import React from 'react';
import CloseModal from '../Buttons/CloseModal';
import ModalButton from '../Buttons/ModalButton';
import ModalXBtn from '../Buttons/ModalXBtn';

const EditRoleModal = () => {
    return (
        <>
            <div className="modal fade modal-xl" id="editRoleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit Role</h1>
                            <ModalXBtn />
                        </div>
                        <div className="modal-body">
                            ...
                        </div>
                        <div className="modal-footer">
                            <CloseModal btn_name='Cancel' />
                            <ModalButton route='#' btn_name='Create' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditRoleModal