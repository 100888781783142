import React, { useState } from "react";
import "./Signin.css";
import { Col, Row } from "react-bootstrap"
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/outline";
import ShowToast from "../../components/Alerts/all_toasts";
import { signUpFunction } from "../../functions/Authentication";
import ShowAlert from "../../components/Alerts/all_alerts";

const AdminSignUp = () => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  //Show Password
  const [values, setValues] = useState({ showPassword: false });

  const ViewPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  //Signup function
  const doSignUp = (e: any) => {
    e.preventDefault();

    if (password !== cPassword) {
      ShowToast.warning_toast("Passwords do not match!");
    } else {
      const signup_data = {
        email: email,
        password: password,
        firstName: fName,
        phone: phone,
        lastName: lName,
        userRoles: ["administrator"],
      };
      ShowAlert.loading_alert("Creating Account...");
      signUpFunction(signup_data);
    }
  };

  return (
    <>
      <div className="h-screen overflow-hidden w-full bg-gray-50">
        <div className="h-full flex justify-center items-center">
          <div className="px-10 py-5 bg-white shadow-lg rounded-[20px]">
            <div className="my-3">
              <p className="text-default_grey text-2xl font-semibold text-center mb-3 sm:hidden">
                Adminsitrator Sign Up
              </p>
              <img
                className="pb-4 block mr-auto ml-auto"
                src={logo}
                alt="..."
                width="100px"
              />
              <form onSubmit={doSignUp}>
                <Row>
                  <Col md={6} className="mt-3">
                    <div className="form-group">
                      <input
                        className="form-control shadow-none border-outline_light"
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                        type="text"
                        id="boss_fname"
                        placeholder="First Name"
                        required
                        autoFocus
                      />
                    </div>
                  </Col>

                  <Col md={6} className="mt-3">
                    <div className="form-group">
                      <input
                        className="form-control shadow-none border-outline_light"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                        type="text"
                        id="boss_lname"
                        placeholder="Last Name"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <div className="form-group pt-3">
                  <input
                    className="form-control shadow-none border-outline_light"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    id="boss_phone"
                    placeholder="Mobile Number"
                    required
                  />
                </div>
                <div className="form-group  pt-3">
                  <input
                    className="form-control shadow-none border-outline_light"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    id="boss_mail"
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div className="form-group pt-3">
                  <div className="input-group">
                    <input
                      className="form-control shadow-none border-outline_light"
                      type={values.showPassword ? "text" : "password"}
                      id="admin_password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      aria-describedby="basic-addon1"
                      required
                    />
                    <Link
                      to="#"
                      className="input-group-text"
                      id="admin_password"
                      onClick={ViewPassword}
                    >
                      <EyeIcon className="w-4 h-4" />
                    </Link>
                  </div>
                </div>
                <div className="form-group pt-3">
                  <input
                    className="form-control shadow-none border-outline_light"
                    value={cPassword}
                    onChange={(e) => setCPassword(e.target.value)}
                    type="password"
                    id="boss_password2"
                    placeholder="Confirm Password"
                    required
                  />
                </div>

                <div className="form-group mt-4 text-center">
                  <button
                    className="bg-default_orange text-default_white shadow-md rounded-full form-control hover:text-default_white active:bg-default_orange"
                    type="submit"
                    id="signin-btn"
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSignUp;
