import React from "react";
import TotalCommissions from "../../components/layouts/dashboard/TotalCommissions";
import ComissionsTable from "../../components/Tables/ComissionsTable";
import PageLayout from "../../components/navLayouts/pageLayout";

const Comissions = () => {
  return (
    <>
      <PageLayout commActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <div className="flex justify-between">
            <p className="text-2xl font-semibold">Commisions</p>
            <h2 className="pt-1 pb-3 text-lg font-semibold">
              Total Commisions:
              <TotalCommissions />
            </h2>
          </div>

          <hr className="my-3" />

          {/* Table */}
          <ComissionsTable />
        </div>
      </PageLayout>
    </>
  );
};

export default Comissions;
