import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShowToast from '../Alerts/all_toasts';
import ShowAlert from '../Alerts/all_alerts';
import WalletTable from '../Tables/WalletTable';
import { PostWalletLimit } from '../../functions/AllPrices';
const isoCountryCurrency = require('iso-country-currency');



const WalletTab = () => {

    const [countryList, setCountryList] = useState([]);
    const [d_country, setDCountry] = useState("");
    const [d_currency, setDCurrency] = useState("");
    const [d_amount, setDAmount] = useState("");
    const [freeDay, setFreeDay] = useState("");



    // get countries on load
    useEffect(() => {
        var data = isoCountryCurrency.getAllISOCodes();
        //console.log(data)
        setCountryList(data);
    }, []);


    // filter currency
    const getCurrency = async (e) => {
        setDCountry(e.target.value);
        var filtered = countryList.filter(function (theData) {
            return theData.countryName === e.target.value;
        });
        // console.log(filtered[0]['currency']);
        setDCurrency(filtered[0]['currency']);
    }


    //Post wallet
    const doPostWallet = () => {
        if (d_country === "" || d_currency === "" || d_amount === "" || freeDay === "") {
            ShowToast.warning_toast('Please fill out all fields!');
        }
        else {
            var data = JSON.stringify({
                "country": d_country,
                "amount": parseInt(d_amount),
                "freeDaily": parseInt(freeDay),
                "currency": d_currency
            });

            ShowAlert.question_alert('Confirm', 'Do you wish to post this data?', 'Proceed')
                .then((result) => {
                    if (result.isConfirmed) {
                        ShowAlert.loading_alert('Psoting Wallet Limit...');
                        //Create wallet function
                        PostWalletLimit(data);
                    }
                })
        }
    }







    return (
        <>
            <Container>

                <div className="container mt-4">
                    <div className='flex justify-between'>
                        <div> <i className='fa fa-sync btn' onClick={() => { window.location.reload() }}></i> </div>

                        <div>
                            <Link className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" to="#" data-bs-toggle="modal" data-bs-target="#addWalletModal">+ Add New</Link>
                        </div>
                    </div>
                </div>


                <div>

                    <WalletTable />

                </div>


            </Container>







            {/* Post Wallet limit modal */}
            <div className="modal fade modal-md" id="addWalletModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 font-semibold" id="staticBackdropLabel">Post Wallet Limit</h1>
                        </div>

                        <div className="modal-body">
                            <Container>
                                <div className='form-group mt-2'>
                                    <select className="form-select form-control" value={d_country} onChange={getCurrency} aria-label="Default select example" id="dropdown">
                                        <option defaultValue='Select...'>Select Country</option>
                                        {
                                            countryList.map((the_d, index) => {
                                                return (
                                                    <option key={index} value={the_d.countryName}>{the_d.countryName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' type='text' value={d_currency} onChange={(e) => setDCurrency(e.target.value)} placeholder='Currency' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' type='text' value={d_amount} onChange={(e) => setDAmount(e.target.value)} placeholder='Amount' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' type='text' value={freeDay} onChange={(e) => setFreeDay(e.target.value)} placeholder='Free Daily' />
                                </div>
                            </Container>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="text-default_red mr-4" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#priceTypeModal">Cancel</button>

                            <button type="button" className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" onClick={() => doPostWallet()}>Post Wallet Limit</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default WalletTab