import ShowAlert from "../components/Alerts/all_alerts";
import ShowToast from "../components/Alerts/all_toasts";
import BaseService from "../mixins/baseServices";
import global_variables from "../mixins/globarVars";

//Create Profession
export const CreateProfession = (data) => {
  const the_route = global_variables().create_profession;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Profession Created!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error.response.data.error);
    });
};

//Delete profession
export const DeleteProfession = (name) => {
  const the_route = global_variables().delete_profession;

  BaseService.delete_api(the_route, name)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Profession Deleted!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error.response.data.error);
    });
};

//Assign Role to Profession
export const DoAssignRole = (data) => {
  const the_route = global_variables().assign_role;

  BaseService.put_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert(
        "Success",
        "Role has been assigned!",
        "Okay"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error.response.data.error);
    });
};

//Create new Admin
export const CreateAminFunction = (data) => {
  const the_route = global_variables().create_user;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "User Created!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error.response.data.error);
    });
};

//delete admin
export const DeleteAdmin = (data) => {
  const the_route = global_variables().delete_user;

  BaseService.delete_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success!", "User Deleted!", "Done").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert("Sorry!", "Failed to delete user!", "Retry").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    });
};

//Post customer service
export const CreateCustomerService = (data) => {
  const the_route = global_variables().post_customer_service;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert(
        "Success",
        "Customer Service Created!",
        "Okay"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not create customer service!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Post update
export const CreateUpdate = (data) => {
  const the_route = global_variables().post_update;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "New Update Released!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Failed to create new update!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Post Cancel reason
export const PostCancelReason = (data) => {
  const the_route = global_variables().post_cancel_reason;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert(
        "Success",
        "New Cancel Reason Created!",
        "Okay"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not create cancel reason!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//delete cancel reason
export const DeleteCancelReason = (data) => {
  const the_route = global_variables().delete_cancel_reason;

  BaseService.delete_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert(
        "Success!",
        "Cancel Reason Deleted!",
        "Done"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Sorry!",
        "Failed to delete cancel reason!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};
