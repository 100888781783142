import React, { useState } from 'react'
import { Input, message, Modal, notification, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { StorageBox } from '../../helpers/stroage';
import { useNavigate } from 'react-router-dom';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
}

const ForgotPassword = ({ isOpened, handleClose }: modalProps) => {

    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [frgtMail, setFrgtMail] = useState("");

    //Forget Pass
    const doforgetPass = async () => {
        if (!frgtMail) {
            message.warning("Please enter your email!");
        } else {
            setIsBusy(true)

            try {
                const response = await BaseService.put_api(global_variables.forget_pss, { email: frgtMail })
                console.log("forgetPassRes:", response)
                StorageBox.saveAccessToken(response?.data?.token)
                message.success("Verify your email address")
                setIsBusy(false)
                navigate(`/verify-reset/${response?.data?.token}`)
            } catch (error: any) {
                console.log("---forgetPassErr:", error)
                message.error(error?.response?.data?.error || "An error occured please try again!")
                setIsBusy(false)
            }
        }
    };


    return (
        <>
            <Modal open={isOpened} onCancel={handleClose} keyboard={false} maskClosable={false} footer={null} centered>
                <div className="w-full">
                    <p className="text-lg font-semibold">Forgot Password?</p>

                    <div className="w-full">
                        Keep calm! We acan help you set a new passowrd: <br /> <br />
                        <p>Enter email again and click the confirm button.</p>
                        <div className="form-group mt-3">
                            <Input className="h-[45px] w-full rounded-md"
                                type="email"
                                value={frgtMail}
                                onChange={(e) => setFrgtMail(e.target.value)}
                                placeholder="Enter email"
                                autoFocus
                                disabled={isBusy}
                            />
                        </div>

                        <div className="from-group text-center pt-3">
                            <button
                                type="submit"
                                className="bg-default_orange text-default_white w-[150px] h-[45px] rounded-md disabled:cursor-not-allowed disabled:bg-gray-400"
                                onClick={() => doforgetPass()}
                                disabled={isBusy}
                            >
                                {
                                    isBusy ?
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#000" }} spin />} />
                                        :
                                        <p>Confirm</p>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ForgotPassword
