import ShowAlert from "../components/Alerts/all_alerts";
import BaseService from "../mixins/baseServices";
import global_variables from "../mixins/globarVars"

//Create promo codes
export const CreateCode = (data) => {
    const the_route = global_variables().create_promo;

    BaseService.post_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Promo code has been created!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            console.log(error);
            ShowAlert.error_alert('Failed!', 'Promo code could not be created!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}





//update promo codes
export const UpdateCode = (data) => {
    const the_route = global_variables().update_promo;

    BaseService.put_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Promo code has been updated!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            console.log(error);
            ShowAlert.error_alert('Failed!', 'Promo code could not be updated!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}