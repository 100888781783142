import React, { useState } from 'react'
import { Form, Input, message, Modal, Spin } from 'antd'
import PhoneNumberInput from 'react-phone-number-input'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import AdminRolesSelector from '../shared/adminRolesSelector'


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}


const CreateNewAdminModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [form] = Form.useForm();
    const [isBusy, setIsBusy] = useState(false)

    //handleSubmit
    const handleSubmit = async (values: any) => {
        console.log('Form values:', values);

        let admin_data = {
            "firstName": values?.firstName,
            "fullName": values?.firstName + " " + values?.lastName,
            "email": values?.email,
            "password": values?.password,
            "phone": values?.phone,
            "userRoles": values?.userRoles,
            "lastName": values?.lastName
        }

        setIsBusy(true)

        try {
            const response = await BaseService.post_api(global_variables.create_user, admin_data)
            console.log("newAdminRes:", response?.data)
            message.success("New admin was created successfully.")
            handleClose()
            handleReFetch()
        } catch (error: any) {
            console.log("newAdminErr:", error)
            message.error(error?.response?.data?.error || "An error occured. Please try again.")
            setIsBusy(false)
        }
    };


    return (
        <>
            <Modal title='Create New Admin' centered open={isOpened} onCancel={handleClose} maskClosable={false} footer={null}>
                <hr />
                <div className='h-[400px] overflow-y-auto px-3'>
                    <Spin spinning={isBusy}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            className='mt-3'
                        >
                            <Form.Item
                                name="userRoles"
                                label="Roles"
                                rules={[{ required: true, message: 'Please select a role' }]}
                            >
                                <AdminRolesSelector onChange={(selected) => console.log(selected)} />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email address' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: 'Please enter your password' }]}
                            >
                                <Input type='password' />
                            </Form.Item>

                            <Form.Item
                                name="firstName"
                                label="First Name"
                                rules={[{ required: true, message: 'Please enter your first name' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                rules={[{ required: true, message: 'Please enter your last name' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="Phone"
                                rules={[{ required: true, message: 'Please enter your phone number' }]}
                            >
                                <PhoneNumberInput value='' onChange={(phone) => console.log(phone)} defaultCountry='MW' />
                            </Form.Item>

                            <Form.Item className='mt-5'>
                                <button className='h-[50px] w-full bg-gradient-to-r from-default_blue to-default_orange text-white rounded-[5px]'>
                                    Submit
                                </button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </>
    )
}

export default CreateNewAdminModal
