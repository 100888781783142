import React from "react";
import TransactionsTable from "../../components/Tables/TransactionsTable";
import PageLayout from "../../components/navLayouts/pageLayout";

const Transactions = () => {
  return (
    <>
      <PageLayout transActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Transactions</p>

          <hr className="my-3" />

          {/* Table */}
          <TransactionsTable />
        </div>
      </PageLayout>
    </>
  );
};

export default Transactions;
