import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import mapIcon from "../../images/navigation-truck2.png";
import { iconPath, tracker } from "./iconPath";
import { Image } from "react-bootstrap";
import Swal from "sweetalert2";
import { StorageBox } from "../../helpers/stroage";

const token = StorageBox.getAccessToken();
const api = process.env.API_BASE_URL;

const mapsKey = process.env.MAP_KEY;

/**
 * @author
 * @function MapsLayOut
 **/

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const { io } = require("socket.io-client");

const TripHistoryMap = (props) => {
  const driverId = props.driverId;
  const trackingId = props.trackingId;

  // console.log(driverId)

  const [map, setMap] = React.useState(null);
  const [directionResponse, setDirectionResponse] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [markerIcon, setMarkerIcon] = useState("");

  const [location, setLocation] = useState([]);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [logLocation, setLogLocation] = useState([]);
  const [tLogSuccess, setTLogSuccess] = useState(false);

  const socket = io(`${api}/rydsome-realtime`);

  const initChannel = "1";
  const handShakeParams = {
    token: token,
    initial_channel: initChannel,
  };

  //Trip logs
  const getTripLogs = (res) => {
    socket.emit(`${res.payload.main_channel}/core/get-triplog`, {
      trackingId: trackingId,
    });

    socket.on(`${res.payload.main_channel}/core/get-triplog`, (response) => {
      // console.log(response);

      setTLogSuccess(response.success);
      if (response.success) {
        // console.log(response.payload)
        setLogLocation(response.payload);
        const new_channel = response.trip_channel;

        socket.on(new_channel, (channelResponse) => {
          // console.log(channelResponse);
          var tempArr = logLocation;
          tempArr.push(channelResponse);
          setLogLocation(tempArr);
        });
      }
    });
  };

  const startSocket = () => {
    socket.on("connect", () => {
      // console.log('Connected');

      socket.emit("/rydsom/handshake", handShakeParams);

      socket.on(initChannel, (res) => {
        // console.log(res)

        //call get trip logs
        getTripLogs(res);

        socket.emit(`${res.payload.main_channel}/core/get-location`, {
          userid: driverId,
        });

        socket.on(
          `${res.payload.main_channel}/core/get-location`,
          (response) => {
            // console.log(response);

            setDataSuccess(response.success);
            if (response.success) {
              // console.log(response.payload.channel)
              setLocation(response.payload.location);
              const new_channel = response.payload.channel;

              socket.on(new_channel, (channelResponse) => {
                // console.log(channelResponse);
                setLocation(channelResponse);
              });
            }
          }
        );
      });
    });

    socket.on("disconnect", () => {
      console.log("Disconnected"); // undefined
      Swal.fire({
        icon: "info",
        text: "Connection lost! Please reload page",
        position: "top-right",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    startSocket();
  }, []);

  // console.log(props.mapDestination)
  // console.log(props.mapOrigin)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapsKey,
  });

  const onLoad = React.useCallback(async function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(props.mapOrigin);
    map.fitBounds(bounds);
    setMap(map);

    // const directionsService = new window.google.maps.DirectionsService()
    // const results = await directionsService.route({
    //   origin: props.mapOrigin,
    //   destination: props.mapDestination,
    //   //eslint-disable-next-line no-undef
    //   travelMode: window.google.maps.TravelMode.DRIVING
    // })

    // setDirectionResponse(results)
    // setDistance(results.routes[0].legs[0].distance.text)
    // setDuration(results.routes[0].legs[0].duration.text)

    // console.log(results)

    //set the live location here
    setMarkerIcon(true);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      // center={props.mapOrigin}
      center={{ lat: location.latitude, lng: location.longitude }}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* {directionResponse && <><DirectionsRenderer directions={directionResponse} /></>} */}
      {/* Child components, such as markers, info windows, etc. */}
      {dataSuccess && (
        <>
          <Marker
            position={{ lat: location.latitude, lng: location.longitude }}
            label="..."
            icon={{
              path: iconPath,
              fillColor: "#ff6700",
              fillOpacity: 0.9,
              scale: 0.1,
              strokeColor: "black",
              strokeWeight: 1,
              rotation: location.rotation,
            }}
            // icon={{ path:mapIcon,rotation:55}}
          />

          {/* trip logs */}
          {logLocation.map((loc, index) => (
            <Marker
              position={{ lat: loc.latitude, lng: loc.longitude }}
              label="..."
              key={index}
              icon={{
                path: tracker,
                fillColor: "#ff6700",
                fillOpacity: 0.9,
                scale: 0.04,
                strokeColor: "#ff6700",
                rotation: loc.rotation,
              }}
            />
          ))}
        </>
      )}
    </GoogleMap>
  ) : (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <p>Please wait...</p>
    </div>
  );
};

export default React.memo(TripHistoryMap);

// const svgMarker = {
//   path: "M24.281,71.674c-5.78,0-10.483,4.702-10.483,10.482c0,5.779,4.702,10.482,10.483,10.482 c5.78,0,10.483-4.703,10.483-10.482C34.764,76.376,30.061,71.674,24.281,71.674z M24.281,90.054c-4.354,0-7.897-3.543-7.897-7.897 s3.542-7.897,7.897-7.897s7.897,3.543,7.897,7.897S28.635,90.054,24.281,90.054z M106.651,71.674c-5.78,0-10.482,4.702-10.482,10.482c0,5.779,4.702,10.482,10.482,10.482 c5.78,0,10.483-4.703,10.483-10.482C117.135,76.376,112.432,71.674,106.651,71.674z M106.651,90.054 c-4.354,0-7.897-3.543-7.897-7.897s3.543-7.897,7.897-7.897c4.355,0,7.897,3.543,7.897,7.897S111.006,90.054,106.651,90.054z M36.76,73.873v9.53h56.683c-0.109-0.667-0.181-1.346-0.181-2.042c0-2.807,0.934-5.393,2.491-7.488 H36.76z M23.486,68.766c2.654,0,5.115,0.83,7.148,2.237V45.735H13.78C0,63.05,13.78,45.735,0,63.05v20.353 h11.073c-0.109-0.667-0.181-1.346-0.181-2.042C10.892,74.417,16.542,68.766,23.486,68.766z M16.196,49.214l5.293,0.298v13.24H5.422 L16.196,49.214z M114.365,24.496H36.76v43.568h77.605V24.496z M56.762,55.896h-8V30.973h8V55.896z M71.963,55.896 h-8.001V30.973h8.001V55.896z M87.164,55.896h-8.001V30.973h8.001V55.896z M102.365,55.896h-8.001V30.973h8.001V55.896z",
//   fillColor: "#52b3ef",
//   fillOpacity: 0.9,
//   strokeWeight: 0,
//   rotation: 50,
//   scale: 0.3,
//   anchor: new window.google.maps.Point(15, 30),
// };
