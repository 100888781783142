import React, { useState } from "react";
import AppUsersTable from "../../components/Tables/AppUsersTable";
import PageLayout from "../../components/navLayouts/pageLayout";
import AddButton from "../../components/shared/addButton";
import AddUserModal from "./addUserModal";

const AppUsers = () => {

  const [openAddModal, setOpenAddModal] = useState(false)

  return (
    <>
      <PageLayout usersActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold">App Users</p>

            <div>
              <AddButton onClick={() => setOpenAddModal(true)} />
            </div>
          </div>

          <hr className="my-3" />

          {/* Table */}
          <AppUsersTable />
        </div>
      </PageLayout>


      {/* add new user modal */}
      {
        openAddModal && <AddUserModal isOpened={openAddModal} handleClose={() => setOpenAddModal(false)} />
      }

    </>
  );
};

export default AppUsers;
