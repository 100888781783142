import ShowAlert from "../components/Alerts/all_alerts";
import BaseService from "../mixins/baseServices";
import global_variables from "../mixins/globarVars";

//Post Car Feature
export const CreateNewFeature = (data) => {
  const the_route = global_variables().car_feature_url;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "New Car Feature Created!", "Okay");
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not create new car feature!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//update Car Feature
export const UpdateCarFeature = (data) => {
  const the_route = global_variables().update_feature_url;

  BaseService.put_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Car Feature Updated!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not update car feature!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Delete Car Feature
export const DeleteNewFeature = (data) => {
  const the_route = global_variables().car_feature_url;

  BaseService.delete_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Car Feature Deleted!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not delete car feature!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Post Car Brand
export const CreateNewBrand = (data) => {
  const the_route = global_variables().car_brand_url;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "New Car Brand Created!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not create new car brand!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//update Car Brand
export const UpdateCarBrand = (data) => {
  const the_route = global_variables().car_brand_url;

  BaseService.put_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Car Brand Updated!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not update car brand!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Delete Car Brand
export const DeleteNewBrand = (data) => {
  const the_route = global_variables().car_brand_url;

  BaseService.delete_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Car Brand Deleted!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not delete car brand!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Post Class
export const CreateNewClass = (data) => {
  const the_route = global_variables().rydsom_class_url;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "New Class Created!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not create new car class!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Delete Class
export const DeleteClass = (data) => {
  const the_route = global_variables().rydsom_class_url;

  BaseService.delete_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Class Deleted!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert("Error", "Could not delete class!", "Retry").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    });
};

//Post Model
export const CreateNewModel = (data) => {
  const the_route = global_variables().car_model_url;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "New Model Created!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not create new car model!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Delete Model
export const DeleteModel = (data) => {
  const the_route = global_variables().car_model_url;

  BaseService.delete_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Model Deleted!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert("Error", "Could not delete model!", "Retry").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    });
};
