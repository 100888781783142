import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { CreateProfession } from '../../functions/Admin';
import ShowAlert from '../Alerts/all_alerts';
import ShowToast from '../Alerts/all_toasts';
import ModalXBtn from '../Buttons/ModalXBtn';
import { message, Modal, Spin } from 'antd';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}


const CreateProfessionModal = ({ handleClose, handleReFetch, isOpened }: modalProps) => {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isBusy, setIsBusy] = useState(false)


    //Create profession function
    const doCreate = async (e: any) => {
        e.preventDefault()

        const the_data = {
            name: title,
            description: description
        }

        if (title === '' || description === '') {
            ShowToast.warning_toast('Please fill out all fields!');
        }
        else {
            setIsBusy(true)
            try {
                const response = await BaseService.post_api(global_variables.create_profession, the_data)
                console.log(response?.data)
                message.success("New profession was created")
                setIsBusy(false)
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                message.error(error?.response?.data?.error || "An error occured. Please retry")
            }
        }

    }


    return (
        <>
            <Modal title='Create New Profession' open={isOpened} onCancel={handleClose} maskClosable={false} footer={null} centered keyboard={false}>
                <hr />
                <div className='h-[400px] overflow-y-auto px-3'>
                    <Spin spinning={isBusy}>
                        <form onSubmit={doCreate}>
                            <label
                                htmlFor='title'
                                className="mb-2"
                            >Profession Title
                            </label>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    type="text"
                                    id='title'
                                    placeholder="Type here..."
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </div>

                            <label
                                htmlFor='description'
                                className="mb-2 mt-3"
                            >Profession Description
                            </label>
                            <div className='form-group'>
                                <textarea
                                    className='form-control'
                                    id='description'
                                    placeholder="Type here..."
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </div>

                            <div className='text-center'>
                                <button type="submit" onClick={doCreate} className='mt-4 bg-default_orange text-default_white w-fit px-3 py-1 text-lg rounded-full hover:text-default_white hover:bg-default_orange'>Create Profession</button>
                            </div>
                        </form>
                    </Spin>
                </div>
            </Modal>
        </>
    )
}

export default CreateProfessionModal