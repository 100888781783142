import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShowToast from "../Alerts/all_toasts";
import ShowAlert from "../Alerts/all_alerts";
import { CreateNewFeature } from "../../functions/CarsAndClasses";
import CarFeaturesTable from "../Tables/CarFeaturesTable";
import { global_variables } from "../../helpers/globalVariables";
import { message, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import { useGeneralContext } from "../../contexts/generalContext";

const FeaturesTab = () => {
  //trigger post modal
  const [show, setPstShow] = useState(false);
  const handlePostClose = () => setPstShow(false);
  const handlePostShow = () => setPstShow(true);
  const { reFetch, setReFetch } = useGeneralContext();
  const [isBusy, setIsBusy] = useState(false);

  const [d_feature, setDFeature] = useState("");

  //Post feature
  const doPostFeature = () => {
    if (d_feature === "") {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = {
        feature: d_feature,
      };

      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to create this feature?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          setIsBusy(true);
          //Create feature function

          BaseService.post_api(global_variables.car_feature_url, data)
            .then((response) => {
              // console.log(response);
              message.success("New Car Feature Created!");
              setIsBusy(false);
              handlePostClose();
              setReFetch(!reFetch);
            })
            .catch((error) => {
              console.log(error);
              message.error(
                error?.response?.data?.error ||
                  "Could not create new car feature! Retry"
              );
              setIsBusy(false);
            });
        }
      });
    }
  };

  return (
    <>
      <Container>
        <div className="container mt-4">
          <div className="flex justify-between">
            <div>
              {" "}
              <i
                className="fa fa-sync btn"
                onClick={() => setReFetch(!reFetch)}
              ></i>{" "}
            </div>

            <div>
              <Link
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                to="#"
                onClick={() => handlePostShow()}
              >
                + Add New
              </Link>
            </div>
          </div>
        </div>

        <div>
          <CarFeaturesTable />
        </div>
      </Container>

      {/* Car FeaturesTab modal */}
      <Modal show={show} onHide={handlePostClose}>
        <Spin spinning={isBusy}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 className="font-semibold">Post Car Features</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="form-group mt-3">
                <input
                  className="form-control shadow-none rounded-md"
                  type="text"
                  value={d_feature}
                  onChange={(e) => setDFeature(e.target.value)}
                  placeholder="Enter car feature"
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="text-default_red mr-4"
              onClick={() => handlePostClose()}
            >
              Cancel
            </button>

            <button
              type="button"
              className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
              onClick={() => doPostFeature()}
            >
              Post Feature
            </button>
          </Modal.Footer>
        </Spin>
      </Modal>
    </>
  );
};

export default FeaturesTab;
