import React, { useState, useEffect } from 'react';
import { Bar } from "react-chartjs-2";
import moment from 'moment';
import global_variables from '../../mixins/globarVars';
import BaseService from '../../mixins/baseServices';



var currentDate = moment();
var weekStart = currentDate.clone().startOf('week');
// var weekEnd = currentDate.clone().endOf('week');

var days = [];
let i;
for (i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
};

const sunday = days[0];
const monday = days[1];
const tuesday = days[2];
const wednesday = days[3];
const thursday = days[4];
const friday = days[5];
const saturday = days[6];



const WeekEarnings = () => {

    const [day1, setDay1] = useState(0);
    const [day2, setDay2] = useState(0);
    const [day3, setDay3] = useState(0);
    const [day4, setDay4] = useState(0);
    const [day5, setDay5] = useState(0);
    const [day6, setDay6] = useState(0);
    const [day7, setDay7] = useState(0);

    //fetch with dates onload
    useEffect(() => {

        fetchDay1();
        fetchDay2();
        fetchDay3();
        fetchDay4();
        fetchDay5();
        fetchDay6();
        fetchDay7();

    }, []);


    const fetchDay1 = async () => {
        const the_route = global_variables().get_all_transactions + `?filter={"createdAt":{"$regex":${JSON.stringify(sunday)},"$options":"i"}}`;

        await BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.payload);
                const gotData = response.data.details;

                // console.log(response);
                if (gotData === null) {
                    setDay1(0);
                }
                else {
                    setDay1(gotData['total_amount']);
                }
            })
            .catch((error) => {
                // console.log(error);
            })

    }

    const fetchDay2 = async () => {
        const the_route = global_variables().get_all_transactions + `?filter={"createdAt":{"$regex":${JSON.stringify(monday)},"$options":"i"}}`;

        await BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.payload);
                const gotData = response.data.details;

                // console.log(response);
                if (gotData === null) {
                    setDay2(0);
                }
                else {
                    setDay2(gotData['total_amount']);
                }
            })
            .catch((error) => {
                // console.log(error);
            })

    }

    const fetchDay3 = async () => {
        const the_route = global_variables().get_all_transactions + `?filter={"createdAt":{"$regex":${JSON.stringify(tuesday)},"$options":"i"}}`;

        await BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.payload);
                const gotData = response.data.details;

                // console.log(response);
                if (gotData === null) {
                    setDay3(0);
                }
                else {
                    setDay3(gotData['total_amount']);
                }
            })
            .catch((error) => {
                // console.log(error);
            })

    }

    const fetchDay4 = async () => {
        const the_route = global_variables().get_all_transactions + `?filter={"createdAt":{"$regex":${JSON.stringify(wednesday)},"$options":"i"}}`;

        await BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.payload);
                const gotData = response.data.details;

                // console.log(response);
                if (gotData === null) {
                    setDay4(0);
                }
                else {
                    setDay4(gotData['total_amount']);
                }
            })
            .catch((error) => {
                // console.log(error);
            })

    }

    const fetchDay5 = async () => {
        const the_route = global_variables().get_all_transactions + `?filter={"createdAt":{"$regex":${JSON.stringify(thursday)},"$options":"i"}}`;

        await BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.payload);
                const gotData = response.data.details;

                // console.log(response);
                if (gotData === null) {
                    setDay5(0);
                }
                else {
                    setDay5(gotData['total_amount']);
                }
            })
            .catch((error) => {
                // console.log(error);
            })

    }

    const fetchDay6 = async () => {
        const the_route = global_variables().get_all_transactions + `?filter={"createdAt":{"$regex":${JSON.stringify(friday)},"$options":"i"}}`;

        await BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.payload);
                const gotData = response.data.details;

                // console.log(response);
                if (gotData === null) {
                    setDay6(0);
                }
                else {
                    setDay6(gotData['total_amount']);
                }
            })
            .catch((error) => {
                // console.log(error);
            })

    }

    const fetchDay7 = async () => {
        const the_route = global_variables().get_all_transactions + `?filter={"createdAt":{"$regex":${JSON.stringify(saturday)},"$options":"i"}}`;

        await BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.payload);
                const gotData = response.data.details;

                // console.log(response);
                if (gotData === null) {
                    setDay7(0);
                }
                else {
                    setDay7(gotData['total_amount']);
                }
            })
            .catch((error) => {
                // console.log(error);
            })

    }



    const data = {
        labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
        datasets: [
            {
                label: "Earnings - Gh¢",
                data: [day1, day2, day3, day4, day5, day6, day7],
                fill: true,
                backgroundColor: "#9999C2",
            }
        ]
    };

    var the_options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: true
                }
            }
        }
    }

    return (
        <>
            <Bar data={data} options={the_options} />
        </>
    )
}

export default WeekEarnings