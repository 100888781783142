import React, { useState } from "react";
import "./SideBar.css";
// import 'flowbite';
import logo from "../../images/logo.png";
import {
  Squares2X2Icon,
  BellAlertIcon,
  BuildingOfficeIcon,
  WrenchScrewdriverIcon,
  ArrowRightOnRectangleIcon,
  GiftIcon,
  ClipboardDocumentCheckIcon,
  MapPinIcon,
  UserGroupIcon,
  IdentificationIcon,
  BanknotesIcon,
  DocumentTextIcon,
  ChatBubbleBottomCenterTextIcon,
  BriefcaseIcon,
  DevicePhoneMobileIcon,
  Bars3Icon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { signOutFunction } from "../../functions/Authentication";

const SideBar = (props) => {
  const [isDrawn, setIsDrawn] = useState(false);
  const [addClass, setAddClass] = useState("hidden");

  //on toggle clicked
  const handleToggle = () => {
    if (isDrawn === false) {
      setIsDrawn(true);
      setAddClass("hidden");
    } else {
      setAddClass("");
      setIsDrawn(false);
    }
  };

  return (
    <>
      <Link className="sm:hidden" to="#" onClick={handleToggle}>
        <Bars3Icon className="w-7 h-7 text-default_orange hover:text-default_orange" />
      </Link>
      <aside
        className={`w-64 h-screen shadow-md fixed sm:block bg-default_white z-10 ${addClass}`}
        aria-label="Sidebar"
      >
        <div
          className="overflow-y-auto py-4 px-3 h-full rounded"
          id="custom_scroll"
        >
          <Link to="#" className="flex items-center pl-2.5 mb-3">
            <img
              src={logo}
              className="flex mr-auto ml-auto h-6 sm:h-7"
              alt="..."
            />
          </Link>

          <ul className="space-y-2 pt-2 pb-2 border-t border-default_grey">
            <li className={props.adminActive}>
              <Link
                to="/administration"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <BuildingOfficeIcon className="w-6 h-6" />

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Administration
                </span>
              </Link>
            </li>
            <li className={props.paymentsActive}>
              <Link
                to="/payments"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <DevicePhoneMobileIcon className="w-6 h-6" />

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Payment & Approvals
                </span>
              </Link>
            </li>
            <li className={props.commActive}>
              <Link
                to="/comissions"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <ArrowTrendingUpIcon className="w-6 h-6" />

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Comissions
                </span>
              </Link>
            </li>
            <li className={props.classActive}>
              <Link
                to="/cars_classes"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <BriefcaseIcon className="w-6 h-6" />

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Cars & Classes
                </span>
              </Link>
            </li>
            <li className={props.transactionsActive}>
              <Link
                to="/transactions"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <BanknotesIcon className="w-6 h-6" />

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Transactions
                </span>
              </Link>
            </li>
            <li className={props.notificationsActive}>
              <Link
                to="/notifications"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <BellAlertIcon className="w-6 h-6" />

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Notifications
                </span>
                {/* <span className="inline-flex justify-center items-center p-2.5 ml-3 w-3 h-3 text-sm font-medium text-white bg-default_orange rounded-full">3</span> */}
              </Link>
            </li>
            <li className={props.chatsActive}>
              <Link
                to="/livechat"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <ChatBubbleBottomCenterTextIcon className="w-6 h-6" />

                <span className="flex-1 ml-3 whitespace-nowrap">Chat</span>
              </Link>
            </li>
          </ul>

          <ul className="pt-2 pb-2 space-y-2 border-t border-default_grey">
            <li className={props.dashActive}>
              <Link
                to="/dashboard"
                className="flex items-center p-2 text-base font-normal rounded-lg"
              >
                <Squares2X2Icon className="w-6 h-6" />

                <span className="ml-3">Dashboard</span>
              </Link>
            </li>
            <li className={props.driverActive}>
              <Link
                to="/drivers"
                className="flex items-center p-2 text-base font-normal rounded-lg"
              >
                <IdentificationIcon className="w-6 h-6" />

                <span className="ml-3">Drivers</span>
              </Link>
            </li>
            <li className={props.vehiclesActive}>
              <Link
                to="/vehicles"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <DocumentTextIcon className="w-6 h-6" />

                <span className="ml-3">Vehicles</span>
              </Link>
            </li>
            <li className={props.tripActive}>
              <Link
                to="/trip_history"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <ClipboardDocumentCheckIcon className="w-6 h-6" />

                <span className="ml-3">Trip History</span>
              </Link>
            </li>
            <li className={props.trackingActive}>
              <Link
                to="/tracking"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <MapPinIcon className="w-6 h-6" />

                <span className="ml-3">Tracking</span>
              </Link>
            </li>
            <li className={props.usersActive}>
              <Link
                to="/app_users"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <UserGroupIcon className="w-6 h-6" />

                <span className="ml-3">Riders</span>
              </Link>
            </li>
            {/* <li className={props.bookingsActive}>
                            <Link to="/bookings" className="flex items-center p-2 text-base rounded-lg">
                                <TicketIcon className='w-6 h-6' />

                                <span className="ml-3">Manual Bookings</span>
                            </Link>
                        </li> */}
            <li className={props.promoActive}>
              <Link
                to="/promo_codes"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <GiftIcon className="w-6 h-6" />

                <span className="ml-3">Promo Codes</span>
              </Link>
            </li>
          </ul>

          <ul className="pt-2 space-y-2 border-t border-default_grey">
            <li className={props.settingsActive}>
              <Link
                to="/settings"
                className="flex items-center p-2 text-base rounded-lg"
              >
                <WrenchScrewdriverIcon className="w-6 h-6" />

                <span className="ml-3">Settings</span>
              </Link>
            </li>
          </ul>
          <ul className="pt-2 space-y-2">
            <li>
              <Link
                to="/"
                className="flex items-center p-2 text-base rounded-lg"
                onClick={() => signOutFunction()}
              >
                <ArrowRightOnRectangleIcon className="w-6 h-6" />

                <span className="ml-3">Sign Out</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideBar;
