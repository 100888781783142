import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import TableShimmer from "../Shimmers/TableShimmer";
import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import global_variables from "../../mixins/globarVars";
import BaseService from "../../mixins/baseServices";
import ShowAlert from "../Alerts/all_alerts";
import { SuspendUser, UnsuspendUser } from "../../functions/Actions";
import ShowToast from "../Alerts/all_toasts";
import CloseModal from "../Buttons/CloseModal";
import ModalXBtn from "../Buttons/ModalXBtn";
import NoDataComponent from "../layouts/NoDataComponent";
import moment from "moment-timezone";
import Select from "react-select";
import place_holder from "../../images/user-profile.png";
import { Empty, Spin } from "antd";

const DriversTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [dataIn, setDataIn] = useState(false);
  const [specificData, setSpecificData] = useState([]);
  const [reFetch, setReFetch] = useState(false);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [reason, setReason] = useState("");
  const [uniqueId, setUniqueId] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //Onload
  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //Fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_all_drivers +
      '?filter={"approved": true}' +
      `&limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setDriverList(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "",
      selector: (row) => (
        <img
          className="rounded-full py-1"
          src={
            row.user_data
              ? row.user_data["image"] === "null"
                ? place_holder
                : row.user_data["image"]
              : place_holder
          }
          width="40px"
          alt="..."
        />
      ),
    },
    {
      name: "Driver Name",
      selector: (row) => row.user_data["fullName"],
    },
    {
      name: "Phone",
      selector: (row) => row.user_data["phone"],
    },
    {
      name: "Signup Date",
      selector: (row) =>
        moment(row.user_data["createdAt"]).format("Do MMM YYYY"),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.user_data["suspended"] ? (
            <p className="text-default_white bg-default_red px-3 py-1 rounded-xl">
              Suspended
            </p>
          ) : (
            <p className="text-default_white bg-default_green px-3 py-1 rounded-xl">
              Approved
            </p>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#detailsModal"
              onClick={showDetails.bind(this, index)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
            {row.user_data["suspended"] ? (
              <Link
                to="#"
                title="Unsuspend"
                onClick={checkPoint2.bind(this, index)}
              >
                <CheckBadgeIcon className="w-5 h-5" />
              </Link>
            ) : (
              <Link
                to="#"
                title="Suspend"
                data-bs-toggle="modal"
                data-bs-target="#suspendModal"
                onClick={PickId.bind(this, index)}
              >
                <ExclamationTriangleIcon className="w-5 h-5" />
              </Link>
            )}
          </div>
        </>
      ),
    },
  ];

  const tableData = {
    columns: columns,
    data: theData,
  };

  //View Details
  const showDetails = (i) => {
    var go = theData[i]["userid"];

    var uniqueData = theData.filter(function (theData) {
      return theData.userid === go;
    });
    setSpecificData(uniqueData[0]);
    setDataIn(true);
    // console.log(uniqueData[0]);
  };

  //get userid for suspension
  const PickId = (i) => {
    var go = theData[i]["userid"];

    var uniqueData = theData.filter(function (theData) {
      return theData.userid === go;
    });

    setUniqueId(uniqueData[0]["userid"]);
    // console.log(uniqueData[0]['userid']);
  };

  //Checkpoint for Suspension
  const checkPoint = (e) => {
    e.preventDefault();
    if (start === "" || end === "" || reason === "") {
      ShowToast.warning_toast("Please complete all fields!");
    } else {
      ShowAlert.question_alert(
        "Confirm Suspension",
        "Would you like to suspend this user?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          //do suspension
          //console.log(start, end, reason);
          ShowAlert.loading_alert("Please wait...");
          const data = {
            userid: uniqueId,
            suspensionStart: start,
            suspensionEnd: end,
            reason: reason,
          };

          SuspendUser(data);
        }
      });
    }
  };

  //Checkpoint for Unsuspension
  const checkPoint2 = (i) => {
    var go = theData[i]["userid"];

    var uniqueData = theData.filter(function (theData) {
      return theData.userid === go;
    });

    // console.log(uniqueData[0]['userid']);

    ShowAlert.question_alert(
      "Confirm Unsuspension",
      "Would you like to unsuspend this user?",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        //do unsuspension

        ShowAlert.loading_alert("Please wait...");
        const data = {
          userid: uniqueData[0]["userid"],
        };

        UnsuspendUser(data);
      }
    });
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  const [filQry, setFilQry] = useState("");

  //Search fiter
  const applySearch = () => {
    if (filQry === "") {
      ShowToast.warning_toast(`Please input a driver's name`);
    } else {
      setLoading(true);

      const the_route =
        global_variables().get_all_drivers +
        `?filter={"userid":${JSON.stringify(filQry)}}`;

      BaseService.get_api(the_route)
        .then((response) => {
          // console.log(response.data.payload);
          setTheData(response.data.payload);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>
          <form className="col-md-6">
            <div className="input-group">
              <Container className="flex">
                <select
                  className="form-select rounded-tr-none rounded-br-none"
                  aria-label="Default select example"
                  style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                >
                  <option defaultValue="0" disabled>
                    Filter by ...{" "}
                  </option>
                  <option value="userid">Driver Name</option>
                </select>

                <Select
                  className="basic-single w-96"
                  isClearable={true}
                  isSearchable={true}
                  options={driverList.map((dd) => {
                    return dd.user_data === null
                      ? { value: dd.userid, label: "" }
                      : { value: dd.userid, label: dd.user_data["fullName"] };
                  })}
                  onChange={(e) => setFilQry(e.value)}
                />

                <button
                  onClick={() => applySearch()}
                  type="button"
                  className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange rounded-tl-none rounded-bl-none"
                >
                  <i className="fas fa-search"></i>
                </button>
              </Container>
            </div>
          </form>
        </div>
      </div>

      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            noDataComponent={<Empty description="No data to display" />}
            paginationServer
            paginationTotalRows={totalRows}
            progressPending={loading}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
          />
        </Card.Body>
      </Card>

      {/* View Details Modal */}
      <div
        className="modal fade modal-xl"
        id="detailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Driver Details
              </h1>
              <ModalXBtn />
            </div>

            <div className="modal-body">
              <Container>
                {dataIn ? (
                  <>
                    <Row>
                      <Col className="mt-3" md={6}>
                        <div className="flex gap-4 mt-1">
                          <p className="font-semibold">License Number:</p>
                          <p>{specificData["license"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">License Image:</p>
                          <img
                            className="img-fluid w-36"
                            src={specificData["licenseImage"]}
                            alt="..."
                          />
                        </div>
                      </Col>

                      <Col className="mt-3" md={6}>
                        <div className="flex gap-4 mt-1">
                          <p className="font-semibold">National Id Number:</p>
                          <p>{specificData["nationalID"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">National Id Image:</p>
                          <img
                            className="img-fluid w-36"
                            src={specificData["nationalIDImage"]}
                            alt="..."
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col className="mt-3" md={6}>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">First Name:</p>
                          <p>{specificData["user_data"]["firstName"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Middle Name:</p>
                          <p>
                            {specificData["user_data"]["middleName"]
                              ? specificData["user_data"]["middleName"]
                              : "..."}
                          </p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Last Name:</p>
                          <p>{specificData["user_data"]["lastName"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Signup Date:</p>
                          <p>
                            {moment(
                              specificData["user_data"]["createdAt"]
                            ).format("Do MMM YYYY")}
                          </p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">User Image:</p>
                          <img
                            className="img-fluid w-36"
                            src={
                              specificData["user_data"]
                                ? specificData["user_data"]["image"]
                                : place_holder
                            }
                            alt="..."
                          />
                        </div>
                      </Col>
                      <Col className="mt-3" md={6}>
                        <div className="flex gap-4 mt-1">
                          <p className="font-semibold">Phone Number:</p>
                          <p>{specificData["user_data"]["phone"]}</p>
                        </div>
                        <div className="mt-3">
                          <div className="flex gap-4 mt-3">
                            <p className="font-semibold">
                              Residential Address:
                            </p>
                            <p>
                              {specificData["residentialAddress"]
                                ? specificData["residentialAddress"]["place"]
                                : "..."}
                            </p>
                          </div>
                          <div className="flex gap-4 mt-3">
                            <p className="font-semibold">Longitude:</p>
                            <p>
                              {specificData["residentialAddress"]
                                ? specificData["residentialAddress"][
                                    "longitude"
                                  ]
                                : "..."}
                            </p>
                          </div>
                          <div className="flex gap-4 mt-3">
                            <p className="font-semibold">Latitude:</p>
                            <p>
                              {specificData["residentialAddress"]
                                ? specificData["residentialAddress"]["latitude"]
                                : "..."}
                            </p>
                          </div>
                          <p className="font-semibold mt-3">Roles:</p>
                          {specificData["user_data"]["userRoles"].map(
                            (the_d, index) => {
                              return (
                                <p key={index}>
                                  {index + 1}. {the_d}
                                </p>
                              );
                            }
                          )}
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <p className="text-center">No data to display ...</p>
                )}
              </Container>
            </div>

            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>

      {/* Suspension Modal */}
      <div
        className="modal fade modal-md"
        id="suspendModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Suspend user
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="startDate">Start Date:</label>
                  <input
                    type="date"
                    className="form-control rounded-md"
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    id="startDate"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="endDate">End Date:</label>
                  <input
                    type="date"
                    className="form-control rounded-md"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    id="endDate"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    type="text"
                    className="form-control rounded-md"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Reason for suspension"
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
              <button
                className="bg-default_orange text-default_white px-2 py-1 rounded-md text-lg"
                type="submit"
                onClick={checkPoint}
              >
                Suspend
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriversTable;
