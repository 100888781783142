import React, { useState, useEffect } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Card, Container, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import ShowAlert from "../Alerts/all_alerts";
import { DeleteNewBrand, UpdateCarBrand } from "../../functions/CarsAndClasses";
import ShowToast from "../Alerts/all_toasts";
import { Empty, Spin } from "antd";
import { useGeneralContext } from "../../contexts/generalContext";

const CarFeaturesTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const { reFetch } = useGeneralContext();

  const [d_brand, setDBrand] = useState("");
  const [d_id, setDId] = useState();

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //trigger edit modal
  const [show, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);

  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().car_brand_url +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "Brand",
      selector: (row) => row.brand,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-3">
            <Link to="#" title="Update Brand" onClick={doEdit.bind(this, row)}>
              <PencilSquareIcon className="w-5 h-5" />
            </Link>
            <Link
              className="text-default_red"
              to="#"
              title="Delete"
              onClick={doDelete.bind(this, index)}
            >
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const doDelete = (i) => {
    var go = theData[i]["_id"];

    var uniqueData = theData.filter(function (theData) {
      return theData._id === go;
    });
    // console.log(uniqueData);

    ShowAlert.question_alert(
      "Confirm",
      "Do you wish to delete this info?",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: uniqueData[0]["_id"],
        };
        ShowAlert.loading_alert("Deleting car brand...");
        //Delete feature function
        DeleteNewBrand(data);
        console.log(data);
      }
    });
  };

  const doEdit = (i) => {
    console.log(i);
    setDBrand(i.brand);
    setDId(i._id);
    handleEditShow();
  };

  //update Brand
  const doEditBrand = () => {
    if (d_brand === "") {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = {
        _id: d_id,
        brand: d_brand,
      };

      handleEditClose();

      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to edit this data?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          ShowAlert.loading_alert("Updating Car Brand...");
          //update brand function
          UpdateCarBrand(data);
        }
      });
    }
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* update Brands modal */}
      <Modal show={show} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="font-semibold">Edit Car Brand</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="form-group mt-3">
              <input
                className="form-control shadow-none rounded-md"
                type="text"
                value={d_brand}
                onChange={(e) => setDBrand(e.target.value)}
                placeholder="Enter car brand"
              />
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="text-default_red mr-4"
            onClick={() => handleEditClose()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
            onClick={() => doEditBrand()}
          >
            Edit Brand
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CarFeaturesTable;
