import ShowAlert from "../components/Alerts/all_alerts";
import BaseService from "../mixins/baseServices";
import global_variables from "../mixins/globarVars"

export const SendSms = (data) => {
    const the_route = global_variables().send_sms;

    BaseService.post_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Message has been delivered!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Message was not delivered!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}

export const SendEmail = (data) => {
    const the_route = global_variables().send_email;

    BaseService.post_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Email has been delivered!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Email was not delivered!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}