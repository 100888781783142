import React, { useEffect, useState } from "react";
import {
  ListBulletIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import ShowAlert from "../Alerts/all_alerts";
import { DeleteAdmin } from "../../functions/Admin";
import CloseModal from "../Buttons/CloseModal";
import ModalXBtn from "../Buttons/ModalXBtn";
import nodata_img from "../../images/noDtat_img.png";
import moment from "moment-timezone";
import { Empty, Spin } from "antd";
import CreateNewAdminModal from "../Modals/CreateNewAdminModal";

const AdministratorsTable = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [specificData, setSpecificData] = useState([]);
  const [dataCome, setDataCome] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);

  // on load
  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);
    const the_route =
      global_variables().get_all_admins +
      `?filter={"administrator":${true}}` +
      `&limit=${lim}&skip=${sk}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "",
      selector: (row) => (
        <img
          className="h-10 w-10 rounded-full object-cover"
          src={
            row?.image ||
            "https://cdn.vectorstock.com/i/500p/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"
          }
          alt="..."
        />
      ),
      width: "8%",
    },
    {
      name: "Fullname",
      selector: (row) => row?.fullName,
    },
    {
      name: "Email",
      cell: (row) => row?.email,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
    },
    {
      name: "Roles",
      cell: (row) => (
        <ul>
          {row?.userRoles &&
            row?.userRoles.map((item, i) => (
              <li key={i} className="text-[12px]">
                {i + 1}. {item}
              </li>
            ))}
        </ul>
      ),
    },
    {
      name: "Date Created",
      cell: (row) => moment(row?.createdAt).format("Do MMM YYYY - h:mmA"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-2">
            {/* <Link to="#" title="Edit" data-bs-toggle="modal" data-bs-target="#editAdminModal" onClick={showDetails.bind(this, index)}><PencilIcon className='w-5 h-5' /></Link> */}
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#viewAdminModal"
              onClick={showDetails.bind(this, index)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
            <Link to="#" title="Delete" onClick={DoDelete.bind(this, index)}>
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  //View Details
  const showDetails = (i) => {
    var go = data[i]["userid"];

    var uniqueData = data.filter(function (theData) {
      return theData.userid === go;
    });
    setSpecificData(uniqueData);
    setDataCome(true);
    // console.log(uniqueData);
  };

  //delete admin
  const DoDelete = (i) => {
    var go = data[i]["userid"];

    var uniqueData = data.filter(function (theData) {
      return theData.userid === go;
    });
    // console.log(uniqueData[0]['userid']);

    const to_del = {
      userid: uniqueData[0]["userid"],
    };

    ShowAlert.question_alert(
      "Confirm",
      "Are you sure you want to delete this user?",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        ShowAlert.loading_alert("Deleting User...");

        //Delete function
        DeleteAdmin(to_del);
      }
    });
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    const the_route =
      global_variables().get_all_admins +
      `?filter={"administrator":true,"fullName":{"$regex":${JSON.stringify(
        filQry
      )},"$options":"i"}}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        //console.log(response.data.payload);
        setData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>
          <form className="col-md-6" onSubmit={handleSearch}>
            <div className="input-group">
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
              >
                <option defaultValue="0" disabled>
                  Filter by ...{" "}
                </option>
                <option value="name">Full Name</option>
              </select>

              <input
                className="form-control shadow-none"
                placeholder="Search..."
                id="truckNum"
                aria-label="Search"
                aria-describedby="search-addon"
                autoComplete="off"
                type="text"
                style={{ border: "1px solid #d9e2ef" }}
                required
                value={filQry}
                onChange={(e) => setFilQry(e.target.value)}
                autoFocus
              />
              <button
                type="submit"
                className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <div className="mt-3 mb-3 fa-pull-right">
            <Link
              className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
              to="#"
              onClick={() => setOpenAddModal(true)}
            >
              + Add
            </Link>
          </div>

          <DataTable
            progressPending={loading}
            data={data}
            columns={columns}
            pagination
            paginationServer
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Add New Admin Modal */}
      {openAddModal && (
        <CreateNewAdminModal
          isOpened={openAddModal}
          handleClose={() => setOpenAddModal(false)}
          handleReFetch={() => setReFetch(!reFetch)}
        />
      )}

      {/* View Details Modal */}
      <div
        className="modal fade modal-lg"
        id="viewAdminModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                View Admin Details
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              {dataCome ? (
                <>
                  <div>
                    <img
                      className="shadow-md flex mr-auto ml-auto"
                      src={
                        specificData?.[0]?.image ||
                        "https://cdn.vectorstock.com/i/500p/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"
                      }
                      alt="..."
                      width="150px"
                    />
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 flex gap-4">
                      <p className="font-semibold">First Name:</p>
                      <p>{specificData?.[0]?.["firstName"]}</p>
                    </div>
                    <div className="col-md-6 flex gap-4">
                      <p className="font-semibold">Last Name:</p>
                      <p>{specificData?.[0]?.["lastName"]}</p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 flex gap-4">
                      <p className="font-semibold">Email:</p>
                      <p>{specificData?.[0]?.["email"]}</p>
                    </div>
                    <div className="col-md-6 flex gap-4">
                      <p className="font-semibold">Phone:</p>
                      <p>{specificData?.[0]?.["phone"]}</p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 flex gap-4">
                      <p className="font-semibold">Date Created:</p>
                      <p>
                        {moment(specificData?.[0]?.["createdAt"]).format(
                          "Do MMMM YYYY - h:mmA"
                        )}
                      </p>
                    </div>
                    <div className="col-md-6 flex gap-4">
                      <p className="font-semibold">Roles:</p>
                      <ul>
                        {specificData?.[0]?.["userRoles"].map((the_d, i) => {
                          return (
                            <li key={i}>
                              {i + 1}. {the_d}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <img
                    className="flex mr-auto ml-auto"
                    src={nodata_img}
                    alt="..."
                    width="200px"
                  />
                  <p>No data to show</p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdministratorsTable;
