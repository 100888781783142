import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

const ModalXBtn = () => {
    return (
        <>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><XMarkIcon className='w-5 h-5' /></button>
        </>
    )
}

export default ModalXBtn