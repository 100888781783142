import { useState } from 'react'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import Select from 'react-select';
import debounce from 'lodash/debounce';

interface selectProps {
    onChange: any
}

const UserSelector = ({ onChange }: selectProps) => {

    const [allUsers, setAllUsers] = useState([])
    const [isFetching, setIsFetching] = useState(false)


    //handleSearch
    const handleSearchInput = debounce((searchVal: any) => {
        if (searchVal) {
            setIsFetching(true)
            BaseService.get_api(`${global_variables.get_all_users}?filter={"fullName": {"$regex":${JSON.stringify(searchVal)},"$options":"i"}}`)
                .then((response) => {
                    // console.log(response?.data?.payload)
                    const arrangedData = response?.data?.payload.map((dd: any) => (
                        { value: dd?.userid, label: dd?.fullName }
                    ))
                    setAllUsers(arrangedData)
                    setIsFetching(false)
                }).catch((error) => {
                    console.log(error)
                })
        }
    }, 500);


    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search user name"
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allUsers}
                onChange={onChange}
                onInputChange={(val: any) => handleSearchInput(val)}
            />
        </>
    )
}

export default UserSelector
