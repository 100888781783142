import React, { useState, useEffect } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Card, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import { PostRegion } from "../../functions/AllPrices";
import ShowAlert from "../Alerts/all_alerts";
import ShowToast from "../Alerts/all_toasts";
import { Empty, Spin } from "antd";
const isoCountryCurrency = require("iso-country-currency");

const RegionsTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);

  const [countryList, setCountryList] = useState([]);
  const [d_country, setDCountry] = useState("");
  const [d_region, setDRegion] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //Onload
  useEffect(() => {
    // get countries on load
    var data = isoCountryCurrency.getAllISOCodes();
    //console.log(data)
    setCountryList(data);

    fetchData(limit, skip);
  }, []);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_all_regions +
      `?limit=${lim}&skip=${sk}&sort={"updatedAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Region",
      selector: (row) => row.region,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Date Updated",
      selector: (row) => moment(row.updatedAt).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#editRegionModal"
              onClick={showDetails.bind(this, index)}
            >
              <PencilIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const showDetails = (i) => {
    var go = theData[i]["_id"];

    var uniqueData = theData.filter(function (theData) {
      return theData._id === go;
    });
    // console.log(uniqueData);
    setDCountry(uniqueData[0]["country"]);
    setDRegion(uniqueData[0]["region"]);
  };

  //Update Region
  const doUpdateRegion = () => {
    if (d_country === "" || d_region === "") {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = JSON.stringify({
        country: d_country,
        region: d_region,
      });

      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to post this region?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          ShowAlert.loading_alert("Adding new region...");
          //Create region function
          PostRegion(data);
        }
      });
    }
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);

    const the_route =
      global_variables().get_all_regions +
      `?filter={"country":{"$regex":${JSON.stringify(filQry)},"$options":"i"}}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <div className="row mb-3">
            <div className="col-md-6 mt-2"></div>

            <form className="col-md-6" onSubmit={handleSearch}>
              <div className="input-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                >
                  <option defaultValue="0" disabled>
                    Filter by ...{" "}
                  </option>
                  <option value="country">Country</option>
                </select>

                <input
                  className="form-control shadow-none"
                  placeholder="Search..."
                  id="truckNum"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  autoComplete="off"
                  type="text"
                  style={{ border: "1px solid #d9e2ef" }}
                  required
                  value={filQry}
                  onChange={(e) => setFilQry(e.target.value)}
                  autoFocus
                />
                <button
                  type="submit"
                  className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>

          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Edit Region modal */}
      <div
        className="modal fade modal-md"
        id="editRegionModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Post Region
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                <div className="form-group mt-2">
                  <select
                    className="form-select form-control"
                    value={d_country}
                    onChange={(e) => setDCountry(e.target.value)}
                    aria-label="Default select example"
                    id="dropdown"
                  >
                    <option defaultValue="Select...">Select Country</option>
                    {countryList.map((the_d, index) => {
                      return (
                        <option key={index} value={the_d.countryName}>
                          {the_d.countryName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    type="text"
                    value={d_region}
                    onChange={(e) => setDRegion(e.target.value)}
                    placeholder="Region"
                  />
                </div>
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#priceTypeModal"
              >
                Cancel
              </button>

              <button
                type="button"
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                onClick={() => doUpdateRegion()}
              >
                Update Region
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegionsTable;
