import React from 'react';
import { Link } from 'react-router-dom';

const ModalButton = (props) => {
    return (
        <>
            <Link className='bg-default_orange text-default_white w-fit px-3 py-1 text-lg rounded-full hover:text-default_white hover:bg-default_orange' to={props.route} onClick={() => props.what_to_do()}>{props.btn_name}</Link>
        </>
    )
}

export default ModalButton