import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import BaseService from "../../helpers/baseServices";
import { Empty, Spin } from "antd";
import DataTable from "react-data-table-component";
import { global_variables } from "../../helpers/globalVariables";

const SmallTransactionsTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);

  //Onload
  useEffect(() => {
    BaseService.get_api(
      `${global_variables.get_all_transactions}?limit=5&sort={"createdAt":-1}`
    )
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response?.data?.payload);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  //Table columns
  const columns: any = [
    {
      name: "",
      selector: (row: any) => (
        <img
          className="img-fluid rounded-full py-1"
          src={row?.driver_data?.["image"]}
          width="45px"
        />
      ),
      width: "10%",
    },
    {
      name: "Driver",
      selector: (row: any) => row?.driver_data?.["fullName"],
    },
    {
      name: "Amount (Gh¢)",
      selector: (row: any) => row?.amount.toFixed(2),
    },
    {
      name: "Pickup",
      selector: (row: any) => row?.trip_data?.["pickUpPlace"],
    },
    {
      name: "Destination",
      selector: (row: any) => row?.trip_data?.["destinationPlace"],
    },
    {
      name: "Trip Date & Time",
      selector: (row: any) => moment(row?.createdAt).format("Do MMM YYYY - h:mm a"),
    },
  ];

  return (
    <>
      {/* Table */}

      <DataTable
        columns={columns}
        data={theData}
        noDataComponent={
          <div className="mt-[30px]">
            <Empty description="No transactions have been recorded" />
          </div>
        }
        progressPending={loading}
        progressComponent={
          <div className="flex justify-center items-center h-[250px]">
            <Spin size="large" />
          </div>
        }
      />
    </>
  );
};

export default SmallTransactionsTable;
