import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import PrivateRoute from './mixins/PrivateRoute';
import Administration from './pages/admin/Administration';
import AppUsers from './pages/app_users/AppUsers';
import AdminSignUp from './pages/auth/AdminSignUp';
import AuthVerify2 from './pages/auth/AuthVerify2';
import NewPass from './pages/auth/NewPass';
import CarsAndClasses from './pages/carsandclasses/CarsAndClasses';
import LiveChat from './pages/Chat/LiveChat';
import Comissions from './pages/comissions/Comissions';
import Dashboard from './pages/dashboard/Dashboard';
import Drivers from './pages/drivers/Drivers';
import Bookings from './pages/manual_bookings/Bookings';
import Notifications from './pages/notifications/Notifications';
import Payments from './pages/payments/Payments';
import PromoCode from './pages/promo_code/PromoCode';
import Settings from './pages/settings/Settings';
import Tracking from './pages/tracking/Tracking';
import Transactions from './pages/Transactions/Transactions';
import TripHistory from './pages/trip_history/TripHistory';
import Vehicles from './pages/vehicles/Vehicles';
import NewSignInPage from './pages/auth/newSignInPage';
import { Alert } from 'antd';
import { connectionEnum } from './sockets/connection.model';
import socketConnection from './sockets/connection';
import VerifyForPwdReset from './pages/auth/verifyForPwdReset';
import { GeneralContextProvider } from './contexts/generalContext';


function App() {


  const [connection, setConnection] = useState(connectionEnum.connected)
  const [hideAlert, setHideAlert] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState("Connecting ...")

  const switcherName = (value: any) => {
    switch (value) {
      case "Connected to the internet":
        return "success";
      case "Connection lost":
        return "error"
      default:
        return "warning"
    }
  }

  useEffect(() => {

    const connectionSubscription = socketConnection.listener$.subscribe((response: any) => {
      setHideAlert(false);
      if (response['listener'] == "connection") {
        setConnection(response.data.stage)
      }
    });

    return () => {
      //stop subscriptions
      connectionSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    switch (connection) {
      case connectionEnum.connected:
        setAlertDisplay('Connected to the internet')
        break;

      case connectionEnum.disconnected:
        setAlertDisplay('Connection lost')
        break;

      case connectionEnum.reconnecting:
        setAlertDisplay('Internet is not stable, reconnecting now')
        break;
    }

    if (connection === connectionEnum.connected) {
      setTimeout(() => {
        setHideAlert(true);
      }, 5000)
    }
  }, [connection])


  return (
    <div className="relative">
      <GeneralContextProvider>
        <Router>
          <Routes>
            <Route path='/*' element={<h4>Page not found!!</h4>} />
            <Route path='/' element={<NewSignInPage />} />
            <Route path='/admin.boss_1.new' element={<AdminSignUp />} />
            <Route path='/setNewPass' element={<NewPass />} />


            <Route path='/verify-reset/:id' element={<VerifyForPwdReset />} />
            <Route path='/verify-account' element={<AuthVerify2 />} />
            <Route path='/administration' element={<PrivateRoute><Administration /></PrivateRoute>} />
            <Route path='/payments' element={<PrivateRoute><Payments /></PrivateRoute>} />
            <Route path='/comissions' element={<PrivateRoute><Comissions /></PrivateRoute>} />
            <Route path='/cars_classes' element={<PrivateRoute><CarsAndClasses /></PrivateRoute>} />
            <Route path='/transactions' element={<PrivateRoute><Transactions /></PrivateRoute>} />
            <Route path='/notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
            <Route path='/livechat' element={<PrivateRoute><LiveChat /></PrivateRoute>} />
            <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path='/app_users' element={<PrivateRoute><AppUsers /></PrivateRoute>} />
            <Route path='/drivers' element={<PrivateRoute><Drivers /></PrivateRoute>} />
            <Route path='/vehicles' element={<PrivateRoute><Vehicles /></PrivateRoute>} />
            <Route path='/tracking' element={<PrivateRoute><Tracking /></PrivateRoute>} />
            <Route path='/trip_history' element={<PrivateRoute><TripHistory /></PrivateRoute>} />
            <Route path='/promo_codes' element={<PrivateRoute><PromoCode /></PrivateRoute>} />
            <Route path='/settings' element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path='/bookings' element={<PrivateRoute><Bookings /></PrivateRoute>} />
          </Routes>
        </Router>
      </GeneralContextProvider>

      {/* loading alert */}
      <div className="fixed bottom-5 z-50 right-5">
        {hideAlert ? <></> : <Alert message={alertDisplay} type={switcherName(alertDisplay) as any ?? 'warning' as any} showIcon closable />}
      </div>
    </div>
  );
}

export default App;
