import React, { useEffect, useState } from "react";
import {
  DocumentMagnifyingGlassIcon,
  EyeIcon,
  PlusIcon,
  TableCellsIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import TableShimmer from "../Shimmers/TableShimmer";
import DataTable from "react-data-table-component";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import global_variables from "../../mixins/globarVars";
import BaseService from "../../mixins/baseServices";
import NoDataComponent from "../layouts/NoDataComponent";
import ShowAlert from "../Alerts/all_alerts";
import { DeleteProfession, DoAssignRole } from "../../functions/Admin";
import ModalXBtn from "../Buttons/ModalXBtn";
import CloseModal from "../Buttons/CloseModal";
import moment from "moment-timezone";
import { Empty, Spin } from "antd";
import CreateProfessionModal from "../Modals/CreateProfessionModal";

const ProfessionsTable = () => {
  const [loading, setLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(true);
  const [data, setData] = useState([]);
  const [role_data, setRole_Data] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [uniquedata, setUniqueData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);

  const [profession, setProfession] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [reFetch, setReFetch] = useState(false);

  //On load
  useEffect(() => {
    fetchData(limit, skip);

    const roles_route = global_variables().get_all_roles;

    BaseService.get_api(roles_route)
      .then((response) => {
        //console.log(response.data.payload);
        setRole_Data(response.data.payload);
        setRoleLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [reFetch]);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_all_professions +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTotalRows(response.data.total);
        setData(response.data.payload);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row.name,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "40%",
    },
    {
      name: "Created On",
      selector: (row) =>
        moment(row.createdAt.slice(0, 10)).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Assign Role"
              data-bs-toggle="modal"
              data-bs-target="#assignModal"
              onClick={PickProf.bind(this, index)}
            >
              <PlusIcon className="w-5 h-5" />
            </Link>
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#viewProfModal"
              onClick={openDetails.bind(this, index)}
            >
              <DocumentMagnifyingGlassIcon className="w-5 h-5" />
            </Link>
            <Link
              className="text-default_red hover:text-default_red"
              to="#"
              title="Delete"
              onClick={doDelete.bind(this, index)}
            >
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  //Delete profession
  const doDelete = (i) => {
    var go = data[i]["name"];

    var uniqueData = data.filter(function (theData) {
      return theData.name === go;
    });

    const delName = { name: uniqueData[0]["name"] };

    ShowAlert.question_alert(
      "Wait...",
      "Confirm you want to delete this profession!",
      "Confirm"
    ).then((result) => {
      if (result.isConfirmed) {
        ShowAlert.loading_alert("Deleting...");
        // console.log(delName)
        DeleteProfession(delName);
      }
    });
  };

  //View Details
  const openDetails = (i) => {
    var go = data[i]["name"];

    var uniqueData = data.filter(function (theData) {
      return theData.name === go;
    });

    //console.log(uniqueData);
    setUniqueData(uniqueData);
    setFiltered(true);
  };

  //Pick Prof to assign
  const PickProf = (i) => {
    var go = data[i]["name"];

    var uniqueProf = data.filter(function (theData) {
      return theData.name === go;
    });
    //console.log(uniqueProf[0]['name']);
    setProfession(uniqueProf[0]["name"]);
  };

  //Do real assign
  const doAssign = (access_point) => {
    ShowAlert.question_alert(
      "Confirm!",
      "Do you wish to assign this role to the selected profession?",
      "Yes"
    ).then((result) => {
      if (result.isConfirmed) {
        const data = {
          accessPoint: access_point,
          profession: profession,
        };
        ShowAlert.loading_alert("Assigning...");
        DoAssignRole(data);
      }
    });
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    const the_route =
      global_variables().get_all_professions +
      `?filter={"name":{"$regex":${JSON.stringify(filQry)},"$options":"i"}}`;

    BaseService.get_api(the_route)
      .then((response) => {
        //console.log(response.data.payload);
        setData(response.data.payload);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <div className="row mb-3">
            <div className="col-md-6 mt-2">
              <Link
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                to="#"
                onClick={() => setOpenAddModal(true)}
              >
                + Add
              </Link>
            </div>

            <form className="col-md-6" onSubmit={handleSearch}>
              <div className="input-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                >
                  <option defaultValue="0" disabled>
                    Filter by ...{" "}
                  </option>
                  <option value="title">Title</option>
                </select>

                <input
                  className="form-control shadow-none"
                  placeholder="Search..."
                  id="truckNum"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  autoComplete="off"
                  type="text"
                  style={{ border: "1px solid #d9e2ef" }}
                  required
                  value={filQry}
                  onChange={(e) => setFilQry(e.target.value)}
                  autoFocus
                />
                <button
                  type="submit"
                  className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>

          <DataTable
            progressPending={loading}
            data={data}
            columns={columns}
            pagination
            paginationServer
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Create Profession Modal */}
      {openAddModal && (
        <CreateProfessionModal
          isOpened={openAddModal}
          handleClose={() => setOpenAddModal(false)}
          handleReFetch={() => setReFetch(!reFetch)}
        />
      )}

      {/* View profession Modal */}
      <div
        className="modal fade modal-xl"
        id="viewProfModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                View Details
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <h3 className="mb-4 text-lg font-semibold">
                Roles Assigned To This Profession
              </h3>
              {!filtered ? (
                <TableShimmer />
              ) : (
                <>
                  {uniquedata[0]["roles"].length === 0 ? (
                    <h3>This profession has no roles</h3>
                  ) : (
                    <>
                      {uniquedata[0]["roles"].map((d, index) => {
                        return (
                          <div key={index} className="flex gap-4 mb-3">
                            <p>{index + 1}.</p>
                            <p>{d._id}</p>
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>

      {/* Assign Role Modal */}
      <div
        className="modal fade modal-md"
        id="assignModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Select a role
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              {roleLoading ? (
                <TableShimmer />
              ) : (
                <>
                  {role_data.map((d, index) => {
                    return (
                      <>
                        <div key={index} className="mb-3 flex gap-4">
                          <p>{index + 1}.</p>
                          <Link to="#" onClick={() => doAssign(d._id)}>
                            {d._id}
                          </Link>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionsTable;
