export default function global_variables() {
  return {
    signup_url: "/rydsom/authentication/signup",
    signin_url: "/rydsom/authentication/signin",
    forget_pss: "/rydsom/authentication/forgetpassword",
    verify_code: "/rydsom/authentication/verify_account",
    reset_password: "/rydsom/authentication/password",
    create_profession: "/rydsom/secuirty/create_professions",
    delete_profession: "/rydsom/secuirty/delete_professions",
    get_all_professions: "/rydsom/secuirty/get_professions",
    get_all_roles: "/rydsom/secuirty/get_roles",
    assign_role: "/rydsom/secuirty/add_roles",
    get_all_users: "/rydsom/user/all",
    get_user_details: "/rydsom/user/details",
    get_all_transactions: "/rydsom/transaction/admin",
    get_all_admins: `/rydsom/user/all`,
    create_user: "/rydsom/user/create",
    delete_user: "/rydsom/user/delete",
    suspend_user: "/rydsom/user/suspend",
    unsuspend_user: "/rydsom/user/unsuspend",
    send_email: "/rydsom/email",
    send_sms: "/rydsom/sms",
    file_upload: "/rydsom/file/upload",
    get_all_drivers: "/rydsom/driver/all",
    get_all_vehicles: "/rydsom/car/all",
    approve_driver: "/rydsom/driver/approve",
    approve_vehicle: "/rydsom/car/approve",
    get_all_trips: "/rydsom/trip/all-admin",
    create_promo: "/rydsom/price/promocode",
    get_all_promo: "/rydsom/price/allpromocode-admin",
    update_promo: "/rydsom/price/promocode",
    get_all_prices: "/rydsom/price/all",
    create_price: "/rydsom/price/create",
    create_regional_price: "/rydsom/price/region-create",
    get_all_regional_prices: "/rydsom/price/regionalprices",
    get_all_wallet_limit: "/rydsom/payment/wallet-limit",
    post_wallet_limit: "/rydsom/payment/wallet-limit",
    get_all_regions: "/rydsom/setup/region",
    post_regions: "/rydsom/setup/region",
    post_update: "/rydsom/update/post",
    get_all_updates: "/rydsom/update/update-all",
    post_customer_service: "/rydsom/customer/post",
    get_customer_service: "/rydsom/customer/all",
    post_cancel_reason: "/rydsom/cancel/cancelreason",
    get_cancel_resons: "/rydsom/cancel/cancelreason",
    delete_cancel_reason: "/rydsom/cancel/cancelreason",
    get_chatheads: "/rydsom/chat/chatheads",
    get_userchat: "/rydsom/chat/admin-all/",
    realtime_url: "/rydsome-realtime",
    car_feature_url: "/rydsom/car/feature",
    update_feature_url: "/rydsom/car/feature",
    car_brand_url: "/rydsom/car/brand",
    rydsom_class_url: "/rydsom/car/class",
    car_model_url: "/rydsom/car/vehiclemodel",
    payment_description_url: "/rydsom/payment/payment-desc",
    get_all_payments: "/rydsom/payment/all",
    approve_transaction: "/rydsom/payment/verification",
  };
}
