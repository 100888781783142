import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../../images/logo.png"
import { HiOutlineBuildingOffice2, HiOutlineBellAlert, HiOutlineSquares2X2, HiOutlineBars3, HiOutlineUsers, HiOutlineCog, HiOutlineMapPin } from "react-icons/hi2";
import { PiMoneyThin, PiUserListThin } from "react-icons/pi";
import { MdOutlineMultipleStop } from "react-icons/md";
import { IoCarSportOutline, IoChatbubblesOutline, IoListOutline, IoIdCardOutline, IoGiftOutline } from "react-icons/io5";
import { AiOutlineHistory } from "react-icons/ai";


interface sideBarProps {
  adminActive?: any
  payActive?: any
  commActive?: any
  carsActive?: any
  transActive?: any
  notifyActive?: any
  chatActive?: any
  dashActive?: any
  usersActive?: any
  driverActive?: any
  vechActive?: any
  trackActive?: any
  tripActive?: any
  promoActive?: any
  settingsActive?: any
}

const CustomSideBar = ({ adminActive, payActive, commActive, carsActive, transActive, notifyActive, chatActive, dashActive, usersActive, driverActive, vechActive, trackActive, tripActive, promoActive, settingsActive }: sideBarProps) => {

  const [isCollapsed, setIsCollapsed] = useState(false);


  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <Sidebar collapsed={isCollapsed}>
      <Menu className="bg-white h-full" id="custom_scroll">
        {/* logo */}
        <div className="py-4 flex items-center gap-5 w-full">
          <HiOutlineBars3
            className="h-8 w-8 cursor-pointer ml-6"
            onClick={() => handleCollapse()}
          />
          {!isCollapsed && (
            <img className="w-[80px]" src={logo} alt="log" />
          )}
        </div>

        {/* menus */}
        <div>
          <MenuItem
            icon={<HiOutlineBuildingOffice2 className="w-5 h-5 ico" />}
            component={
              <Link to="/administration" />
            }
            className={adminActive}
          >
            <p className="text-sm">Administration</p>
          </MenuItem>

          <MenuItem
            icon={<PiMoneyThin className="w-5 h-5 ico" />}
            component={
              <Link to="/payments" />
            }
            className={payActive}
          >
            <p className="text-sm">Payments & Approvals</p>
          </MenuItem>

          <MenuItem
            icon={<MdOutlineMultipleStop className="w-5 h-5 ico" />}
            component={
              <Link to="/comissions" />
            }
            className={commActive}
          >
            <p className="text-sm">Commissions</p>
          </MenuItem>

          <MenuItem
            icon={<IoCarSportOutline className="w-5 h-5 ico" />}
            component={
              <Link to="/cars_classes" />
            }
            className={carsActive}
          >
            <p className="text-sm">Cars & Classes</p>
          </MenuItem>

          <MenuItem
            icon={<IoListOutline className="w-5 h-5 ico" />}
            component={
              <Link to="/transactions" />
            }
            className={transActive}
          >
            <p className="text-sm">Transactions</p>
          </MenuItem>

          <MenuItem
            icon={<HiOutlineBellAlert className="w-5 h-5 ico" />}
            component={
              <Link to="/notifications" />
            }
            className={notifyActive}
          >
            <p className="text-sm">Notifications</p>
          </MenuItem>

          <MenuItem
            icon={<IoChatbubblesOutline className="w-5 h-5 ico" />}
            component={
              <Link to="/livechat" />
            }
            className={chatActive}
          >
            <p className="text-sm">Chat</p>
          </MenuItem>

          <hr className="my-2" />

          <MenuItem
            icon={<HiOutlineSquares2X2 className="w-5 h-5 ico" />}
            component={
              <Link to="/dashboard" />
            }
            className={dashActive}
          >
            <p className="text-sm">Dashboard</p>
          </MenuItem>

          <MenuItem
            icon={<HiOutlineUsers className="w-5 h-5 ico" />}
            component={
              <Link to="/app_users" />
            }
            className={usersActive}
          >
            <p className="text-sm">App Users</p>
          </MenuItem>

          <MenuItem
            icon={<IoIdCardOutline className="w-5 h-5 ico" />}
            component={
              <Link to="/drivers" />
            }
            className={driverActive}
          >
            <p className="text-sm">Drivers</p>
          </MenuItem>

          <MenuItem
            icon={<PiUserListThin className="w-5 h-5 ico" />}
            component={
              <Link to="/vehicles" />
            }
            className={vechActive}
          >
            <p className="text-sm">Vehicles</p>
          </MenuItem>

          <MenuItem
            icon={<HiOutlineMapPin className="w-5 h-5 ico" />}
            component={
              <Link to="/tracking" />
            }
            className={trackActive}
          >
            <p className="text-sm">Tracking</p>
          </MenuItem>

          <MenuItem
            icon={<AiOutlineHistory className="w-5 h-5 ico" />}
            component={
              <Link to="/trip_history" />
            }
            className={tripActive}
          >
            <p className="text-sm">Trip History</p>
          </MenuItem>

          <MenuItem
            icon={<IoGiftOutline className="w-5 h-5 ico" />}
            component={
              <Link to="/promo_codes" />
            }
            className={promoActive}
          >
            <p className="text-sm">Promo Codes</p>
          </MenuItem>

          <MenuItem
            icon={<HiOutlineCog className="w-5 h-5 ico" />}
            component={
              <Link to="/settings" />
            }
            className={settingsActive}
          >
            <p className="text-sm">Settings</p>
          </MenuItem>

        </div>

      </Menu>
    </Sidebar>
  );
};

export default CustomSideBar;
