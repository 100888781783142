import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShowAlert from "../../components/Alerts/all_alerts";
import ShowToast from "../../components/Alerts/all_toasts";
import ModalXBtn from "../../components/Buttons/ModalXBtn";
import PromoTable from "../../components/Tables/PromoTable";
import { CreateCode } from "../../functions/PromoFunctions";
import PageLayout from "../../components/navLayouts/pageLayout";
const isoCountryCurrency = require("iso-country-currency");

const PromoCode = () => {
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");
  const [percent, setPercent] = useState("");
  const [d_limit, setDLimit] = useState("");
  const [tripCount, setTripCount] = useState("");
  const [type, setType] = useState("user");
  const [typeValue, setTypeValue] = useState("unrestricted");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [d_country, setDCountry] = useState("Select...");

  //onload
  useEffect(() => {
    // get countries on load
    var data = isoCountryCurrency.getAllISOCodes();
    //console.log(data)
    setCountryList(data);
  }, []);

  //Run Create code function
  const doCreate = (e) => {
    e.preventDefault();

    if (type === "city" && typeValue === "unrestricted") {
      ShowToast.warning_toast("Please enter a vaid city name!");
    } else if (type === "regional" && typeValue === "unrestricted") {
      ShowToast.warning_toast("Please enter a valid region name!");
    } else if (d_country === "Select..." || d_limit === "") {
      ShowToast.warning_toast("Please select a country!");
    } else {
      var data = {
        country: d_country,
        promoCode: code,
        limit: parseInt(d_limit),
        amount: parseInt(amount),
        percent: parseInt(percent),
        tripNumber: parseInt(tripCount),
        type: type.toLowerCase(),
        typeValue: typeValue,
        activationDate: start,
        expiryDate: end,
      };
      // console.log(data)

      ShowAlert.loading_alert("Creating promo code...");

      CreateCode(data);
    }
  };

  return (
    <>
      <PageLayout promoActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold">Promo Codes</p>

            <Link
              className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#addCodeModal"
            >
              + Create Code
            </Link>
          </div>

          <hr className="my-3" />

          {/* Table */}
          <PromoTable />
        </div>
      </PageLayout>

      {/* Create promo code modal */}
      <div
        className="modal fade modal-md"
        id="addCodeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                New Promo Code
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                <form onSubmit={doCreate}>
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      value={d_country}
                      onChange={(e) => setDCountry(e.target.value)}
                      aria-label="Default select example"
                      id="dropdown"
                    >
                      <option defaultValue="Select...">Select Country</option>
                      {countryList.map((the_d, index) => {
                        return (
                          <option key={index} value={the_d.countryName}>
                            {the_d.countryName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      className="form-control rounded-md"
                      type="text"
                      id="code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="Promo Code"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      className="form-control rounded-md"
                      type="text"
                      id="amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      placeholder="Amount"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      className="form-control rounded-md"
                      type="text"
                      id="limit"
                      value={d_limit}
                      onChange={(e) => setDLimit(e.target.value)}
                      placeholder="No. of users"
                      required
                    />
                  </div>

                  <Row>
                    <Col className="mt-3" md={6}>
                      <div className="form-group">
                        <input
                          className="form-control rounded-md"
                          type="text"
                          id="percentage"
                          value={percent}
                          onChange={(e) => setPercent(e.target.value)}
                          placeholder="Percentage Off"
                          required
                        />
                      </div>
                    </Col>
                    <Col className="mt-3" md={6}>
                      <div className="form-group">
                        <input
                          className="form-control rounded-md"
                          type="text"
                          id="times"
                          value={tripCount}
                          onChange={(e) => setTripCount(e.target.value)}
                          placeholder="Times of Use"
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mt-3" md={6}>
                      <div className="form-group">
                        <label htmlFor="start">Start Date</label>
                        <input
                          className="form-control rounded-md"
                          type="date"
                          id="start"
                          value={start}
                          onChange={(e) => setStart(e.target.value)}
                          required
                        />
                      </div>
                    </Col>
                    <Col className="mt-3" md={6}>
                      <div className="form-group">
                        <label htmlFor="end">Expiry Date</label>
                        <input
                          className="form-control rounded-md"
                          type="date"
                          id="end"
                          value={end}
                          onChange={(e) => setEnd(e.target.value)}
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mt-3" md={6}>
                      <div className="form-group">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          id="chooseType"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option defaultValue="user">User</option>
                          <option value="city">City</option>
                          <option value="regional">Region</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="mt-3" md={6}>
                      {type === "user" ? (
                        <></>
                      ) : (
                        <div className="form-group">
                          <input
                            className="form-control rounded-md"
                            type="text"
                            id="end"
                            value={typeValue}
                            onChange={(e) => setTypeValue(e.target.value)}
                            placeholder="Value"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="mt-4 bg-default_orange text-default_white w-fit px-3 py-1 text-lg rounded-full hover:text-default_white hover:bg-default_orange"
                    >
                      Create Code
                    </button>
                  </div>
                </form>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoCode;
