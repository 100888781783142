import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import user_img from "../../images/user.png";
import global_variables from "../../mixins/globarVars";
import BaseService from "../../mixins/baseServices";
import ShowToast from "../../components/Alerts/all_toasts";
import ShowAlert from "../../components/Alerts/all_alerts";
import { UpdateUserDetails } from "../../functions/Authentication";
import PageLayout from "../../components/navLayouts/pageLayout";

const Settings = () => {
  const [loaded2, setLoaded2] = useState(false);
  const [userData, setUserData] = useState([]);

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [dPhone, setDPhone] = useState("");
  const [dMail, setDMail] = useState("");

  //onload
  useEffect(() => {
    const the_route = global_variables().get_user_details;

    BaseService.get_api(the_route)
      .then((response) => {
        //console.log(response.data.payload);
        setUserData(response.data.payload);
        setLoaded2(true);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  //update function
  const doUpdate = () => {
    if (fName === "" || lName === "" || dMail === "" || dPhone === "") {
      ShowToast.warning_toast("Please fill out all fields");
    } else {
      ShowAlert.question_alert(
        "Confirm",
        "Do you want to proceed with updates?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          ShowAlert.loading_alert("Saving changes...");

          const userData = {
            fullName: fName + " " + lName,
            firstName: fName,
            email: dMail,
            phone: dPhone,
            lastName: lName,
          };
          //call update function
          UpdateUserDetails(userData);
        }
      });
    }
  };

  return (
    <>
      <PageLayout settingsActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Settings</p>

          <hr className="my-3" />

          {!loaded2 ? (
            <div className="text-center mt-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p>Please wait...</p>
            </div>
          ) : (
            <>
              <Card className="shadow-md mt-4  border-y-0 border-default_orange border-b-0 border-x-2">
                <Card.Body>
                  <p className="font-bold text-default_orange text-xl">
                    My Information
                  </p>

                  <Row>
                    <Col className="mt-4 mb-4" md={4}>
                      <img
                        className="rounded-full flex ml-auto mr-auto"
                        src={user_img}
                        width="150px"
                        alt="..."
                      />
                    </Col>
                    <Col className="mt-4 mb-4" md={4}>
                      <div className="flex gap-3">
                        <p className="font-semibold">First Name:</p>
                        <p>{userData["user_data"]["firstName"]}</p>
                      </div>
                      <div className="mt-2 flex gap-3">
                        <p className="font-semibold">Last Name:</p>
                        <p>{userData["user_data"]["lastName"]}</p>
                      </div>
                      <div className="mt-2 flex gap-3">
                        <p className="font-semibold">Phone:</p>
                        <p>{userData["user_data"]["phone"]}</p>
                      </div>
                      <div className="mt-2 flex gap-3">
                        <p className="font-semibold">Email:</p>
                        <p>
                          {userData["user_data"]["email"]
                            ? userData["user_data"]["email"]
                            : "N/A"}
                        </p>
                      </div>
                    </Col>
                    <Col className="mt-4 mb-4" md={4}>
                      <p className="font-semibold">Roles:</p>

                      <p className="capitalize">
                        {userData["user_data"]["userRoles"].join(", ")}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          )}

          <Card className="shadow-md mt-4  border-y-0 border-default_orange border-b-0 border-x-2">
            <Card.Body>
              <p className="font-bold text-default_orange text-xl">
                Edit Information
              </p>
              <Row>
                <Col md={6}>
                  <div className="form group mt-3">
                    <input
                      className="form-control"
                      value={fName}
                      onChange={(e) => setFName(e.target.value)}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form group mt-3">
                    <input
                      className="form-control"
                      value={dMail}
                      onChange={(e) => setDMail(e.target.value)}
                      placeholder="Email"
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form group mt-3">
                    <input
                      className="form-control"
                      value={lName}
                      onChange={(e) => setLName(e.target.value)}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="form group mt-3">
                    <input
                      className="form-control"
                      value={dPhone}
                      onChange={(e) => setDPhone(e.target.value)}
                      placeholder="Phone"
                    />
                  </div>
                  <div className="form group mt-3">
                    <button
                      className="form-control px-3 bg-default_orange text-default_white"
                      onClick={() => doUpdate()}
                    >
                      Update My Information
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </PageLayout>
    </>
  );
};

export default Settings;
