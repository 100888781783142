import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import ShowAlert from "../Alerts/all_alerts";
import { DeleteCancelReason, PostCancelReason } from "../../functions/Admin";
import ShowToast from "../Alerts/all_toasts";
import { Empty, Spin } from "antd";

const CancelTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  const [d_country, setDCountry] = useState("");
  const [d_currency, setDCurrency] = useState("");
  const [d_reason, setDReason] = useState("");
  const [d_user, setDUser] = useState("");
  const [d_time, setDTime] = useState("");
  const [d_unit, setDUnit] = useState("m");
  const [d_charge, setDCharge] = useState("");

  //Onload
  useEffect(() => {
    fetchData(limit, skip);
  }, []);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_cancel_resons +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      width: "30%",
    },
    {
      name: "Cancel Period",
      selector: (row) => row.time + row.unit,
    },
    {
      name: "Person",
      selector: (row) => row.user,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <div className="flex gap-3">
            <Link
              to="#"
              title="Edit"
              data-bs-toggle="modal"
              data-bs-target="#editReason"
              onClick={CheckPoint.bind(this, row)}
            >
              <PencilSquareIcon className="w-5 h-5" />
            </Link>
            <Link to="#" title="Delete" onClick={doDelete.bind(this, row)}>
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Delete cancel reason
  const CheckPoint = (i) => {
    // console.log(i)
    setDCharge(i["charge"]);
    setDTime(i["time"]);
    setDUnit(i["unit"]);
    setDCountry(i["country"]);
    setDCurrency(i["currency"]);
    setDReason(i["reason"]);
    setDUser(i["user"]);
  };

  const doEditReason = () => {
    if (
      d_country === "Select Country..." ||
      d_reason === "" ||
      d_time === "" ||
      d_unit === "Select Unit..." ||
      d_user === "Select User..." ||
      d_charge === "" ||
      d_currency === ""
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = {
        reason: d_reason,
        time: parseInt(d_time),
        user: d_user,
        unit: d_unit,
        charge: parseInt(d_charge),
        country: d_country,
        currency: d_currency,
      };
      ShowAlert.question_alert(
        "Confirm",
        "Do you want to edit this reason?",
        "Confirm"
      ).then((result) => {
        if (result.isConfirmed) {
          ShowAlert.loading_alert("Updating reason...");

          PostCancelReason(data);
        }
      });
    }
  };

  //Delete cancel reason
  const doDelete = (i) => {
    // console.log(i)
    var data = {
      reason: i["reason"],
      country: i["country"],
    };
    // console.log(data);
    ShowAlert.question_alert(
      "Confirm",
      "Do you want to delete this reason?",
      "Confirm"
    ).then((result) => {
      if (result.isConfirmed) {
        ShowAlert.loading_alert("Deleting reason...");

        DeleteCancelReason(data);
      }
    });
  };

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Edit Cancel Reason */}
      <div
        className="modal fade modal-md"
        id="editReason"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Edit Cancel Reason
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    value={d_time}
                    onChange={(e) => setDTime(e.target.value)}
                    type="text"
                    placeholder="Minutes after accepting"
                  />
                </div>
                <div className="form-group mt-2">
                  <select
                    className="form-select form-control"
                    value={d_unit}
                    onChange={(e) => setDUnit(e.target.value)}
                    aria-label="Default select example"
                    id="dropdown2"
                  >
                    <option defaultValue="Select Unit..." selected>
                      Select Unit
                    </option>
                    <option value="m">Minute</option>
                  </select>
                </div>
                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    value={d_charge}
                    onChange={(e) => setDCharge(e.target.value)}
                    type="text"
                    placeholder="Charge"
                  />
                </div>
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-toggle="modal"
                data-bs-target="#customerModal"
              >
                Cancel
              </button>

              <button
                type="button"
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                onClick={() => doEditReason()}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelTable;
