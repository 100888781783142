import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import global_variables from "../../mixins/globarVars";
import baseSerVices from "../../mixins/baseServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import ModalXBtn from "../Buttons/ModalXBtn";
import CloseModal from "../Buttons/CloseModal";
import ShowAlert from "../Alerts/all_alerts";
import ShowToast from "../Alerts/all_toasts";
import { SuspendUser, UnsuspendUser } from "../../functions/Actions";
import place_holder from "../../images/user-profile.png";
import { Empty, Spin } from "antd";
import { useGeneralContext } from "../../contexts/generalContext";

const AppUsersTable = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const { reFetch, setReFetch } = useGeneralContext();

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [reason, setReason] = useState("");
  const [uniqueId, setUniqueId] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [dataIn, setDataIn] = useState(false);

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //on load
  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route = global_variables().get_all_users;
    const the_filter =
      '?filter={"userRoles":{"$regex":"user"}}' +
      `&limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    baseSerVices
      .get_api(the_route + the_filter)
      .then((response) => {
        // console.log(response.data.payload);
        setTotalRows(response.data.total);
        setData(response.data.payload);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "",
      selector: (row) => (
        <img
          className="w-10 h-10 rounded-full"
          src={row.image ? row.image : place_holder}
          alt="..."
        />
      ),
      width: "10%",
    },
    {
      name: "Full Name",
      selector: (row) => row.fullName,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Signup Date",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY - h:m a"),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.suspended ? (
            <p className="text-default_white bg-default_red px-3 py-1 rounded-xl">
              Suspended
            </p>
          ) : (
            <p className="text-default_white bg-default_green px-3 py-1 rounded-xl">
              Approved
            </p>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-3">
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#userDetailsModal"
              onClick={ShowDetails.bind(this, index)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
            {row.suspended ? (
              <Link
                to="#"
                title="Unsuspend"
                onClick={checkPoint2.bind(this, index)}
              >
                <CheckBadgeIcon className="w-5 h-5" />
              </Link>
            ) : (
              <Link
                to="#"
                title="Suspend"
                data-bs-toggle="modal"
                data-bs-target="#suspendModal"
                onClick={PickId.bind(this, index)}
              >
                <ExclamationTriangleIcon className="w-5 h-5" />
              </Link>
            )}
          </div>
        </>
      ),
    },
  ];

  //get userid for suspension
  const PickId = (i) => {
    var go = data[i]["userid"];

    var uniqueData = data.filter(function (theData) {
      return theData.userid === go;
    });

    setUniqueId(uniqueData[0]["userid"]);
    // console.log(uniqueData[0]['userid']);
  };

  //Checkpoint for Suspension
  const checkPoint = (e) => {
    e.preventDefault();
    if (start === "" || end === "" || reason === "") {
      ShowToast.warning_toast("Please complete all fields!");
    } else {
      ShowAlert.question_alert(
        "Confirm Suspension",
        "Would you like to suspend this user?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          //do suspension
          //console.log(start, end, reason);
          ShowAlert.loading_alert("Please wait...");
          const data = {
            userid: uniqueId,
            suspensionStart: start,
            suspensionEnd: end,
            reason: reason,
          };

          SuspendUser(data);
        }
      });
    }
  };

  //Checkpoint for Unsuspension
  const checkPoint2 = (i) => {
    var go = data[i]["userid"];

    var uniqueData = data.filter(function (theData) {
      return theData.userid === go;
    });

    // console.log(uniqueData[0]['userid']);

    ShowAlert.question_alert(
      "Confirm Unsuspension",
      "Would you like to unsuspend this user?",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        //do unsuspension

        ShowAlert.loading_alert("Please wait...");
        const data = {
          userid: uniqueData[0]["userid"],
        };

        UnsuspendUser(data);
      }
    });
  };

  //show details
  const ShowDetails = (i) => {
    var go = data[i]["_id"];

    var uniqueData = data.filter(function (theData) {
      return theData._id === go;
    });

    setSelectedData(uniqueData[0]);
    setDataIn(true);
    // console.log(uniqueData[0]);
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    const the_route = global_variables().get_all_users;
    const the_filter = `?filter={"userRoles":{"$regex":"user"},"fullName":{"$regex":${JSON.stringify(
      filQry
    )},"$options":"i"}}`;

    baseSerVices
      .get_api(the_route + the_filter)
      .then((response) => {
        //console.log(response.data.payload);
        setTotalRows(response.data.total);
        setData(response.data.payload);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>
          <form className="col-md-6" onSubmit={handleSearch}>
            <div className="input-group">
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
              >
                <option defaultValue="0" disabled>
                  Filter by ...{" "}
                </option>
                <option value="name">Full Name</option>
              </select>

              <input
                className="form-control shadow-none"
                placeholder="Search..."
                id="truckNum"
                aria-label="Search"
                aria-describedby="search-addon"
                autoComplete="off"
                type="text"
                style={{ border: "1px solid #d9e2ef" }}
                required
                value={filQry}
                onChange={(e) => setFilQry(e.target.value)}
                autoFocus
              />
              <button
                type="submit"
                className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
          />
        </Card.Body>
      </Card>

      {/* Suspension Modal */}
      <div
        className="modal fade modal-md"
        id="suspendModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Suspend user
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="startDate">Start Date:</label>
                  <input
                    type="date"
                    className="form-control rounded-md"
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    id="startDate"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="endDate">End Date:</label>
                  <input
                    type="date"
                    className="form-control rounded-md"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    id="endDate"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    type="text"
                    className="form-control rounded-md"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Reason for suspension"
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
              <button
                className="bg-default_orange text-default_white px-2 py-1 rounded-md text-lg"
                type="submit"
                onClick={checkPoint}
              >
                Suspend
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* View Details Modal */}
      <div
        className="modal fade modal-xl"
        id="userDetailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                User Details
              </h1>
              <ModalXBtn />
            </div>

            <div className="modal-body">
              <Container>
                {dataIn ? (
                  <Row>
                    <Col className="mt-3" md={6}>
                      <div className="flex gap-4 mt-3">
                        <p className="font-semibold">First Name:</p>
                        <p>{selectedData["firstName"]}</p>
                      </div>
                      <div className="flex gap-4 mt-3">
                        <p className="font-semibold">Middle Name:</p>
                        <p>
                          {selectedData["middleName"]
                            ? selectedData["middleName"]
                            : "..."}
                        </p>
                      </div>
                      <div className="flex gap-4 mt-3">
                        <p className="font-semibold">Last Name:</p>
                        <p>{selectedData["lastName"]}</p>
                      </div>
                      <div className="flex gap-4 mt-3">
                        <p className="font-semibold">Signup Date:</p>
                        <p>
                          {moment(selectedData["createdAt"]).format(
                            "Do MMM YYYY"
                          )}
                        </p>
                      </div>
                      <div className="flex gap-4 mt-3">
                        <p className="font-semibold">User Iamge:</p>
                        <img
                          className="img-fluid w-36"
                          src={selectedData["image"]}
                          alt="..."
                        />
                      </div>
                    </Col>
                    <Col className="mt-3" md={6}>
                      <div className="flex gap-4 mt-1">
                        <p className="font-semibold">Phone Number:</p>
                        <p>{selectedData["phone"]}</p>
                      </div>
                      <div className="mt-3">
                        <p className="font-semibold">Roles:</p>
                        {selectedData["userRoles"].map((the_d, index) => {
                          return (
                            <p key={index}>
                              {index + 1}. {the_d}
                            </p>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <p className="text-center">No data to display ...</p>
                )}
              </Container>
            </div>

            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppUsersTable;
