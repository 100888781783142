import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TripHistoryTable from "../../components/Tables/TripHistoryTable";
import CanceledTripsTable from "../../components/Tables/CanceledTripsTable";
import PageLayout from "../../components/navLayouts/pageLayout";

const TripHistory = () => {
  const [tabActive, setTabActive] = useState(0);

  //Tab names
  const tabNames = [
    {
      name: "Completed",
    },
    {
      name: "Cancelled",
    },
  ];

  const showActive = (i) => {
    if (i === tabActive) {
      return "rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl bg-default_orange text-default_white";
    } else {
      return "bg-text_white rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl hover:bg-default_orange hover:text-default_white";
    }
  };

  return (
    <>
      <PageLayout tripActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Trip History</p>

          <hr className="my-3" />

          <Tabs className="mt-3">
            <TabList className="bottom-0 mb-3 flex gap-2 text-xs md:text-md lg:text-lg xl:text-lg">
              {tabNames.map((item, index) => (
                <Tab key={index} className="border-0 bg-transparent text-sm">
                  <button
                    key={index}
                    onClick={setTabActive.bind(this, index)}
                    className={showActive(index)}
                  >
                    {item.name}
                  </button>
                </Tab>
              ))}
            </TabList>

            {/* Completed Teab */}
            <TabPanel>
              <TripHistoryTable />
            </TabPanel>

            {/* Cancelled Tab */}
            <TabPanel>
              <CanceledTripsTable />
            </TabPanel>
          </Tabs>
        </div>
      </PageLayout>
    </>
  );
};

export default TripHistory;
