import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import { Empty, Spin } from "antd";

const CustomerTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //Onload
  useEffect(() => {
    fetchData(limit, skip);
  }, []);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_customer_service +
      `?limit=${lim}&skip=${sk}&sort={"updated_at":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Full Name",
      selector: (row) => row.name,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "App",
      selector: (row) => row.app,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.created_at).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-3">
            <Link
              to={row.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
              title="WhatsApp"
            >
              <i className="fab fa-whatsapp" style={{ fontSize: "18px" }}></i>
            </Link>
            <Link
              to={row.facebook}
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <i className="fab fa-facebook" style={{ fontSize: "18px" }}></i>
            </Link>
            <Link
              to={row.instagram}
              target="_blank"
              rel="noopener noreferrer"
              title="Instagram"
            >
              <i className="fab fa-instagram" style={{ fontSize: "18px" }}></i>
            </Link>
            <Link
              to={row.twitter}
              target="_blank"
              rel="noopener noreferrer"
              title="Twitter"
            >
              <i className="fab fa-twitter" style={{ fontSize: "18px" }}></i>
            </Link>
          </div>
        </>
      ),
    },
  ];

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    const the_route =
      global_variables().get_customer_service +
      `?filter={"country":{"$regex":${JSON.stringify(filQry)},"$options":"i"}}`;

    BaseService.get_api(the_route)
      .then((response) => {
        //console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <div className="row mb-3">
            <div className="col-md-6 mt-2"></div>

            <form className="col-md-6" onSubmit={handleSearch}>
              <div className="input-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                >
                  <option defaultValue="0" disabled>
                    Filter by ...{" "}
                  </option>
                  <option value="country">Country</option>
                </select>

                <input
                  className="form-control shadow-none"
                  placeholder="Search..."
                  id="truckNum"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  autoComplete="off"
                  type="text"
                  style={{ border: "1px solid #d9e2ef" }}
                  required
                  value={filQry}
                  onChange={(e) => setFilQry(e.target.value)}
                  autoFocus
                />
                <button
                  type="submit"
                  className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>

          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerTable;
