import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import PinInput from "react-pin-input";
import verify_img from "../../images/code_verify.png";
import ShowAlert from "../../components/Alerts/all_alerts";
import {
  sendCodeFunction,
  verifyCodeFunction2,
} from "../../functions/Authentication";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ShowToast from "../../components/Alerts/all_toasts";
import { message } from "antd";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";
import { BASE_URL } from "../../helpers/contants";
import axios from "axios";

const VerifyForPwdReset = () => {

  const { id } = useParams()
  const [email, setEmail] = useState("");
  const [pinValue, setPinValue] = useState("");

  //Check if code is correct
  const runVerification = (the_code: any) => {
    //console.log(the_code);

    ShowAlert.loading_alert("Verifying your account...");

    let data = JSON.stringify({
      "token": the_code
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: BASE_URL + global_variables.verify_code,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': id
      },
      data: data
    };

    axios.request(config)
      .then((response: any) => {
        // console.log(response);
        window.location.href = "/setNewPass";
      })
      .catch((error: any) => {
        console.log(error);
        ShowAlert.error_alert("Error", error?.response?.data?.error || "An error occured. Please retry", "Retry")
          .then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/"
            }
          })
      });
  }



  //resend code
  const doResend = () => {
    if (email === "") {
      message.warning("Please enter your email!");
    } else {
      ShowAlert.loading_alert("Please wait...!");
      sendCodeFunction({ email: email });
    }
  };

  return (
    <>
      <div className="h-screen w-full overflow-y-auto">
        <div className="flex justify-center items-center h-full overflow-y-auto">
          <div className="lg:grid grid-cols-2 w-full">
            <div className="w-full">
              <img className="img-fluid" src={verify_img} alt="..." />
            </div>

            <div className="w-full flex flex-col items-center justify-center">
              <div className="header">
                <h3 id="header-text">Check your mailbox</h3>
              </div>
              <p style={{ color: "#656565" }}>
                We’ve sent a 5-digit verification code to your email, check your
                inbox <br />
                and enter the code below to verify your account.
              </p>

              <div style={{ marginTop: "0.5rem" }}>
                <PinInput
                  length={5}
                  onChange={(value) => {
                    setPinValue(value);
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{ borderColor: "#8877cc" }}
                  inputFocusStyle={{ borderColor: "#523dc4" }}
                  onComplete={(value) => {
                    runVerification(value);
                  }}
                  autoSelect={true}
                />
              </div>

              {/* <Link className="btn btn-primary" to="#" type="button" style={{ textDecoration: "none", backgroundColor: "#ff6700", borderColor: "#ff6700" }}>
                            Verify
                        </Link> */}

              <br />
              <br />

              <div>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#enterCode"
                  style={{ textDecoration: "none", color: "#52b3ef" }}
                >
                  Didn't get the email?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Resend Modal */}
      <div
        className="modal fade modal-md"
        id="enterCode"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Resend Verification</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="modal-body">
              If you don't see an email from us within 5 minutes, one of these
              things could have happened: <br /> <br />
              1. The email might be in your spam folder. (If you use Gmail,
              please check your Promotions folder as well.) <br /> <br />
              2. The email address you entered had a typo. <br /> <br />
              3. You accidentally entered another email address. (Usually
              happens with auto-complete.) <br /> <br />
              4. We can't deliver the email to this address. (Usually because of
              corporate firewalls or filtering.) <br /> <br />
              <p>Enter email again and click the resend button.</p>
              <div className="form-group mt-3">
                <input
                  className="form-control shadow-none border-outline_light rounded-md"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="admin_email"
                  placeholder="Enter email"
                  required
                  autoFocus
                />
              </div>
              <div className="from-group text-center pt-3">
                <button
                  type="submit"
                  className="bg-default_orange text-default_white px-3 py-1 rounded-md"
                  onClick={() => doResend()}
                >
                  Resend Code To My Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyForPwdReset;
