import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'

interface selectProps {
    onChange?: (value: any) => void
}


const AdminRolesSelector = ({ onChange }: selectProps) => {

    const [isBusy, setIsBusy] = useState(true)
    const [allRoles, setAllRoles] = useState()


    //onload
    useEffect(() => {
        const getAllRoles = async () => {
            setIsBusy(true)

            try {
                const response = await BaseService.get_api(`${global_variables.get_all_professions}`)
                // console.log(response?.data)
                const mapped = response?.data?.payload.map((item: any) => (
                    { label: item?.name, value: item?.name }
                ))
                setAllRoles(mapped)
                setIsBusy(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllRoles()
    }, [])



    return (
        <>
            <Select
                className='h-[40px] w-full'
                placeholder='Select role'
                options={allRoles}
                onChange={onChange}
                loading={isBusy}
                disabled={isBusy}
                mode="multiple"
            />
        </>
    )
}

export default AdminRolesSelector
