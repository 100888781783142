import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CreateRoleModal from "../../components/Modals/CreateRoleModal";
import EditProfessionModal from "../../components/Modals/EditProfessionModal";
import EditRoleModal from "../../components/Modals/EditRoleModal";
import AdministratorsTable from "../../components/Tables/AdministratorsTable";
import ProfessionsTable from "../../components/Tables/ProfessionsTable";
import PricingTab from "../../components/Tabs/PricingTab";
import RegionTab from "../../components/Tabs/RegionTab";
import WalletTab from "../../components/Tabs/WalletTab";
import CustomerTab from "../../components/Tabs/CustomerTab";
import UpdatesTab from "../../components/Tabs/UpdatesTab";
import CancelTab from "../../components/Tabs/CancelTab";
import PageLayout from "../../components/navLayouts/pageLayout";

const Administration = () => {
  const [tabActive, setTabActive] = useState(0);

  //Tab names
  const tabNames = [
    {
      name: "Administrators",
    },
    {
      name: "Professions",
    },
    {
      name: "Pricing",
    },
    {
      name: "Regions",
    },
    {
      name: "Wallet Limit",
    },
    {
      name: "Updates",
    },
    {
      name: "Customer Service",
    },
    {
      name: "Cancel Reasons",
    },
  ];
  const showActive = (i) => {
    if (i === tabActive) {
      return "rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl bg-default_orange text-default_white";
    } else {
      return "bg-text_white rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl hover:bg-default_orange hover:text-default_white";
    }
  };

  return (
    <>
      <PageLayout adminActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Administration</p>

          <hr className="my-3" />

          <Tabs className="mt-3">
            <TabList className="bottom-0 mb-3 flex gap-2 text-xs md:text-md lg:text-lg xl:text-lg">
              {tabNames.map((item, index) => (
                <Tab key={index} className="border-0 bg-transparent text-sm">
                  <button
                    key={index}
                    onClick={setTabActive.bind(this, index)}
                    className={showActive(index)}
                  >
                    {item.name}
                  </button>
                </Tab>
              ))}
            </TabList>

            {/* Admins Tab */}
            <TabPanel>
              {/* Table */}
              <AdministratorsTable />
            </TabPanel>

            {/* Professions */}
            <TabPanel>
              {/* Table */}
              <ProfessionsTable />
            </TabPanel>

            {/*Pricing Tab */}
            <TabPanel>
              <PricingTab />
            </TabPanel>

            {/*Regions Tab */}
            <TabPanel>
              <RegionTab />
            </TabPanel>

            {/*Wallet Limit Tab */}
            <TabPanel>
              <WalletTab />
            </TabPanel>

            {/* Updates */}
            <TabPanel>
              <UpdatesTab />
            </TabPanel>

            {/* Customer Service*/}
            <TabPanel>
              <CustomerTab />
            </TabPanel>

            {/* Cancel Reason*/}
            <TabPanel>
              <CancelTab />
            </TabPanel>
          </Tabs>
        </div>
      </PageLayout>

      {/* Edit Profession Modal */}
      <EditProfessionModal />

      {/* Create Role Modal */}
      <CreateRoleModal />

      {/* Edit Role Modal */}
      <EditRoleModal />
    </>
  );
};

export default Administration;
