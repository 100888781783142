import React from 'react';

const CloseModal = (props) => {
    return (
        <>
            <button type="button" className="text-default_red mr-4" data-bs-dismiss="modal">{props.btn_name}</button>
        </>
    )
}

export default CloseModal