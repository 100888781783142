import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShowToast from '../Alerts/all_toasts';
import ShowAlert from '../Alerts/all_alerts';
import WalletTable from '../Tables/WalletTable';
import CustomerTable from '../Tables/CustomerTable';
import { CreateCustomerService } from '../../functions/Admin';
const isoCountryCurrency = require('iso-country-currency');



const CustomerTab = () => {

    const [countryList, setCountryList] = useState([]);
    const [d_country, setDCountry] = useState("");
    const [d_phone, setDPhone] = useState("");
    const [d_app, setDApp] = useState("");
    const [d_name, setDName] = useState("");
    const [fb, setFB] = useState("");
    const [whats_app, setWhatsApp] = useState("");
    const [insta, setInsta] = useState("");
    const [tweet, setTweet] = useState("");



    // get countries on load
    useEffect(() => {
        var data = isoCountryCurrency.getAllISOCodes();
        //console.log(data)
        setCountryList(data);
    }, []);



    //Post customer service
    const doPostCustomer = () => {
        if (d_country === "" || d_phone === "" || d_app === "" || fb === "" || whats_app === "" || d_name === "" || insta === "" || tweet === "") {
            ShowToast.warning_toast('Please fill out all fields!');
        }
        else {
            ShowAlert.loading_alert('Creating customer service...');

            var data = {
                "phone": d_phone,
                "app": d_app,
                "facebook": fb,
                "country": d_country,
                "whatsapp": whats_app,
                "name": d_name,
                "instagram": insta,
                "twitter": tweet
            };

            CreateCustomerService(data);

        }
    }







    return (
        <>
            <Container>

                <div className="container mt-4">
                    <div className='flex justify-between'>
                        <div> <i className='fa fa-sync btn' onClick={() => { window.location.reload() }}></i> </div>

                        <div>
                            <Link className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" to="#" data-bs-toggle="modal" data-bs-target="#customerModal">+ Add New</Link>
                        </div>
                    </div>
                </div>


                <div>

                    <CustomerTable />

                </div>


            </Container>







            {/* Post Customer Service */}
            <div className="modal fade modal-md" id="customerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 font-semibold" id="staticBackdropLabel">Post Customer Service</h1>
                        </div>

                        <div className="modal-body">
                            <Container>
                                <div className='form-group mt-2'>
                                    <select className="form-select form-control" value={d_country} onChange={(e) => setDCountry(e.target.value)} aria-label="Default select example" id="dropdown">
                                        <option defaultValue='Select...'>Select Country</option>
                                        {
                                            countryList.map((the_d, index) => {
                                                return (
                                                    <option key={index} value={the_d.countryName}>{the_d.countryName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={d_name} onChange={(e) => setDName(e.target.value)} type='text' placeholder='Full Name' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={d_phone} onChange={(e) => setDPhone(e.target.value)} type='text' placeholder='Phone' />
                                </div>
                                <div className='form-group mt-2'>
                                    <select className="form-select form-control" value={d_app} onChange={(e) => setDApp(e.target.value)} aria-label="Default select example" id="dropdown2">
                                        <option defaultValue='Select...'>Select App</option>
                                        <option value='com.rydsom.user'>Rydsom User App</option>
                                        <option value='com.rydsom.driver'>Rydsom Driver App</option>
                                    </select>
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={fb} onChange={(e) => setFB(e.target.value)} type='text' placeholder='Facebook Link' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={whats_app} onChange={(e) => setWhatsApp(e.target.value)} type='text' placeholder='WhatsApp Link' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={insta} onChange={(e) => setInsta(e.target.value)} type='text' placeholder='Instagram Link' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={tweet} onChange={(e) => setTweet(e.target.value)} type='text' placeholder='Twitter Link' />
                                </div>
                            </Container>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="text-default_red mr-4" data-bs-toggle="modal" data-bs-target="#customerModal">Cancel</button>

                            <button type="button" className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" onClick={() => doPostCustomer()}>Post Customer Service</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CustomerTab