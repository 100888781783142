import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import BaseService from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import { PiUsersFourLight } from "react-icons/pi";


const TotalDrivers = () => {


    const [loading3, setLoading3] = useState(true);
    const [totalDrivers, setTotalDrivers] = useState(0);


    //Onload
    useEffect(() => {
        BaseService.get_api(`${global_variables.get_all_drivers}?limit=1&filter={"approved": true}`)
            .then((response) => {
                console.log(response?.data?.total);
                setTotalDrivers(response?.data?.total);
                setLoading3(false);
            })
            .catch((error) => {
                // console.log(error);
            })
    }, []);



    return (
        <div className='w-full'>
            <Card className='rounded-lg shadow-sm border-y-0 border-default_orange border-b-0 border-x-2'>
                <Card.Body>
                    {
                        loading3 ?
                            <>
                                <Row className='border-gray-200 divide-gray-200 animate-pulse'>
                                    <Col md={4}>
                                        <div className="w-16 h-16 mb-1 mt-1 bg-gray-200 rounded-full py-6 flex mr-auto ml-auto"></div>
                                    </Col>
                                    <Col className='text-center' md={8}>
                                        <div className="h-2.5 bg-gray-300 rounded-full text-lg mt-3"></div>
                                        <div className="w-28 h-2 bg-gray-200 rounded-full mt-3"></div>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <div className="flex gap-[20px] items-center justify-between">
                                    <div className='flex mx-auto'>
                                        <PiUsersFourLight className="h-[40px] w-[40px] text-default_orange" />
                                    </div>

                                    <div className="text-center">
                                        <p className="text-default_blue font-semibold text-xl">
                                            Total Drivers
                                        </p>
                                        <p className="text-default_orange text-2xl mt-2">
                                            {totalDrivers}
                                        </p>
                                    </div>
                                </div>
                            </>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}

export default TotalDrivers