import React, { useEffect, useState } from "react";
import { ListBulletIcon } from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import { Card, Container, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import TableShimmer from "../Shimmers/TableShimmer";
import ModalXBtn from "../Buttons/ModalXBtn.js";
import CloseModal from "../Buttons/CloseModal.js";
import Select from "react-select";
import ShowToast from "../Alerts/all_toasts";
import { Empty, Spin } from "antd";

const TransactionsTable = () => {
  const [laoding, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const [filQry, setFilQry] = useState("");

  const [uniqueLoaded, setUniqueLoaded] = useState(false);
  const [specificData, setSpecificData] = useState([]);
  const [reFetch, setReFetch] = useState(false);

  //Onload
  useEffect(() => {
    getDrivers();
    getTransactions(limit, skip);
  }, [reFetch]);

  //Fetch drivers
  const getDrivers = () => {
    setUserLoading(true);
    const the_route =
      global_variables().get_all_drivers + '?filter={"approved": true}';
    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setDrivers(response.data.payload);
        setUserLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setUserLoading(true);
      });
  };

  const getTransactions = (lim, sk) => {
    setLoading(true);
    // console.log('triggered')
    const the_route =
      global_variables().get_all_transactions +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTransactions(response.data.payload);
        setLoading(false);
        setTotalRow(response.data.total);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "",
      selector: (row) => (
        <img
          className="img-fluid rounded-full py-1"
          src={row.driver_data["image"]}
          width="45px"
        />
      ),
      width: "10%",
    },
    {
      name: "Driver",
      selector: (row) => row.driver_data["fullName"],
    },
    {
      name: "Amount (Gh¢)",
      selector: (row) => row.amount.toFixed(2),
    },
    {
      name: "Pickup",
      selector: (row) => row.trip_data["pickUpPlace"],
    },
    {
      name: "Destination",
      selector: (row) => row.trip_data["destinationPlace"],
    },
    {
      name: "Trip Date & Time",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY; hh:mm a"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#tripDetailsModal"
              onClick={showDetails.bind(this, row)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const handlePageChange = (i) => {
    // console.log(i)
    if (i === 1) {
      getTransactions(limit, 0);
      setSkip(0);
    } else {
      const newSkip = i * limit - limit;
      getTransactions(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };

  const handleNumRowChange = (i) => {
    getTransactions(i, 0);
    setLimit(i);
    setSkip(0);
  };

  const driverlist = () => {
    drivers.map((dd) => {
      return { value: dd.userid, label: dd.user_data["fullName"] };
    });
  };

  const applySearch = () => {
    if (filQry === "") {
      ShowToast.warning_toast(`Please input a driver's name`);
    } else {
      setLoading(true);

      const the_route =
        global_variables().get_all_transactions +
        `?filter={"driverId": ${JSON.stringify(filQry)}}`;

      BaseService.get_api(the_route)
        .then((response) => {
          // console.log(response.data.payload);
          setTransactions(response.data.payload);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  //View Details
  const showDetails = (i) => {
    setSpecificData(i);
    setUniqueLoaded(true);
    // console.log(i);
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>
          <form className="col-md-6">
            <div className="input-group">
              <Container className="flex">
                <select
                  className="form-select rounded-tr-none rounded-br-none"
                  aria-label="Default select example"
                  style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                >
                  <option defaultValue="0" disabled>
                    Filter by ...{" "}
                  </option>
                  <option value="driver">Driver Name</option>
                </select>

                <Select
                  className="basic-single w-96"
                  isClearable={true}
                  isSearchable={true}
                  options={drivers.map((dd) => {
                    return dd.user_data === null
                      ? { value: dd.userid, label: "" }
                      : { value: dd.userid, label: dd.user_data["fullName"] };
                  })}
                  onChange={(e) => setFilQry(e.value)}
                />

                <button
                  onClick={() => applySearch()}
                  type="button"
                  className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange rounded-tl-none rounded-bl-none"
                >
                  <i className="fas fa-search"></i>
                </button>
              </Container>
            </div>
          </form>
        </div>
      </div>

      <Container>
        <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
          <Card.Body>
            <DataTable
              progressPending={laoding}
              columns={columns}
              data={transactions}
              paginationTotalRows={totalRow}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleNumRowChange}
              noDataComponent={<Empty description="No data to display" />}
              progressComponent={
                <div className="flex justify-center items-center h-[150px]">
                  <Spin size="large" />
                </div>
              }
            />
          </Card.Body>
        </Card>

        <br />
      </Container>

      {/* Transaction Details Modal */}
      <div
        className="modal fade modal-xl"
        id="tripDetailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Transaction Details
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                {!uniqueLoaded ? (
                  <TableShimmer />
                ) : (
                  <>
                    <div>
                      <div className="flex gap-4">
                        <p className="font-semibold">Amount Charged:</p>
                        <p>GH¢ {specificData["amount"].toFixed(2)}</p>
                      </div>
                    </div>

                    <Table>
                      <thead>
                        <tr>
                          <th>Trip Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="font-semibold">Pick Up City:</span>{" "}
                            {specificData.trip_data["pickUpCity"]}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Pick Up Place:
                            </span>{" "}
                            {specificData.trip_data["pickUpPlace"]}
                          </td>
                          <td>
                            <span className="font-semibold">Request Time:</span>{" "}
                            {moment(specificData.createdAt).format(
                              "Do MMM YYYY; hh:mm a"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">
                              Destination City:
                            </span>{" "}
                            {specificData.trip_data["destinationCity"]}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Destination Place:
                            </span>{" "}
                            {specificData.trip_data["destinationPlace"]}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Trip Distance:
                            </span>{" "}
                            {specificData.trip_data["distance"] +
                              " " +
                              specificData.trip_data["distanceUnit"]}
                          </td>
                        </tr>
                      </tbody>
                      <br />

                      <thead>
                        <tr>
                          <th>Driver Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              className="img-fluid rounded-full"
                              src={specificData.driver_data["image"]}
                              alt="..."
                              width="100px"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">First Name:</span>{" "}
                            {specificData.driver_data["firstName"]}
                          </td>
                          <td>
                            <span className="font-semibold">Last Name:</span>{" "}
                            {specificData.driver_data["lastName"]}
                          </td>
                          <td>
                            <span className="font-semibold">Phone Number:</span>{" "}
                            {specificData.driver_data["phone"]}
                          </td>
                        </tr>
                      </tbody>
                      <br />

                      <thead>
                        <tr>
                          <th>Rider Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              className="img-fluid rounded-full"
                              src={specificData.user_data["image"]}
                              alt="..."
                              width="100px"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">First Name:</span>{" "}
                            {specificData.user_data["firstName"]}
                          </td>
                          <td>
                            <span className="font-semibold">Last Name:</span>{" "}
                            {specificData.user_data["lastName"]}
                          </td>
                          <td>
                            <span className="font-semibold">Phone Number:</span>{" "}
                            {specificData.user_data["phone"]}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                )}
              </Container>
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsTable;
