import React, { useState, useEffect } from "react";
import {
  CalculatorIcon,
  GlobeEuropeAfricaIcon,
} from "@heroicons/react/24/outline";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalXBtn from "../Buttons/ModalXBtn";
import PricingTable from "../Tables/PricingTable";
import ShowToast from "../Alerts/all_toasts";
import ShowAlert from "../Alerts/all_alerts";
import { CreatePrice, CreateRegionalPrice } from "../../functions/AllPrices";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Select from "react-select";
import RegionalPricingTable from "../Tables/RegionalPricingTable";
import global_variables from "../../mixins/globarVars";
import axios from "axios";
import { StorageBox } from "../../helpers/stroage";

const api = process.env.API_BASE_URL;
const isoCountryCurrency = require("iso-country-currency");

const PricingTab = () => {
  const [countryList, setCountryList] = useState([]);
  const [baseP, setBaseP] = useState("");
  const [costPerD, setCostPerD] = useState("");
  const [costPerM, setCostPerM] = useState("");
  const [vType, setVType] = useState("");
  const [d_country, setDCountry] = useState("");
  const [d_region, setDRegion] = useState("");
  const [d_currency, setDCurrency] = useState("");
  const [opFee, setOpFee] = useState("");
  const [d_comm, setDcomm] = useState("");
  const [d_CommType, setDCommType] = useState("");
  const [percentOn, setPercentOn] = useState("");
  const [percentOff, setPercentOff] = useState("");
  const [forceType, setForceType] = useState("price");
  const [dClass, setDClass] = useState([]);
  const [dSurge, setDSurge] = useState([]);

  const [cType, setCType] = useState("");
  const [cValue, setCValue] = useState("");
  const [cAdded, setCAdded] = useState(false);
  const [cPromo, setCPromo] = useState();
  const [sVal, setSVal] = useState("");
  const [sPercent, setSPercent] = useState("");
  const [sAdded, setSAdded] = useState(false);

  const [deRegions, setDeRegions] = useState([]);

  // get countries on load
  useEffect(() => {
    var data = isoCountryCurrency.getAllISOCodes();
    //console.log(data)
    setCountryList(data);
  }, []);

  // filter currency
  const getCurrency = async (e) => {
    setDCountry(e.target.value);
    var filtered = countryList.filter(function (theData) {
      return theData.countryName === e.target.value;
    });
    // console.log(filtered[0]);
    setDCurrency(filtered[0]["currency"]);

    var config = {
      method: "get",
      url:
        api +
        global_variables().get_all_regions +
        `?filter={"country":${JSON.stringify(filtered[0]["countryName"])}}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data.payload);
        setDeRegions(response.data.payload);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Add class funtion
  const addToClass = () => {
    var dropdown4 = document.getElementById("dropdown4");
    if (cType === "" || cValue === "") {
      ShowToast.warning_toast("Fill out all fields!");
    } else if (dropdown4.value === "Promotion Status") {
      ShowToast.warning_toast("Choose a promo status!");
    } else {
      var tempArr = dClass;
      const data = {
        type: cType,
        promo: cPromo === "true" ? true : false,
        amount: parseInt(cValue),
      };
      // console.log(data)
      tempArr.push(data);
      setDClass(tempArr);
      setCAdded(true);
      setCPromo("");
      setCValue("");
      setCType("");
    }
  };

  //Add Surge function
  const addToSurge = () => {
    if (sVal === "" || sPercent === "") {
      ShowToast.warning_toast("Fill out all fields!");
    } else {
      var tempArr = dSurge;
      const data = {
        value: parseInt(sVal),
        percent: parseInt(sPercent),
      };
      // console.log(data)
      tempArr.push(data);
      setDSurge(tempArr);
      setSAdded(true);
      setSVal("");
      setSPercent("");
    }
  };

  //Post Price Function
  const postPrice = () => {
    var data = JSON.stringify({
      basePrice: parseInt(baseP),
      costPerDistance: parseFloat(costPerD),
      costPerMinute: parseFloat(costPerM),
      type: vType,
      country: d_country,
      currency: d_currency,
      class: dClass,
      surge: dSurge,
      operationFee: parseInt(opFee),
      commission: parseInt(d_comm),
      commissionFeeType: d_CommType,
    });

    if (
      baseP === "" ||
      costPerD === "" ||
      costPerM === "" ||
      vType === "" ||
      d_country === "" ||
      d_currency === "" ||
      opFee === "" ||
      d_comm === "" ||
      d_CommType === ""
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else if (dClass.length === 0 || dSurge.length === 0) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      // console.log(JSON.parse(data));
      ShowAlert.question_alert("Confirm", "Post new price", "Proceed").then(
        (result) => {
          if (result.isConfirmed) {
            ShowAlert.loading_alert("Creating new price...");
            //Import Post function
            CreatePrice(data);
          }
        }
      );
    }
  };

  //Post regional Price
  const postRegionalPrice = () => {
    var dataNoPercent = JSON.stringify({
      region: d_region,
      country: d_country,
      price_metrics: {
        basePrice: parseInt(baseP),
        costPerDistance: parseFloat(costPerD),
        costPerMinute: parseFloat(costPerM),
        type: vType,
        currency: d_currency,
        country: d_country,
        class: dClass,
        surge: dSurge,
        operationFee: parseInt(opFee),
        commission: parseInt(d_comm),
        commissionFeeType: d_CommType,
      },
      force_type: forceType,
    });

    var dataWithPercent = JSON.stringify({
      region: d_region,
      country: d_country,
      percentage_metrics: {
        percentageOff: parseInt(percentOff),
        percentageOn: parseInt(percentOn),
        type: "percentage",
      },
      force_type: forceType,
    });

    if (
      (forceType === "price" && d_region === "") ||
      (forceType === "price" && baseP === "") ||
      (forceType === "price" && costPerD === "") ||
      (forceType === "price" && costPerM === "") ||
      (forceType === "price" && vType === "") ||
      d_country === "" ||
      d_currency === "" ||
      (forceType === "price" && opFee === "") ||
      (forceType === "price" && d_comm === "") ||
      (forceType === "price" && d_CommType === "")
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else if (
      (forceType === "price" && dClass.length === 0) ||
      (forceType === "price" && dSurge.length === 0)
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else if (
      (forceType === "percentage" && percentOn === "") ||
      (forceType === "percentage" && d_region === "") ||
      (forceType === "percentage" && percentOff === "")
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      ShowAlert.question_alert("Confirm", "Post new price", "Proceed").then(
        (result) => {
          if (result.isConfirmed) {
            ShowAlert.loading_alert("Creating new price...");
            if (forceType === "percentage") {
              //Import post regional functional
              // console.log(dataWithPercent);
              CreateRegionalPrice(dataWithPercent);
            } else {
              //Import post regional functional
              // console.log(dataNoPercent);
              CreateRegionalPrice(dataNoPercent);
            }
          }
        }
      );
    }
  };

  return (
    <>
      <Container>
        <div className="container mt-4">
          <div className="flex justify-between">
            <div>
              {" "}
              <i
                className="fa fa-sync btn"
                onClick={() => {
                  window.location.reload();
                }}
              ></i>{" "}
            </div>

            <div>
              <Link
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#priceTypeModal"
              >
                + Add New
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <Tabs>
            <TabList>
              <Tab>General Price</Tab>
              <Tab>Regional Price</Tab>
            </TabList>

            <TabPanel>
              <PricingTable />
            </TabPanel>

            <TabPanel>
              <RegionalPricingTable />
            </TabPanel>
          </Tabs>
        </div>
      </Container>

      {/* Choose price type modal */}
      <div
        className="modal fade modal-md"
        id="priceTypeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Choose Price Type
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#genPriceModal"
                >
                  <Card className="border-0 shadow-sm text-center">
                    <Card.Body>
                      <CalculatorIcon className="w-8 h-8 mr-auto ml-auto" />
                      <p className="mt-3">General Pricing</p>
                    </Card.Body>
                  </Card>
                </Link>
                <br />
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#regPriceModal"
                >
                  <Card className="border-0 shadow-sm text-center">
                    <Card.Body>
                      <GlobeEuropeAfricaIcon className="w-8 h-8 mr-auto ml-auto" />
                      <p className="mt-3">Regional Pricing</p>
                    </Card.Body>
                  </Card>
                </Link>
              </Container>
            </div>
          </div>
        </div>
      </div>

      {/* General Price modal */}
      <div
        className="modal fade modal-xl"
        id="genPriceModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Post General Pricing
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group mt-2">
                      <select
                        className="form-select form-control"
                        value={d_country}
                        onChange={getCurrency}
                        aria-label="Default select example"
                        id="dropdown"
                      >
                        <option defaultValue="Select...">Select Country</option>
                        {countryList.map((the_d, index) => {
                          return (
                            <option key={index} value={the_d.countryName}>
                              {the_d.countryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control shadow-none rounded-md"
                        type="text"
                        value={d_currency}
                        onChange={(e) => setDCurrency(e.target.value)}
                        placeholder="Currency"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control shadow-none rounded-md"
                        value={baseP}
                        onChange={(e) => setBaseP(e.target.value)}
                        type="text"
                        placeholder="Base Price"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control shadow-none rounded-md"
                        value={costPerD}
                        onChange={(e) => setCostPerD(e.target.value)}
                        type="text"
                        placeholder="Cost Per Distance"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control shadow-none rounded-md"
                        value={costPerM}
                        onChange={(e) => setCostPerM(e.target.value)}
                        type="text"
                        placeholder="Cost Per Minute"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <select
                        className="form-select form-control"
                        value={vType}
                        onChange={(e) => setVType(e.target.value)}
                        aria-label="Default select example"
                        id="dropdown2"
                      >
                        <option defaultValue="Select...">Select Vehicle</option>
                        <option value="car">Car</option>
                        <option value="motorcycle">Motorcycle</option>
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control shadow-none rounded-md"
                        value={d_comm}
                        onChange={(e) => setDcomm(e.target.value)}
                        type="text"
                        placeholder="Commission"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <select
                        className="form-select form-control"
                        value={d_CommType}
                        onChange={(e) => setDCommType(e.target.value)}
                        aria-label="Default select example"
                        id="dropdown3"
                      >
                        <option defaultValue="Select...">
                          Commission Type
                        </option>
                        <option value="price">Price</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control shadow-none rounded-md"
                        value={opFee}
                        onChange={(e) => setOpFee(e.target.value)}
                        type="text"
                        placeholder="Operation Fee"
                      />
                    </div>
                    <hr className="mt-3" />
                    <p className="mt-2">Add Rydsom Class</p>
                    <Row>
                      <Col md={6}>
                        <div className="form-group mt-2">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={cType}
                            onChange={(e) => setCType(e.target.value)}
                            type="text"
                            placeholder="Type"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <select
                            className="form-select form-control"
                            value={cPromo}
                            onChange={(e) => setCPromo(e.target.value)}
                            aria-label="Default select example"
                            id="dropdown4"
                          >
                            <option defaultValue="Select...">
                              Promotion Status
                            </option>
                            <option value={true}>Allowable</option>
                            <option value={false}>Forbidden</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group mt-2">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={cValue}
                            onChange={(e) => setCValue(e.target.value)}
                            type="text"
                            placeholder="Amount"
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            type="button"
                            onClick={() => addToClass()}
                            className="text-default_white text-xs bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                          >
                            Add Class
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <hr className="mt-3" />
                    <p className="mt-2">Add Rydsom Surge</p>
                    <Row>
                      <Col md={6}>
                        <div className="form-group mt-2">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={sVal}
                            onChange={(e) => setSVal(e.target.value)}
                            type="text"
                            placeholder="No. of requests"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group mt-2">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={sPercent}
                            onChange={(e) => setSPercent(e.target.value)}
                            type="text"
                            placeholder="Percentage Charge"
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            type="button"
                            onClick={() => addToSurge()}
                            className="text-default_white text-xs bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                          >
                            Add Surge
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <p className="mt-2">Rydsom Class List</p>
                    <hr className="mt-2" />
                    <Row>
                      {!cAdded ? (
                        <small>No class & price added</small>
                      ) : (
                        dClass.map((the_d, index) => {
                          return (
                            <Col key={index} md={6}>
                              <div className="flex gap-3 mt-2">
                                <p>{index + 1}.</p>
                                <div>
                                  <p>Type: {the_d.type}</p>
                                  <p>Amount: {the_d.amount}</p>
                                  <p>
                                    Promo:{" "}
                                    {the_d.promo ? "Allowable" : "Forbidden"}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </Row>

                    <p className="mt-5">Rydsom Surge List</p>
                    <hr className="mt-2" />
                    <Row>
                      {!sAdded ? (
                        <small>No surge price added</small>
                      ) : (
                        dSurge.map((the_d, index) => {
                          return (
                            <Col key={index} md={6}>
                              <div className="flex gap-3 mt-2">
                                <p>{index + 1}.</p>
                                <div>
                                  <p>No. of requests: {the_d.value}</p>
                                  <p>Percentage Charge: {the_d.percent}</p>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#priceTypeModal"
              >
                Cancel
              </button>

              <button
                type="button"
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                onClick={() => postPrice()}
              >
                Post Price
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Regional Price modal */}
      <div
        className="modal fade modal-xl"
        id="regPriceModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Post Regional Pricing
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="form-group mt-3">
                      <label htmlFor="dropdown5">Select Force Type</label>
                      <select
                        className="form-select form-control"
                        value={forceType}
                        onChange={(e) => setForceType(e.target.value)}
                        aria-label="Default select example"
                        id="dropdown5"
                      >
                        <option value="price">Price</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                    <div className="form-group mt-2">
                      <select
                        className="form-select form-control"
                        value={d_country}
                        onChange={getCurrency}
                        aria-label="Default select example"
                        id="dropdown"
                      >
                        <option defaultValue="Select...">Select Country</option>
                        {countryList.map((the_d, index) => {
                          return (
                            <option key={index} value={the_d.countryName}>
                              {the_d.countryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mt-3">
                      <label htmlFor="chooseRegion">Select Region</label>
                      <Select
                        className="basic-single mt-3"
                        id="chooseRegion"
                        isSearchable={true}
                        options={deRegions.map((dd) => {
                          return { value: dd.region, label: dd.region };
                        })}
                        onChange={(e) => setDRegion(e.value)}
                      />
                    </div>
                    {forceType === "price" ? (
                      <>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            type="text"
                            value={d_currency}
                            onChange={(e) => setDCurrency(e.target.value)}
                            placeholder="Currency"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={baseP}
                            onChange={(e) => setBaseP(e.target.value)}
                            type="text"
                            placeholder="Base Price"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={costPerD}
                            onChange={(e) => setCostPerD(e.target.value)}
                            type="text"
                            placeholder="Cost Per Distance"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={costPerM}
                            onChange={(e) => setCostPerM(e.target.value)}
                            type="text"
                            placeholder="Cost Per Minute"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <select
                            className="form-select form-control"
                            value={vType}
                            onChange={(e) => setVType(e.target.value)}
                            aria-label="Default select example"
                            id="dropdown2"
                          >
                            <option defaultValue="Select...">
                              Select Vehicle
                            </option>
                            <option value="car">Car</option>
                            <option value="motorcycle">Motorcycle</option>
                          </select>
                        </div>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={d_comm}
                            onChange={(e) => setDcomm(e.target.value)}
                            type="text"
                            placeholder="Commission"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <select
                            className="form-select form-control"
                            value={d_CommType}
                            onChange={(e) => setDCommType(e.target.value)}
                            aria-label="Default select example"
                            id="dropdown3"
                          >
                            <option defaultValue="Select...">
                              Commission Type
                            </option>
                            <option value="price">Price</option>
                            <option value="percentage">Percentage</option>
                          </select>
                        </div>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={opFee}
                            onChange={(e) => setOpFee(e.target.value)}
                            type="text"
                            placeholder="Operation Fee"
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {forceType === "price" ? (
                      <></>
                    ) : (
                      <>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={percentOn}
                            onChange={(e) => setPercentOn(e.target.value)}
                            type="text"
                            placeholder="Percentage On"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <input
                            className="form-control shadow-none rounded-md"
                            value={percentOff}
                            onChange={(e) => setPercentOff(e.target.value)}
                            type="text"
                            placeholder="Percentage Off"
                          />
                        </div>
                      </>
                    )}
                    <hr className="mt-3" />
                    {forceType === "percentage" ? (
                      <></>
                    ) : (
                      <>
                        <p className="mt-2">Add Rydsom Class</p>
                        <Row>
                          <Col md={6}>
                            <div className="form-group mt-2">
                              <input
                                className="form-control shadow-none rounded-md"
                                value={cType}
                                onChange={(e) => setCType(e.target.value)}
                                type="text"
                                placeholder="Type"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <select
                                className="form-select form-control"
                                value={cPromo}
                                onChange={(e) => setCPromo(e.target.value)}
                                aria-label="Default select example"
                                id="dropdown4"
                              >
                                <option defaultValue="Select...">
                                  Promotion Status
                                </option>
                                <option value={true}>Allowable</option>
                                <option value={false}>Forbidden</option>
                              </select>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group mt-2">
                              <input
                                className="form-control shadow-none rounded-md"
                                value={cValue}
                                onChange={(e) => setCValue(e.target.value)}
                                type="text"
                                placeholder="Amount"
                              />
                            </div>
                            <div className="mt-3">
                              <button
                                type="button"
                                onClick={() => addToClass()}
                                className="text-default_white text-xs bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                              >
                                Add Class
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <hr className="mt-3" />
                        <p className="mt-2">Add Rydsom Surge</p>
                        <Row>
                          <Col md={6}>
                            <div className="form-group mt-2">
                              <input
                                className="form-control shadow-none rounded-md"
                                value={sVal}
                                onChange={(e) => setSVal(e.target.value)}
                                type="text"
                                placeholder="No. of requests"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group mt-2">
                              <input
                                className="form-control shadow-none rounded-md"
                                value={sPercent}
                                onChange={(e) => setSPercent(e.target.value)}
                                type="text"
                                placeholder="Percentage Charge"
                              />
                            </div>
                            <div className="mt-3">
                              <button
                                type="button"
                                onClick={() => addToSurge()}
                                className="text-default_white text-xs bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                              >
                                Add Surge
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>

                  <Col md={6}>
                    <p className="mt-2">Rydsom Class List</p>
                    <hr className="mt-2" />
                    <Row>
                      {!cAdded ? (
                        <small>No class & price added</small>
                      ) : (
                        dClass.map((the_d, index) => {
                          return (
                            <Col key={index} md={6}>
                              <div className="flex gap-3 mt-2">
                                <p>{index + 1}.</p>
                                <div>
                                  <p>Type: {the_d.type}</p>
                                  <p>Amount: {the_d.amount}</p>
                                  <p>
                                    Promo:{" "}
                                    {the_d.promo ? "Allowable" : "Forbidden"}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </Row>

                    <p className="mt-5">Rydsom Surge List</p>
                    <hr className="mt-2" />
                    <Row>
                      {!sAdded ? (
                        <small>No surge price added</small>
                      ) : (
                        dSurge.map((the_d, index) => {
                          return (
                            <Col key={index} md={6}>
                              <div className="flex gap-3 mt-2">
                                <p>{index + 1}.</p>
                                <div>
                                  <p>No. of requests: {the_d.value}</p>
                                  <p>Percentage Charge: {the_d.percent}</p>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#priceTypeModal"
              >
                Cancel
              </button>

              <button
                type="button"
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                onClick={() => postRegionalPrice()}
              >
                Post Price
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingTab;
