import React, { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import ShowAlert from "../Alerts/all_alerts";
import { DeleteModel } from "../../functions/CarsAndClasses";
import { Empty, Spin } from "antd";
import { useGeneralContext } from "../../contexts/generalContext";

const CarModelTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const { reFetch, setReFetch } = useGeneralContext();

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().car_model_url +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //Table columns
  const columns = [
    {
      name: "Brand",
      selector: (row) => row?.brandData?.[0]?.brand,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Model",
      selector: (row) => row.model,
    },
    // {
    //   name: "Class",
    //   selector: (row) => row.class,
    // },
    {
      name: "No. of seats",
      selector: (row) => row.seats,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-3">
            {/* <Link to="#" data-bs-toggle="modal" data-bs-target="#editModelModal" title="Edit Model"><PencilIcon className='w-5 h-5' /></Link> */}
            <Link
              className="text-default_red"
              to="#"
              title="Delete Model"
              onClick={doDelete.bind(this, row)}
            >
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const doDelete = (i) => {
    // console.log(i);

    ShowAlert.question_alert(
      "Confirm",
      "Do you wish to delete this info?",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        var data = {
          model: i["model"],
          brand: i["brand"],
          class: i["class"],
          seats: i["seats"],
          type: i["type"],
        };
        ShowAlert.loading_alert("Deleting model...");
        //Delete feature function
        DeleteModel(data);
      }
    });
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filParam, setFilParam] = useState("brand");
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    setLoading(true);
    e.preventDefault();

    const the_route =
      global_variables().car_model_url +
      `?filter={${JSON.stringify(filParam)}:{"$regex":${JSON.stringify(
        filQry
      )},"$options":"i"}}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>

          <form className="col-md-6" onSubmit={handleSearch}>
            <div className="input-group">
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                value={filParam}
                onChange={(e) => setFilParam(e.target.value)}
              >
                <option defaultValue="0" disabled>
                  Filter by ...{" "}
                </option>
                <option value="brand">Brand</option>
                <option value="model">Model</option>
                <option value="class">Class</option>
              </select>

              <input
                className="form-control shadow-none"
                placeholder="Type here..."
                id="truckNum"
                aria-label="Search"
                aria-describedby="search-addon"
                autoComplete="off"
                type="text"
                style={{ border: "1px solid #d9e2ef" }}
                required
                value={filQry}
                onChange={(e) => setFilQry(e.target.value)}
                autoFocus
              />
              <button
                type="submit"
                className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Table */}
      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default CarModelTable;
