import React, { useState } from 'react'
import { useGeneralContext } from '../../contexts/generalContext'
import { Form, Input, Modal, notification, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import PhoneInput from "react-phone-number-input";
import { PhotoIcon } from '@heroicons/react/24/outline';
import ShowToast from '../../components/Alerts/all_toasts';
import { global_variables } from '../../helpers/globalVariables';
import BaseService from '../../helpers/baseServices';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
}

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    user: string;
    image: string;
}


const AddUserModal = ({ isOpened, handleClose }: modalProps) => {

    const { reFetch, setReFetch } = useGeneralContext()
    const [isBusy, setIsBusy] = useState(false)
    const [form] = useForm()
    const [phone, setPhone] = useState("");
    const [imgError, setImgError] = useState(false)
    const [imageFile, setImageFile] = useState<any>(null);
    const [imageObjectURL, setImageObjectURL] = useState<string | null>(null);



    //handleImageFileChange
    const handleImageFileChange = (event: any) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setImageFile(selectedFile);

            const objectURL = URL.createObjectURL(selectedFile);
            setImageObjectURL(objectURL);
        }
    };


    //create new user
    const onFinish = async (values: FormValues) => {
        if (imageFile === null) {
            setImgError(true)
        } else {
            console.log('Received values:', values);

            setIsBusy(true);
            try {
                const imgResponse: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${values?.firstName}&storage=userimage`, imageFile)
                console.log(imgResponse)
                try {
                    const data = JSON.stringify({
                        "fullName": `${values?.firstName} ${values?.lastName}`,
                        "firstName": values?.firstName,
                        "phone": values?.phone,
                        "image": imgResponse?.data?.url,
                        "lastName": values?.lastName,
                        "user": "user"
                    });

                    setIsBusy(true);
                    const response = await BaseService.post_api(
                        `${global_variables.create_general_user}`,
                        data
                    );

                    if (response.status === 200) {
                        ShowToast.success_toast(response?.data?.message);
                        handleClose();
                        setReFetch(!reFetch);
                    }
                } catch (error: any) {
                    console.log(error);
                    notification.error({ message: error?.response?.data?.error, placement: "topLeft" })
                    handleClose();
                    setIsBusy(false);
                }

            } catch (error: any) {
                console.log("ImageError", error);
                notification.error({ message: error?.response?.data?.error, placement: "topLeft" })
                handleClose();
                setIsBusy(false)
            }
        }
    };

    return (
        <>
            <Modal title={<p>Add new user</p>} open={isOpened} onCancel={handleClose} keyboard={false} footer={null} maskClosable={false}>
                <hr />
                <div className="h-[450px] overflow-y-auto px-3">
                    <Spin spinning={isBusy}>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            layout="vertical"
                            className='mt-3'
                        >
                            <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'Please enter first name' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Please enter last name' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Email is required' },
                            { type: 'email', message: 'Please enter a valid email address' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please enter phone number' }]}>
                                <div className="w-full border-[1px] border-gray-500 h-[40px]">
                                    <PhoneInput
                                        className='px-2 border-none h-[40px]'
                                        defaultCountry="MW"
                                        inputStyle={{ width: "100%" }}
                                        placeholder="Enter phone number"
                                        value={phone}
                                        onChange={(phone: any) => setPhone(phone)}
                                        numberInputProps={{
                                            className: "focus:outline-none outline-none border-none h-[35px] bg-transparent"
                                        }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item name="image" label="Image">
                                <div className="flex flex-col justify-center items-center">
                                    <label htmlFor="userImg">
                                        {imageObjectURL ?
                                            <div className="border-[1px] border-default_orange border-dashed h-[140px] w-[140px] rounded-full bg-midGreen bg-opacity-[10%] cursor-pointer">
                                                <img src={imageObjectURL} alt="Selected" className="w-full h-full rounded-full object-cover" />
                                            </div>
                                            :
                                            <div className="border-[1px] border-default_orange border-dashed h-[140px] w-[140px] rounded-full bg-midGreen bg-opacity-[10%] cursor-pointer flex justify-center items-center">
                                                <PhotoIcon className="w-10 h-10 text-default_orange" />
                                            </div>
                                        }
                                    </label>
                                    <input id="userImg" type="file" className="hidden" onChange={handleImageFileChange} />
                                    {imgError ? <small className="mt-2 text-red-500">* Please upload user's image</small> : <></>}
                                </div>
                            </Form.Item>

                            <Form.Item>
                                <button className='w-full h-[50px] bg-gradient-to-r from-default_blue to-default_orange text-white rounded-[40px]'>
                                    <p>Create New User</p>
                                </button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </>
    )
}

export default AddUserModal
