import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RegionsTable from '../Tables/RegionsTable';
import ShowToast from '../Alerts/all_toasts';
import ShowAlert from '../Alerts/all_alerts';
import { PostRegion } from '../../functions/AllPrices';
const isoCountryCurrency = require('iso-country-currency');



const RegionTab = () => {

    const [countryList, setCountryList] = useState([]);
    const [d_country, setDCountry] = useState("");
    const [d_region, setDRegion] = useState("");



    // get countries on load
    useEffect(() => {
        var data = isoCountryCurrency.getAllISOCodes();
        //console.log(data)
        setCountryList(data);
    }, []);



    //Post Region
    const doPostRegion = () => {
        if (d_country === "" || d_region === "") {
            ShowToast.warning_toast('Please fill out all fields!');
        }
        else {
            var data = JSON.stringify({
                "country": d_country,
                "region": d_region
            });

            ShowAlert.question_alert('Confirm', 'Do you wish to post this region?', 'Proceed')
                .then((result) => {
                    if (result.isConfirmed) {
                        ShowAlert.loading_alert('Adding new region...');
                        //Create region function
                        PostRegion(data);
                    }
                })
        }
    }






    return (
        <>
            <Container>

                <div className="container mt-4">
                    <div className='flex justify-between'>
                        <div> <i className='fa fa-sync btn' onClick={() => { window.location.reload() }}></i> </div>

                        <div>
                            <Link className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" to="#" data-bs-toggle="modal" data-bs-target="#addRegionModal">+ Add New</Link>
                        </div>
                    </div>
                </div>


                <div>

                    <RegionsTable />

                </div>


            </Container>







            {/* Post Region modal */}
            <div className="modal fade modal-md" id="addRegionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 font-semibold" id="staticBackdropLabel">Post Region</h1>
                        </div>

                        <div className="modal-body">
                            <Container>
                                <div className='form-group mt-2'>
                                    <select className="form-select form-control" value={d_country} onChange={(e) => setDCountry(e.target.value)} aria-label="Default select example" id="dropdown">
                                        <option defaultValue='Select...'>Select Country</option>
                                        {
                                            countryList.map((the_d, index) => {
                                                return (
                                                    <option key={index} value={the_d.countryName}>{the_d.countryName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' type='text' value={d_region} onChange={(e) => setDRegion(e.target.value)} placeholder='Region' />
                                </div>
                            </Container>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="text-default_red mr-4" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#priceTypeModal">Cancel</button>

                            <button type="button" className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" onClick={() => doPostRegion()}>Post Region</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default RegionTab