import React, { useState, useEffect } from "react";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import ShowAlerts from "../Alerts/all_alerts";
import { ApproveTransaction } from "../../functions/PaymentsFuntions";
import { Empty, Spin } from "antd";

const PendingPayTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [reFetch, setReFetch] = useState(false);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_all_payments +
      `?filter={"action":"deposit"}&limit=${lim}&skip=${sk}&sort={"updatedAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //Table columns
  const columns = [
    {
      name: "Reference",
      selector: (row) => row.textRef,
    },
    {
      name: "Type",
      selector: (row) => row.action,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Amount",
      selector: (row) => row.currency + " " + row.deposit,
    },
    {
      name: "Date",
      selector: (row) => moment(row.timestamp).format("Do MMM YYYY"),
    },
    {
      name: "Time",
      selector: (row) => moment(row.timestamp).format("HH:MM A"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-4">
            {row.status === "Mark-As-Paid" ? (
              <Link
                className="text-default_green"
                to="#"
                title="Approve Transaction"
                onClick={showDetails.bind(this, row)}
              >
                <CheckBadgeIcon className="w-6 h-6" />
              </Link>
            ) : (
              <></>
            )}
          </div>
        </>
      ),
    },
  ];

  //Details & approve transaction
  const showDetails = (i) => {
    // console.log(i);

    var data = {
      textRef: i.textRef,
      status: "Successful",
      userid: i.userid,
    };

    // console.log(data);

    ShowAlerts.question_alert(
      "Confirm",
      "Do you wish to approve this transaction",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        ShowAlerts.loading_alert("Approving Transaction...");
        //Approve transaction function
        ApproveTransaction(data);
      }
    });
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filParam, setFilParam] = useState("textRef");
  const [filQry, setFilQry] = useState("");

  //second filter
  const handleFilterChange2 = (e) => {
    // setFilQry("");
    setFilParam(e.target.value);
    // console.log(e.target.value);

    if (e.target.value === "textRef") {
      setFilParam(e.target.value);
    } else {
      setLoading(true);

      const the_route =
        global_variables().get_all_payments +
        `?filter={"action":"deposit","status":${JSON.stringify(
          e.target.value
        )}}&sort={"updatedAt":-1}`;

      BaseService.get_api(the_route)
        .then((response) => {
          setTheData(response.data.payload);
          setTotalRows(response.data.total);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const doSearch = (e) => {
    e.preventDefault();
    setLoading(true);

    const the_route =
      global_variables().get_all_payments +
      `?filter={"action":"deposit","textRef":{"$regex":${JSON.stringify(
        filQry
      )},"$options":"i"}}&sort={"updatedAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-8">
          {" "}
          <i
            className="fa fa-sync btn"
            onClick={() => setReFetch(!reFetch)}
          ></i>{" "}
        </div>

        <form className="col-md-4" onSubmit={doSearch}>
          <div className="input-group ">
            <select
              className="form-select"
              aria-label="Default select example"
              value={filParam}
              onChange={handleFilterChange2}
              style={{ border: "1px solid #d9e2ef" }}
            >
              <option value="Select..." disabled>
                Filter status ...{" "}
              </option>
              <option value="textRef">Reference</option>
              <option value="Successful">Successful</option>
              <option value="Pending">Pending</option>
              <option value="Mark-As-Paid">Awaiting Approval</option>
              <option value="Failed">Failed</option>
            </select>

            <input
              className="form-control shadow-none"
              placeholder="Search..."
              id="truckNum"
              aria-label="Search"
              aria-describedby="search-addon"
              autoComplete="off"
              type="text"
              style={{ border: "1px solid #d9e2ef" }}
              required
              value={filQry}
              onChange={(e) => setFilQry(e.target.value)}
              autoFocus
            />
            <button
              type="submit"
              className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </form>
      </div>

      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            progressPending={loading}
            columns={columns}
            data={theData}
            paginationTotalRows={totalRows}
            pagination
            paginationServer
            onChangePage={changePage}
            onChangeRowsPerPage={rowChange}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default PendingPayTable;
