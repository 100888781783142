import React from "react";
import { Navigate } from "react-router-dom";
import { StorageBox } from "../helpers/stroage";

const PrivateRoute = ({ children }: any) => {
  const token = StorageBox.getAccessToken();

  if (token) {

    return children;

  }
  else {
    return <Navigate to="/" />
  }
};

export default PrivateRoute;
