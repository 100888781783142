import React from 'react';

const Table_Shimmer = () => {
    return (
        <>

            <div role="status" className="p-4 space-y-4 mt-3 mb-3 w-full rounded animate-pulse">
                <div className="flex justify-between items-center mt-3">
                    <div className="flex gap-12">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-44 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center mt-3">
                    <div className="flex gap-12">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-44 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center mt-3">
                    <div className="flex gap-12">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-44 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center mt-3">
                    <div className="flex gap-12">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-44 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center mt-3">
                    <div className="flex gap-12">
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-96 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                        <div>
                            <div className="h-2.5 bg-gray-300 rounded-full"></div>
                            <div className="w-44 h-2 bg-gray-200 rounded-full"></div>
                        </div>
                    </div>
                </div>
                <span className="sr-only">Loading...</span>
            </div>

        </>
    )
}

export default Table_Shimmer