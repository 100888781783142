import ShowToast from "../components/Alerts/all_toasts";
import BaseService from "../mixins/baseServices";
import ShowAlert from "../components/Alerts/all_alerts";
import { StorageBox } from "../helpers/stroage";
import { global_variables } from "../helpers/globalVariables";
import { message } from "antd";

//Sign in
export const signInFunction = (email: any, password: any) => {
  // console.log({email,password});

  BaseService.post_api(global_variables.signin_url, { email, password })
    .then((response) => {
      // console.log(response);
      ShowToast.success_toast("Signed In");
      StorageBox.saveAccessToken(response?.data?.token);
      StorageBox.saveUserData(response?.data?.payload);
      setTimeout(() => {
        window.location.href = "/verify-account";
      }, 1000);
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
    });
};

//Sign up
export const signUpFunction = (signup_data: any) => {
  const SignUp = global_variables.signup_url;

  BaseService.post_api(SignUp, signup_data)
    .then((response) => {
      // console.log(response);
      StorageBox.saveAccessToken(response?.data?.token);
      StorageBox.saveUserData(JSON.stringify(response?.data?.payload));
      window.location.href = "/verify-account";
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
    });
};

//Verify code for login
export const verifyCodeFunction = (the_code: any) => {
  const VerifyCode = global_variables.verify_code;

  BaseService.put_api(VerifyCode, the_code)
    .then((response) => {
      // console.log(response);
      message.success(response?.data?.message);
      StorageBox.saveUserData(response?.data?.payload);
      StorageBox.saveAccessToken(response?.data?.token);
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
      window.location.href = "/";
    });
};

//forget password on sign in
export const frgtPassFunction = (email: any) => {
  const ForgetPass = global_variables.forget_pss;

  BaseService.put_api(ForgetPass, email)
    .then((response) => {
      // console.log(response);
      StorageBox.saveAccessToken(response?.data?.token);
      ShowAlert.success_alert(
        "Success",
        response?.data?.message,
        "Enter Token"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/verify";
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
    });
};

//send verifcation code for password reset
export const sendCodeFunction = (email: any) => {
  const ForgetPass = global_variables.forget_pss;

  BaseService.put_api(ForgetPass, email)
    .then((response) => {
      // console.log(response);
      StorageBox.saveAccessToken(response?.data?.token);
      window.location.href = "/verify2";
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
    });
};

//veify code for new pass
export const verifyCodeFunction2 = (the_code: any) => {
  const VerifyCode = global_variables.verify_code;

  BaseService.put_api(VerifyCode, the_code)
    .then((response) => {
      // console.log(response);
      window.location.href = "/setNewPass";
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
    });
};

//setNewPass
export const setNewPassFunction = (new_pwd: any) => {
  const SetPass = global_variables.reset_password;

  BaseService.put_api(SetPass, new_pwd)
    .then((response) => {
      // console.log(response);
      StorageBox.saveAccessToken(response?.data?.token);
      ShowAlert.success_alert(
        "Success",
        "Password updated successfully! Proceed to sign in",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/";
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
    });
};

//update info
export const UpdateUserDetails = (userData: any) => {
  const updateUrl = global_variables.create_user;

  BaseService.put_api(updateUrl, userData)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert(
        "Success",
        "Your details updated successfully!",
        "Okay"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/";
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert("Error", `Couldn't update details`, "Retry").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    });
};

//sign out
export const signOutFunction = () => {
  StorageBox.clearStorage();
};
