import React from "react";
import { Container } from "react-bootstrap";
import SideBar from "../../components/layouts/SideBar";

const Bookings = () => {
  return (
    <>
      <SideBar bookingsActive="active" />

      <div className="content-wrapper sm:ml-64 sm:relative sm:top-4 sm:min-h-full">
        <Container>
          <h2 className="pt-1 pb-3 text-lg font-semibold">Manual Bookings</h2>
          <hr />
        </Container>
      </div>
    </>
  );
};

export default Bookings;
