import React, { useEffect, useState } from "react";
import { ListBulletIcon } from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import { Card, Container, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import TableShimmer from "../Shimmers/TableShimmer";
import ModalXBtn from "../Buttons/ModalXBtn.js";
import CloseModal from "../Buttons/CloseModal.js";
import Select from "react-select";
import ShowToast from "../Alerts/all_toasts";
import { Empty, Spin } from "antd";

const CanceledTripsTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const [uniqueLoaded, setUniqueLoaded] = useState(false);
  const [specificData, setSpecificData] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [reFetch, setReFetch] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //Fetch drivers
  const fetchDrivers = () => {
    const the_route =
      global_variables().get_all_drivers +
      '?filter={"approved": true}&sort={"createdAt":-1}';

    BaseService.get_api(the_route)
      .then((response) => {
        //console.log(response.data.payload);
        setDriverList(response.data.payload);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);
    const the_route =
      global_variables().get_all_trips +
      '?filter={"canceled": true}' +
      `&limit=${lim}&skip=${sk}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Onload
  useEffect(() => {
    fetchDrivers();
    fetchData(limit, skip);
  }, [reFetch]);

  //Table columns
  const columns = [
    {
      name: "",
      selector: (row) => (
        <img
          className="img-fluid rounded-full py-1"
          src={row.driver_data["image"]}
          width="45px"
        />
      ),
      width: "10%",
    },
    {
      name: "Driver",
      selector: (row) => row.driver_data["fullName"],
    },
    {
      name: "Pick Up",
      selector: (row) => row.pickUpPlace,
    },
    {
      name: "Destination",
      selector: (row) => row.destinationPlace,
    },
    {
      name: "Trip Date & Time",
      selector: (row) => moment(row.requestTime).format("Do MMM YYYY; hh:mm a"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#tripDetailsModal"
              onClick={showDetails.bind(this, row)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  //View Details
  const showDetails = (i) => {
    setSpecificData(i);
    setUniqueLoaded(true);
    // console.log(i);
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  const [filQry, setFilQry] = useState("");

  //Search fiter
  const applySearch = () => {
    if (filQry === "") {
      ShowToast.warning_toast(`Please input a driver's name`);
    } else {
      setLoading(true);

      const the_route =
        global_variables().get_all_trips +
        `?filter={"canceled": true, "driverId": ${JSON.stringify(filQry)}}`;

      BaseService.get_api(the_route)
        .then((response) => {
          // console.log(response.data.payload);
          setTheData(response.data.payload);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>
          <form className="col-md-6">
            <div className="input-group">
              <Container className="flex">
                <select
                  className="form-select rounded-tr-none rounded-br-none"
                  aria-label="Default select example"
                  style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                >
                  <option defaultValue="0" disabled>
                    Filter by ...{" "}
                  </option>
                  <option value="driver">Driver Name</option>
                </select>

                <Select
                  className="basic-single w-96"
                  isClearable={true}
                  isSearchable={true}
                  options={driverList.map((dd) => {
                    return {
                      value: dd.userid,
                      label: dd.user_data["fullName"],
                    };
                  })}
                  onChange={(e) => setFilQry(e.value)}
                />

                <button
                  onClick={() => applySearch()}
                  type="button"
                  className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange rounded-tl-none rounded-bl-none"
                >
                  <i className="fas fa-search"></i>
                </button>
              </Container>
            </div>
          </form>
        </div>
      </div>

      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            progressPending={loading}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
          />
        </Card.Body>
      </Card>

      {/* Trip Details Modal */}
      <div
        className="modal fade modal-xl"
        id="tripDetailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                View Trip Details
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                {!uniqueLoaded ? (
                  <TableShimmer />
                ) : (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>Trip Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="font-semibold">Pick Up City:</span>{" "}
                            {specificData.pickUpCity}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Pick Up Place:
                            </span>{" "}
                            {specificData.pickUpPlace}
                          </td>
                          <td>
                            <span className="font-semibold">Request Time:</span>{" "}
                            {moment(specificData.requestTime).format(
                              "Do MMM YYYY; hh:mm a"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">
                              Destination City:
                            </span>{" "}
                            {specificData.destinationCity}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Destination Place:
                            </span>{" "}
                            {specificData.destinationPlace}
                          </td>
                          <td>
                            <span className="font-semibold">End Time:</span>{" "}
                            {moment(
                              specificData.currentStatus["timestamp"]
                            ).format("Do MMM YYYY; hh:mm a")}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">
                              Trip Distance:
                            </span>{" "}
                            {specificData.distance +
                              " " +
                              specificData.distanceUnit}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <br />

                      <thead>
                        <tr>
                          <th>Driver Info</th>
                        </tr>
                      </thead>
                      {specificData["driver_data"] === null ? (
                        <></>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <img
                                className="img-fluid rounded-full"
                                src={specificData.driver_data["image"]}
                                alt="..."
                                width="100px"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="font-semibold">First Name:</span>{" "}
                              {specificData.driver_data["firstName"]}
                            </td>
                            <td>
                              <span className="font-semibold">Last Name:</span>{" "}
                              {specificData.driver_data["lastName"]}
                            </td>
                            <td>
                              <span className="font-semibold">
                                Phone Number:
                              </span>{" "}
                              {specificData.driver_data["phone"]}
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <br />

                      <thead>
                        <tr>
                          <th>Car Info</th>
                        </tr>
                      </thead>
                      {specificData["car_data"] === null ? (
                        <></>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <span className="font-semibold">
                                Car: {specificData.car_data["brand"]}{" "}
                                {specificData.car_data["model"]} (
                                {specificData.car_data["registeration"]})
                              </span>
                            </td>
                            <td>
                              <span className="font-semibold">
                                Roadworthy Name:
                              </span>{" "}
                              {specificData.car_data["roadWorthy"]}
                            </td>
                            <td>
                              <span className="font-semibold">
                                Insurance Number:
                              </span>{" "}
                              {specificData.car_data["insurance"]}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Front View
                              <img
                                className="img-fluid"
                                src={specificData.car_data["carFront"]}
                                alt="..."
                                width="150px"
                              />
                            </td>
                            <td>
                              Side View
                              <img
                                className="img-fluid"
                                src={specificData.car_data["carSide"]}
                                alt="..."
                                width="150px"
                              />
                            </td>
                            <td>
                              Back View
                              <img
                                className="img-fluid"
                                src={specificData.car_data["carBack"]}
                                alt="..."
                                width="150px"
                              />
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <br />

                      <thead>
                        <tr>
                          <th>Rider Info</th>
                        </tr>
                      </thead>
                      {specificData["user_data"] === null ? (
                        <></>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <img
                                className="img-fluid rounded-full"
                                src={specificData.user_data["image"]}
                                alt="..."
                                width="100px"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="font-semibold">First Name:</span>{" "}
                              {specificData.user_data["firstName"]}
                            </td>
                            <td>
                              <span className="font-semibold">Last Name:</span>{" "}
                              {specificData.user_data["lastName"]}
                            </td>
                            <td>
                              <span className="font-semibold">
                                Phone Number:
                              </span>{" "}
                              {specificData.user_data["phone"]}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </>
                )}
              </Container>
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CanceledTripsTable;
