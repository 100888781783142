import React, { useState } from 'react'
import logo from "../../images/logo.png";
import { Input, message, Spin } from 'antd';
import { EyeIcon } from '@heroicons/react/24/outline';
import BaseService from '../../helpers/baseServices';
import { StorageBox } from '../../helpers/stroage';
import { global_variables } from '../../helpers/globalVariables';
import { LoadingOutlined } from '@ant-design/icons';
import ForgotPassword from './forgotPassword';
import dashSvg from "../../images/dashboard_svg.svg"
import { useNavigate } from 'react-router-dom';


const NewSignInPage = () => {

    const [isBusy, setIsBusy] = useState(false)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate()

    //open forgot pwd
    const [openFogotPwd, setOpenFogotPwd] = useState(false)

    //Show Password
    const [values, setValues] = useState({ showPassword: false });

    const ViewPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    //Signin function
    const doSignIn = async () => {
        if (!email) {
            message.warning("Please enter your email!");
        } else if (!password) {
            message.warning("Please enter your password!");
        } else {
            setIsBusy(true);
            let signin_data = {
                "email": email,
                "password": password
            };

            try {
                const response = await BaseService.post_api(global_variables.signin_url, signin_data);
                console.log("signInRes:", response);

                setIsBusy(false);

                if (response?.data?.payload && Object.keys(response?.data?.payload).length > 0) {
                    StorageBox.saveAccessToken(response?.data?.token);
                    StorageBox.saveUserData(response?.data?.payload);
                    setTimeout(() => {
                        navigate("/dashboard");
                    }, 1000)
                } else {
                    StorageBox.saveAccessToken(response?.data?.token);
                    setTimeout(() => {
                        navigate("/verify-account");
                    }, 1000)
                }
            } catch (error: any) {
                console.log("---signInErr:", error);
                message.error(error?.response?.data?.error || "An error occurred, please try again!");
                setIsBusy(false);
            }
        }
    };


    return (
        <>
            <div className="w-full h-screen overflow-hidden">
                <div className="lg:grid grid-cols-2 w-full h-full">
                    {/* left */}
                    <div className="w-full bg-default_orange hidden lg:block">
                        <div className="flex justify-center items-center h-full">
                            <img
                                className="w-[350px]"
                                src={dashSvg}
                                alt="..."
                            />
                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full h-full overflow-y-auto">
                        <div className="flex flex-col items-center justify-center h-full 2xl:px-[150px] xl:px-[100px] lg:px-[100px] md:px-[50px] px-[20px]">
                            <div className="w-full">
                                <img
                                    className="pb-4 block mr-auto ml-auto"
                                    src={logo}
                                    alt="..."
                                    width="100px"
                                />
                            </div>

                            <div className="w-full bg-white shadow-md rounded p-[20px]">
                                <p className="text-2xl font-semibold">
                                    Administrator
                                </p>

                                <p className="text-text_primary">
                                    Enter your valid email and password to proceed
                                </p>

                                <div className="w-full mt-[40px]">
                                    <form onSubmit={doSignIn}>
                                        {/* email */}
                                        <div>
                                            <Input
                                                className="h-[45px] w-full rounded-md"
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter email"
                                                required
                                                autoFocus
                                                disabled={isBusy}
                                            />
                                        </div>

                                        {/* password */}
                                        <div className="mt-3 relative">
                                            <Input className="h-[45px] w-full rounded-md"
                                                type={values.showPassword ? "text" : "password"}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Password"
                                                required
                                                disabled={isBusy}
                                            />
                                            <EyeIcon className="w-4 h-4 absolute right-3 bottom-4 cursor-pointer" onClick={ViewPassword} />
                                        </div>

                                        {/* forgot pwd btn */}
                                        <div className="text-center mt-3">
                                            <button
                                                type="button"
                                                onClick={() => setOpenFogotPwd(true)}
                                                style={{ textDecoration: "none", color: "#8877cc" }}
                                                disabled={isBusy}
                                            >
                                                Forgot Password?
                                            </button>
                                        </div>

                                        {/* submit btn */}
                                        <div className="mt-4">
                                            <button
                                                className="h-[50px] bg-default_orange text-default_white shadow-md rounded-full form-control hover:text-default_white active:bg-default_orange disabled:cursor-not-allowed disabled:bg-gray-400"
                                                onClick={doSignIn}
                                                type="submit"
                                                disabled={isBusy}
                                            >
                                                {
                                                    isBusy ?
                                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#000" }} spin />} />
                                                        :
                                                        <p>Sign In</p>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Forgot password modal */}
            {
                openFogotPwd && <ForgotPassword isOpened={openFogotPwd} handleClose={() => setOpenFogotPwd(false)} />
            }
        </>
    )
}

export default NewSignInPage
