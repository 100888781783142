import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChatBubbleLeftEllipsisIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import global_variables from "../../mixins/globarVars";
import BaseService from "../../mixins/baseServices";
import moment from "moment-timezone";
import env from "react-dotenv";
import ShowToast from "../../components/Alerts/all_toasts";
import all_general_storage from "../../mixins/storage";
import ShowAlert from "../../components/Alerts/all_alerts";
import place_holder from "../../images/user-profile.png";
import PageLayout from "../../components/navLayouts/pageLayout";
const token = all_general_storage.getToken();
const api = process.env.API_BASE_URL;
const { io } = require("socket.io-client");
const socket = io(api + global_variables().realtime_url);

const LiveChat = () => {
  const [myOwnId, setMyOwnId] = useState("");

  const [chatHeadLoading, setChatHeadLoading] = useState(true);
  const [chatHeadData, setChatheadData] = useState([]);
  const [activeName, setActiveName] = useState("");

  const [dMessage, setDMessage] = useState("");
  const [userchatLoading, setUserChatLoading] = useState(true);
  const [typingStatus, setTypingStatus] = useState("");
  const [adminId, setAdminId] = useState("");
  const [mainChannel, setMainChannel] = useState("");
  const [specialId, setSpecialId] = useState("");
  const [userId, setUserId] = useState("");

  const [currentChat, setCurrentChat] = useState([]);

  const [limit, setLimit] = useState(5);

  const [filQry, setFilQry] = useState("all");

  //onload
  useEffect(() => {
    getChatHeads(limit);
    var userData = localStorage.getItem("userData");
    var parsedData = JSON.parse(userData);
    setMyOwnId(parsedData.user_data.userid);
  }, []);

  //get chat heads
  const getChatHeads = (lim) => {
    setChatHeadLoading(true);
    setUserChatLoading(true);
    setFilQry("all");
    const the_route =
      global_variables().get_chatheads + `?limit=${lim}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then(function (response) {
        // console.log(response.data.payload);

        const theData = response.data.payload;
        // const result = theData.filter(obj => obj.hasOwnProperty('adminId') && obj.adminId === myId['user_data']['userid']);
        // console.log(theData);
        setChatheadData(response.data.payload);
        setChatHeadLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });

    //start chat sockets
    const initChannel = "1";
    const handShakeParams = {
      token: token,
      initial_channel: initChannel,
    };

    socket.on("connect", () => {
      // console.log('Connected');

      socket.emit("/rydsom/handshake", handShakeParams);

      socket.on(initChannel, (res) => {
        // console.log(res);
        // console.log(res.payload.userid);
        setAdminId(res.payload.userid);

        setMainChannel(res.payload.main_channel);
      });
    });

    socket.on("disconnect", () => {
      // console.log("Disconnected");
      ShowToast.info_toast("Connection lost, please reload chat.");
    });
  };

  //get user chats
  const getUserChats = (i) => {
    setUserChatLoading(true);
    var go = chatHeadData[i]["_id"];

    var uniqueData = chatHeadData.filter(function (theData) {
      return theData._id === go;
    });

    setActiveName(uniqueData[0]["user_data"]["fullName"]);
    setUserId(uniqueData[0]["userid"]);
    setSpecialId(uniqueData[0]["specialId"]);
    // setTripId(uniqueData[0]['trip_data']['tripId']);
    // console.log(uniqueData[0]['adminId']);
    // console.log(myOwnId);

    if (
      myOwnId === uniqueData[0]["adminId"] ||
      uniqueData[0]["admin_data"] === null
    ) {
      const the_route =
        global_variables().get_userchat + `${uniqueData[0]["specialId"]}`;

      BaseService.get_api(the_route)
        .then(function (response) {
          // console.log(response.data.payload);
          var chatData = response.data.payload;
          chatData.messages.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );

          setCurrentChat(chatData.messages);
          setUserChatLoading(false);
        })
        .catch(function (error) {
          // console.log(error);
        });

      socket.on(
        `chat/${uniqueData[0]["trip_data"]["tripId"]}/${uniqueData[0]["user_data"]["userid"]}`,
        (response) => {
          // console.log(response);
          setTypingStatus(response.message);
          setTimeout(() => {
            setTypingStatus("");
          }, 3000);
          // setChatChannel(response.chatChannel);
          // setMetaChannel(response.metaChannel);
        }
      );

      //listen to chat channel
      socket.on(
        `chat/${uniqueData[0]["trip_data"]["tripId"]}/message`,
        (response) => {
          // console.log(response);
          //append to current chat (if id is my id do not append to current chat)
          if (response.payload.data.chatMeta.userid !== adminId) {
            var tempArr = currentChat;
            tempArr.push(response.payload.data);
            setCurrentChat(tempArr);
          }
        }
      );
    } else {
      ShowAlert.warning_alert(
        "Warning",
        "Another administrator has already been assigned to this chat.",
        "Okay"
      );
    }
  };

  const handleOnChange = (e) => {
    setDMessage(e.target.value);

    socket.emit(`${mainChannel}/core/typing-broadcast`, {
      userid: userId,
      specialId: specialId,
    });
  };

  //send message
  const sendMessage = (e) => {
    e.preventDefault();
    var today = new Date();
    // console.log(adminId);

    var msgData = {
      chatData: {
        message: dMessage,
      },
      chatMeta: {
        userid: adminId,
        specialId: specialId,
      },
      userid: adminId,
    };
    var msgData2 = {
      chatData: {
        message: dMessage,
      },
      chatMeta: {
        userid: adminId,
        specialId: specialId,
      },
      userid: adminId,
      createdAt: today.toISOString(),
    };

    var tempArr = currentChat;
    tempArr.push(msgData2);
    setCurrentChat(tempArr);
    setDMessage("");
    // console.log(currentChat);

    socket.emit(`${mainChannel}/core/save-admin`, msgData);
  };

  //Load more code
  const doLoadMore = () => {
    let newLimit;
    if (limit <= chatHeadData.length) {
      newLimit = limit + 5;
      // console.log(newLimit);
      setLimit(newLimit);
      getChatHeads(newLimit);
    } else if (limit === chatHeadData.length) {
      ShowToast.info_toast("All data has been retrieved");
    } else {
      ShowToast.info_toast("All data has been retrieved");
    }
  };

  //filter assigned chats
  const handleFilChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "all") {
      getChatHeads(limit);
      setFilQry("all");
    } else if (e.target.value === "mine") {
      setFilQry("mine");
      var filteredChat = chatHeadData.filter((dd) => {
        return dd.adminId === myOwnId;
      });
      // console.log(filteredChat);
      setChatheadData(filteredChat);
    } else {
      setFilQry("new");
      var filteredChat = chatHeadData.filter((dd) => {
        return dd.admin_data === null;
      });
      // console.log(filteredChat);
      setChatheadData(filteredChat);
    }
  };

  return (
    <>
      <PageLayout chatActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Live Chat</p>

          <hr className="my-3" />

          <Row className="mt-3">
            {/* Chat heads */}
            <Col md={4}>
              <Card
                className="shadow-sm rounded-xl border-y-0 border-default_orange border-b-0 border-x-2 h-screen overflow-y-auto"
                id="chatScroll"
              >
                <Card.Body>
                  <div className="flex justify-between mb-3">
                    <div>
                      <i
                        className="fa fa-sync btn"
                        onClick={() => {
                          getChatHeads(limit);
                        }}
                      ></i>{" "}
                    </div>

                    <div>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={filQry}
                        onChange={handleFilChange}
                        style={{
                          maxWidth: "150px",
                          border: "1px solid #d9e2ef",
                        }}
                      >
                        <option defaultValue="0" disabled>
                          Filter by ...{" "}
                        </option>
                        <option value="all">All Chats</option>
                        <option value="mine">My Chats</option>
                        <option value="new">New Chats</option>
                      </select>
                    </div>
                  </div>

                  {chatHeadLoading ? (
                    <div className="text-center mt-5">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      <p>Loading chats...</p>
                    </div>
                  ) : (
                    <>
                      {chatHeadData.map((dd, index) => {
                        return (
                          <Link
                            to="#"
                            key={index}
                            onClick={getUserChats.bind(this, index)}
                          >
                            <Row>
                              <hr className="mb-2" />
                              <Col md={3}>
                                <img
                                  className="img-fluid rounded-full"
                                  src={
                                    dd.user_data
                                      ? dd.user_data["image"]
                                      : place_holder
                                  }
                                  alt="..."
                                  width="80px"
                                />
                              </Col>
                              <Col md={7}>
                                <p className="font-semibold text-lg">
                                  {dd.user_data ? dd.user_data["fullName"] : ""}
                                </p>
                                {dd.last_chat ? (
                                  <>
                                    <p className="text-sm">
                                      {dd.last_chat[0]["chatData"]["message"]}
                                    </p>
                                    <small className="text-xs mt-1">
                                      {moment(
                                        dd.last_chat[0]["createdAt"]
                                      ).format("YYYY-MM-DD HH:MMA")}
                                    </small>
                                  </>
                                ) : (
                                  <p className="text-sm">...</p>
                                )}
                              </Col>
                              <Col md={2}>
                                {dd.number_unread_messages > 0 ? (
                                  <div className="rounded-full bg-default_orange text-default_white w-6 h-6 text-center">
                                    {dd.number_unread_messages}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Col>
                              <hr className="mt-2" />
                            </Row>
                          </Link>
                        );
                      })}
                      <div className="mt-4 text-center">
                        <Link
                          className="text-default_white bg-default_orange px-4 py-2 rounded-md hover:text-default_white"
                          onClick={doLoadMore}
                        >
                          Load more ...
                        </Link>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>

            {/* chat room */}
            <Col md={8}>
              {userchatLoading ? (
                <>
                  <Card className="shadow-sm rounded-xl border-y-0 border-default_orange border-b-0 border-x-2 h-screen">
                    <div className="flex items-center justify-center h-screen">
                      <div className="text-center">
                        <ChatBubbleLeftEllipsisIcon className="w-16 h-16 text-default_orange flex mr-auto ml-auto" />
                        <p className="font-semibold text-xl">Rydsom Chat</p>
                        <p>Click on a user to begin chatting online now.</p>
                      </div>
                    </div>
                  </Card>
                </>
              ) : (
                <>
                  <Card
                    className="shadow-sm rounded-xl border-y-0 border-default_orange border-b-0 border-x-2 h-screen overflow-y-auto"
                    id="chatScroll"
                  >
                    <Card.Body>
                      <Card className="shadow-sm border-0 rounded-none">
                        <Card.Body>
                          <div className="flex gap-3">
                            <div className="rounded-full bg-green-500 w-3 h-3 mt-3"></div>
                            <div>
                              <p className="font-semibold text-2xl">
                                {activeName}
                              </p>
                              <small className="text-xs">{typingStatus}</small>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>

                      <Container className="mt-3 mb-3">
                        {/* Messages */}
                        {currentChat.map((dd, index) => {
                          return dd.chatMeta["userid"] === adminId ? (
                            <>
                              <div
                                className="w-60 mt-2 float-right clear-both"
                                key={index}
                              >
                                <Card className="border-0 shadow-sm bg-default_orange text-default_white rounded-t-2xl rounded-l-2xl rounded-br-none">
                                  <Card.Body>
                                    <p>{dd.chatData["message"]}</p>
                                    <small className="float-right text-xs mt-1">
                                      {moment(dd.createdAt).format(
                                        "YYYY-MM-DD HH:MMA"
                                      )}
                                    </small>
                                  </Card.Body>
                                </Card>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="w-60 mt-2" key={index + 1}>
                                <Card className="border-0 shadow-sm bg-default_grey text-default_white rounded-t-2xl rounded-bl-none rounded-br-2xl">
                                  <Card.Body>
                                    <p>{dd.chatData["message"]}</p>
                                    <small className="float-right text-xs mt-1">
                                      {moment(dd.createdAt).format(
                                        "YYYY-MM-DD HH:MMA"
                                      )}
                                    </small>
                                  </Card.Body>
                                </Card>
                              </div>
                            </>
                          );
                        })}
                      </Container>
                    </Card.Body>
                  </Card>

                  <Container className="mt-3">
                    <Card className="border-0 rounded-none shadow-sm">
                      <Card.Body>
                        <form onSubmit={sendMessage}>
                          <div className="flex gap-3">
                            <input
                              className="h-10 px-2 form-control"
                              value={dMessage}
                              onChange={handleOnChange}
                              placeholder="Type message here..."
                              autoFocus
                              required
                            />

                            <button
                              className="bg-default_orange px-2 rounded-md shadow-sm text-default_white"
                              title="Send Message"
                              type="submit"
                            >
                              <PaperAirplaneIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </Container>
                </>
              )}
            </Col>
          </Row>

          <br />
        </div>
      </PageLayout>
    </>
  );
};

export default LiveChat;
