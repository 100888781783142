import React, { useState, useEffect } from 'react';
import { Pie } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import global_variables from '../../mixins/globarVars';
import BaseService from '../../mixins/baseServices';

const UserStatistics = () => {


    const [pieLoading, setPieLoading] = useState(true);
    const [totalDrivers, setTotalDrivers] = useState(0);
    const [totalRiders, setTotalRiders] = useState(0);


    //Onload
    useEffect(() => {
        const the_route = global_variables().get_all_drivers + '?filter={"approved": true}';

        BaseService.get_api(the_route)
            .then((response) => {
                //console.log(response.data.total);
                setTotalDrivers(response.data.total);

                const the_route2 = global_variables().get_all_users;
                const the_filter = '?filter={"userRoles":{"$regex":"user"}}';

                BaseService.get_api(the_route2 + the_filter)
                    .then((response) => {
                        // console.log(response.data.total);
                        setTotalRiders(response.data.total);
                        setPieLoading(false);
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
            })
            .catch((error) => {
                // console.log(error);
            })
    }, []);

    const data = {
        labels: ['Drivers', 'Users'],
        datasets: [{
            data: [totalDrivers, totalRiders],
            backgroundColor: [
                '#FCAF7F',
                '#9999C2'
            ],
            hoverOffset: 10
        }]
    };

    return (
        <>
            <Pie className='mb-4' data={data} />
        </>
    )
}

export default UserStatistics