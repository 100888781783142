import React, { useState, useEffect } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Card, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import ShowAlert from "../Alerts/all_alerts";
import ShowToast from "../Alerts/all_toasts";
import { PostWalletLimit } from "../../functions/AllPrices";
import { Empty, Spin } from "antd";
const isoCountryCurrency = require("iso-country-currency");

const WalletTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);

  const [countryList, setCountryList] = useState([]);
  const [d_country, setDCountry] = useState("");
  const [d_currency, setDCurrency] = useState("");
  const [d_amount, setDAmount] = useState("");
  const [freeDay, setFreeDay] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  // filter currency
  const getCurrency = async (e) => {
    setDCountry(e.target.value);
    var filtered = countryList.filter(function (theData) {
      return theData.countryName === e.target.value;
    });
    // console.log(filtered[0]['currency']);
    setDCurrency(filtered[0]["currency"]);
  };

  //Onload
  useEffect(() => {
    var data = isoCountryCurrency.getAllISOCodes();
    //console.log(data)
    setCountryList(data);

    fetchData(limit, skip);
  }, []);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_all_wallet_limit +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Free Daily",
      selector: (row) => row.freeDaily,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Edit Price"
              data-bs-toggle="modal"
              data-bs-target="#editDetailsModal"
              onClick={showDetails.bind(this, index)}
            >
              <PencilIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const showDetails = (i) => {
    var go = theData[i]["_id"];

    var uniqueData = theData.filter(function (theData) {
      return theData._id === go;
    });
    //console.log(uniqueData);
    setDAmount(uniqueData[0]["amount"]);
    setDCurrency(uniqueData[0]["currency"]);
    setFreeDay(uniqueData[0]["freeDaily"]);
    setDCountry(uniqueData[0]["country"]);
  };

  //Update wallet
  const doUpdateWallet = () => {
    if (
      d_country === "" ||
      d_currency === "" ||
      d_amount === "" ||
      freeDay === ""
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = JSON.stringify({
        country: d_country,
        amount: parseInt(d_amount),
        freeDaily: parseInt(freeDay),
        currency: d_currency,
      });

      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to post this data?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          ShowAlert.loading_alert("Psoting Wallet Limit...");
          //Create wallet function
          PostWalletLimit(data);
        }
      });
    }
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Edit Details modal */}
      <div
        className="modal fade modal-md"
        id="editDetailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Post Wallet Limit
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                <div className="form-group mt-2">
                  <select
                    className="form-select form-control"
                    value={d_country}
                    onChange={getCurrency}
                    aria-label="Default select example"
                    id="dropdown"
                  >
                    <option defaultValue="Select...">Select Country</option>
                    {countryList.map((the_d, index) => {
                      return (
                        <option key={index} value={the_d.countryName}>
                          {the_d.countryName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    type="text"
                    value={d_currency}
                    onChange={(e) => setDCurrency(e.target.value)}
                    placeholder="Currency"
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    type="text"
                    value={d_amount}
                    onChange={(e) => setDAmount(e.target.value)}
                    placeholder="Amount"
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    type="text"
                    value={freeDay}
                    onChange={(e) => setFreeDay(e.target.value)}
                    placeholder="Free Daily"
                  />
                </div>
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#priceTypeModal"
              >
                Cancel
              </button>

              <button
                type="button"
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                onClick={() => doUpdateWallet()}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTable;
