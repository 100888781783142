import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BaseService from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';


// Create the context
const GeneralContext = createContext<any>(null);

// Create a custom hook for easy access
export const useGeneralContext = () => useContext(GeneralContext);

interface ApiProviderProps {
    children: ReactNode;
}

export const GeneralContextProvider: React.FC<ApiProviderProps> = ({ children }) => {

    const [reFetch, setReFetch] = useState(false)

    return (
        <GeneralContext.Provider value={{ reFetch, setReFetch }}>
            {children}
        </GeneralContext.Provider>
    );
};
