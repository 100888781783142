import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import ShowAlert from "../Alerts/all_alerts";
import { StorageBox } from "../../helpers/stroage";

const CustomNavbar = () => {

  //handleLogout
  const handleLogout = () => {
    ShowAlert.question_alert("Confirm", "Do you wish to log out of your account?", "Proceed")
      .then((result) => {
        if (result.isConfirmed) {
          StorageBox.clearStorage()
        }
      })
  }

  return (
    <div className="w-full px-4 py-3 shadow-sm bg-white border-b border-b-gray-300">
      <div className="flex items-center justify-between text-blue-gray-900">
        {/* left */}
        <div className="w-[400px] relative">
          <input
            className="h-[40px] px-3 w-full focus:outline-none bg-midGreen bg-opacity-[5%] border rounded"
            placeholder="What are you looking for?"
          />
          <MagnifyingGlassIcon className="h-5 w-5 absolute right-3 bottom-[10px] text-midGreen" />
        </div>

        {/* middle */}
        <div className="hidden lg:block"></div>

        {/* right */}
        <div className="flex gap-4 items-center">
          <button className="px-3 text-red-500 hover:text-red-600" onClick={handleLogout}>
            <p>Sign Out</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomNavbar;
