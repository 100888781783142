import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShowToast from '../Alerts/all_toasts';
import ShowAlert from '../Alerts/all_alerts';
import { CreateCustomerService, PostCancelReason } from '../../functions/Admin';
import CancelTable from '../Tables/CancelTable';
const isoCountryCurrency = require('iso-country-currency');



const CancelTab = () => {

    const [countryList, setCountryList] = useState([]);
    const [d_country, setDCountry] = useState("");
    const [d_currency, setDCurrency] = useState("");
    const [d_reason, setDReason] = useState("");
    const [d_time, setDTime] = useState("");
    const [d_user, setDUser] = useState("");
    const [d_unit, setDUnit] = useState("m");
    const [d_charge, setDCharge] = useState("");



    // get countries on load
    useEffect(() => {
        var data = isoCountryCurrency.getAllISOCodes();
        //console.log(data)
        setCountryList(data);
    }, []);


    // filter currency
    const getCurrency = async (e) => {

        setDCountry(e.target.value);
        var filtered = countryList.filter(function (theData) {
            return theData.countryName === e.target.value;
        });
        // console.log(filtered[0]);
        setDCurrency(filtered[0]['currency']);

    }



    //Post customer service
    const doPostReason = () => {
        if (d_country === "Select Country..." || d_reason === "" || d_time === "" || d_unit === "Select Unit..." || d_user === "Select User..." || d_charge === "" || d_currency === "") {
            ShowToast.warning_toast('Please fill out all fields!');
        }
        else {
            ShowAlert.loading_alert('Creating new reason...');

            var data = {
                "reason": d_reason,
                "time": parseInt(d_time),
                "user": d_user,
                "unit": d_unit,
                "charge": parseInt(d_charge),
                "country": d_country,
                "currency": d_currency
            }

            PostCancelReason(data);

        }
    }







    return (
        <>
            <Container>

                <div className="container mt-4">
                    <div className='flex justify-between'>
                        <div> <i className='fa fa-sync btn' onClick={() => { window.location.reload() }}></i> </div>

                        <div>
                            <Link className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" to="#" data-bs-toggle="modal" data-bs-target="#postReason">+ Add New</Link>
                        </div>
                    </div>
                </div>


                <div>

                    <CancelTable />

                </div>


            </Container>







            {/* Post Cancel Reason */}
            <div className="modal fade modal-md" id="postReason" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 font-semibold" id="staticBackdropLabel">Post Cancel Reason</h1>
                        </div>

                        <div className="modal-body">
                            <Container>
                                <div className='form-group mt-2'>
                                    <select className="form-select form-control" value={d_country} onChange={getCurrency} aria-label="Default select example" id="dropdown">
                                        <option defaultValue='Select Country...'>Select Country</option>
                                        {
                                            countryList.map((the_d, index) => {
                                                return (
                                                    <option key={index} value={the_d.countryName}>{the_d.countryName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' type='text' value={d_currency} onChange={(e) => setDCurrency(e.target.value)} placeholder='Currency' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={d_reason} onChange={(e) => setDReason(e.target.value)} type='text' placeholder='Enter reason' />
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={d_time} onChange={(e) => setDTime(e.target.value)} type='text' placeholder='Minutes after accepting' />
                                </div>
                                <div className='form-group mt-2'>
                                    <select className="form-select form-control" value={d_unit} onChange={(e) => setDUnit(e.target.value)} aria-label="Default select example" id="dropdown2">
                                        <option defaultValue='Select Unit...' selected>Select Unit</option>
                                        <option value='m'>Minute</option>
                                    </select>
                                </div>
                                <div className='form-group mt-2'>
                                    <select className="form-select form-control" value={d_user} onChange={(e) => setDUser(e.target.value)} aria-label="Default select example" id="dropdown2">
                                        <option defaultValue='Select User...' selected>Select Users</option>
                                        <option value='user'>Rydsom Rider</option>
                                        <option value='driver'>Rydsom Driver</option>
                                    </select>
                                </div>
                                <div className='form-group mt-3'>
                                    <input className='form-control shadow-none rounded-md' value={d_charge} onChange={(e) => setDCharge(e.target.value)} type='text' placeholder='Charge' />
                                </div>
                            </Container>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="text-default_red mr-4" data-bs-toggle="modal" data-bs-target="#customerModal">Cancel</button>

                            <button type="button" className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" onClick={() => doPostReason()}>Post</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CancelTab