import React from 'react';
import UserStatistics from '../../components/charts_graphs/UserStatistics';
import { Link } from 'react-router-dom';
import WeekEarnings from '../../components/charts_graphs/WeekEarnings';
import SmallTransactionsTable from '../../components/Tables/SmallTransactionsTable';
import TotalRiders from '../../components/layouts/dashboard/TotalRiders';
import TotalDrivers from '../../components/layouts/dashboard/TotalDrivers';
import LiveTrips from '../../components/layouts/dashboard/LiveTrips';
import TotalEarnings from '../../components/layouts/dashboard/TotalEarnings';
import PageLayout from '../../components/navLayouts/pageLayout';

const Dashboard = () => {
  return (
    <>
      <PageLayout dashActive="active">

        <div className='h-full overflow-y-auto w-full px-10 pt-10 pb-20'>

          <p className='text-2xl font-semibold'>Dashboard</p>

          <hr className='my-3' />

          {/* First Section */}
          <div className='mt-3 grid grid-cols-4 gap-[20px] w-full'>

            <TotalRiders />

            <TotalDrivers />

            <LiveTrips />

            <TotalEarnings />

          </div>


          {/* Second Section */}
          <div className='mt-[30px] grid grid-cols-3 gap-[20px] w-full'>
            <div className='w-full col-span-2'>
              <div className='rounded-lg border-t-2 border-default_orange bg-white shadow-sm p-[20px]'>
                <p className='text-lg font-semibold'>This Week's Transactions</p>

                <div className='pt-2'>
                  <WeekEarnings />
                </div>
              </div>
            </div>

            <div className='w-full col-span-1'>
              <div className='rounded-lg border-t-2 border-default_orange bg-white shadow-sm p-[20px]'>
                <p className='text-lg font-semibold'>User Statistics</p>

                <div className='pt-2'>
                  <UserStatistics />
                </div>
              </div>
            </div>
          </div>


          {/* Third Section */}
          <div className='mt-[30px] mb-[30px] w-full'>
            <div className='rounded-lg border-t-2 border-default_orange p-[20px] bg-white shadow-md' style={{ minHeight: "320px" }}>
              <div className='flex justify-between pb-3'>
                <p className='text-lg font-semibold'>Transactions</p>
                <Link className='text-sm bg-default_orange text-white rounded-full pt-1 pl-4 pr-4' to='/transactions'>View All</Link>
              </div>
              <hr />

              {/* Table */}
              <SmallTransactionsTable />

            </div>
          </div>
        </div>

      </PageLayout>
    </>
  )
}

export default Dashboard