import { PhotoIcon } from '@heroicons/react/24/outline'

interface imgSelectProps {
    id: string
    selectedImgUrl: any
    onChange: any
    disabled?: boolean
}

const ImageUploader = ({ id, selectedImgUrl, onChange, disabled }: imgSelectProps) => {


    return (
        <>
            <div className='w-full'>
                <label className='w-full' htmlFor={id}>
                    <div className='w-full h-[200px] bg-default_orange bg-opacity-[10%] flex justify-center items-center border-[2px] border-default_orange border-dashed rounded-md cursor-pointer'>
                        {
                            selectedImgUrl ? <img src={selectedImgUrl} alt="mmg" className='h-full w-full object-cover' />
                                :
                                <PhotoIcon className='h-10 w-10 text-default_orange' />
                        }
                    </div>
                </label>
                <input id={id} type='file' className='hidden disabled:cursor-not-allowed' style={{ display: 'none' }} accept="image/*" onChange={onChange} disabled={disabled} />
            </div>
        </>
    )
}

export default ImageUploader
