import React from 'react';
import { Container } from 'react-bootstrap';
import PendingPayTable from '../Tables/PendingPayTable';





const PendingPayTab = () => {

    return (
        <>
            <Container>

                <div>

                    <PendingPayTable />

                </div>


            </Container>

        </>
    )
}

export default PendingPayTab