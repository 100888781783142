import React, { useState, useEffect } from "react";
import { ListBulletIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Card, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import { Empty, Spin } from "antd";

const PayDescriptionTable = () => {
  const [loading, setLoading] = useState(true);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const [details, setTheDetails] = useState();

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().payment_description_url +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData(limit, skip);
  }, []);

  //Table columns
  const columns = [
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Network",
      selector: (row) => row.network,
    },
    {
      name: "Code",
      selector: (row) => row.code,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-4">
            <Link className="text-default_red" to="#" title="Delete">
              <TrashIcon className="w-5 h-5" />
            </Link>
            <Link
              to="#"
              title="Description"
              data-bs-toggle="modal"
              data-bs-target="#detailsModal"
              onClick={showDetails.bind(this, row)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  //Details
  const showDetails = (i) => {
    // console.log(i);
    setTheDetails(i);
    setDetailsLoading(false);
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Payment Description modal */}
      <div
        className="modal fade modal-lg"
        id="detailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Payment Description
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                {detailsLoading ? (
                  <>
                    <div className="text-center">
                      <p>No data to show...</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: details.description }}
                    />
                  </>
                )}
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-dismiss="modal"
                data-bs-target="#detailsModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayDescriptionTable;
