import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShowToast from "../Alerts/all_toasts";
import ShowAlert from "../Alerts/all_alerts";
import { CreateNewClass } from "../../functions/CarsAndClasses";
import ClassesTable from "../Tables/ClassesTable";
import { useGeneralContext } from "../../contexts/generalContext";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";
import { message, Spin } from "antd";

const ClassesTab = () => {
  const [d_class, setDClass] = useState("");
  const [d_promo, setDPromo] = useState("");
  const { reFetch, setReFetch } = useGeneralContext();
  const [isBusy, setIsBusy] = useState(false);

  //trigger post modal
  const [show, setPstShow] = useState(false);
  const handlePostClose = () => setPstShow(false);
  const handlePostShow = () => setPstShow(true);

  //Post Brand
  const doPostClass = () => {
    if (d_class === "" || d_promo === "") {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = {
        class: d_class,
        promo: JSON.parse(d_promo),
      };

      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to post this class?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          setIsBusy(true);

          BaseService.post_api(global_variables.rydsom_class_url, data)
            .then((response) => {
              // console.log(response);
              message.success("New Class Created!");
              setIsBusy(false);
              handlePostClose();
              setReFetch(!reFetch);
            })
            .catch((error) => {
              console.log(error);
              setIsBusy(false);
              message.error(
                error?.response?.data?.error ||
                  "Could not create new car class! Retry"
              );
            });
        }
      });
    }
  };

  return (
    <>
      <Container>
        <div className="container mt-4">
          <div className="flex justify-between">
            <div>
              {" "}
              <i
                className="fa fa-sync btn"
                onClick={() => setReFetch(!reFetch)}
              ></i>{" "}
            </div>

            <div>
              <Link
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                to="#"
                onClick={() => handlePostShow()}
              >
                + Add New
              </Link>
            </div>
          </div>
        </div>

        <div>
          <ClassesTable />
        </div>
      </Container>

      {/* Post Class modal */}
      <Modal show={show} onHide={handlePostClose}>
        <Spin spinning={isBusy}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 className="font-semibold">Post Rydsom Class</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="form-group mt-3">
                <input
                  className="form-control shadow-none rounded-md"
                  type="text"
                  value={d_class}
                  onChange={(e) => setDClass(e.target.value)}
                  placeholder="Enter car feature"
                />
              </div>
              <div className="form-group mt-2">
                <select
                  className="form-select form-control"
                  value={d_promo}
                  onChange={(e) => setDPromo(e.target.value)}
                  aria-label="Default select example"
                  id="dropdown"
                >
                  <option defaultValue="Select...">Promo Status</option>
                  <option value={true}>Allowable</option>
                  <option value={false}>Not Allowed</option>
                </select>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="text-default_red mr-4"
              onClick={() => handlePostClose()}
            >
              Cancel
            </button>

            <button
              type="button"
              className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
              onClick={() => doPostClass()}
            >
              Post Class
            </button>
          </Modal.Footer>
        </Spin>
      </Modal>
    </>
  );
};

export default ClassesTab;
