import React, { useState } from 'react'
import { useGeneralContext } from '../../contexts/generalContext'
import { Form, Input, message, Modal, notification, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import UserSelector from '../../components/shared/userSelector';
import { MAP_KEY } from '../../helpers/contants';
import ImageUploader from '../../components/shared/imageUploader';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
}

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    user: string;
    image: string;
}



const AddDriverModal = ({ isOpened, handleClose }: modalProps) => {

    const [form] = useForm()
    const { reFetch, setReFetch } = useGeneralContext()
    const [isBusy, setIsBusy] = useState(false)
    const [isError, setIsError] = useState(false);
    const [imgError, setImgError] = useState(false)
    const [imageFile, setImageFile] = useState<any>(null);
    const [imageObjectURL, setImageObjectURL] = useState<string | null>(null);
    const [currStep, setCurrStep] = useState(0)
    const [driverId, setDriverId] = useState("")

    //pickup states
    const [pickLat, setPickLat] = useState<any>(0)
    const [pickLong, setPickLong] = useState<any>(0)
    const [pickPlaceName, setPickPlaceName] = useState("")
    //license
    const [frontFile, setFrontFile] = useState<any>(null)
    const [licenseFrontUrl, setLicenseFrontUrl] = useState("")
    const [backFile, setBackFile] = useState<any>(null)
    const [licenseBackUrl, setLicenseBackUrl] = useState("")
    const [idFrontFile, setIdFrontFile] = useState<any>(null)
    const [idFrontUrl, setIdFrontUrl] = useState("")
    const [idBackFile, setIdBackFile] = useState<any>(null)
    const [idBackUrl, setIdBackUrl] = useState("")

    const [licenceNum, setLicenceNum] = useState("")
    const [dateIssued, setDateIssued] = useState("")
    const [expDate, setExpDate] = useState("")
    const [wasSkipped, setWasSkipped] = useState(false)
    const [nationalIdNum, setNationalIdNum] = useState("")


    //handleImageFileChange driver photo
    const handleImageFileChange = (event: any) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setImageFile(selectedFile);

            const objectURL = URL.createObjectURL(selectedFile);
            setImageObjectURL(objectURL);
        }
    };

    // handle pikup slected
    const handlePickUpSlected = (selected: any) => {
        if (selected) {
            console.log(selected.value);
            geocodeByPlaceId(selected.value.place_id)
                .then((results: any) => {
                    // console.log(results);
                    getLatLng(results[0]).then(({ lat, lng }) => {
                        // console.log(lat, lng);
                        setPickLat(lat);
                        setPickLong(lng);
                    });
                    setPickPlaceName(selected?.value?.description);
                })
                .catch((error) => console.error(error));
        } else {
            console.log("No option selected");
            setPickPlaceName("");
        }
    };

    //handle on front select
    const onFrontSelect = (e: any) => {
        const file = e.target.files[0];
        setFrontFile(file)
        if (file) {
            const imageUrl: any = URL.createObjectURL(file);
            setLicenseFrontUrl(imageUrl);
        }
    }

    //handle on back select
    const onBackSelect = (e: any) => {
        const file = e.target.files[0];
        setBackFile(file)
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setLicenseBackUrl(imageUrl);
        }
    }

    //handle on id front select
    const onIDFrontSelect = (e: any) => {
        const file = e.target.files[0];
        setIdFrontFile(file)
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setIdFrontUrl(imageUrl);
        }
    }

    //handle on id back select
    const onIDBackSelect = (e: any) => {
        const file = e.target.files[0];
        setIdBackFile(file)
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setIdBackUrl(imageUrl);
        }
    }


    //create new user
    const onFinish = async (values: any) => {

        if (!pickPlaceName) {
            message.warning("Enter your residential address!")
        }
        else if (!licenseFrontUrl) {
            message.warning("Upload license front image!")
        } else if (!licenseBackUrl) {
            message.warning("Upload license back image!")
        } else if (!idFrontUrl) {
            message.warning("Upload national id front image!")
        } else if (!idBackUrl) {
            message.warning("Upload national id back image!")
        } else {
            console.log("___formValues:", values)
            setIsBusy(true)
            //upload licence front
            try {
                const frontImgRes: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${driverId}front&storage=driverLicense`, frontFile)
                console.log({ cardFront: frontImgRes?.data?.url })

                //upload license back
                try {
                    const backImgRes: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${driverId}back&storage=driverLicense`, backFile)
                    console.log({ cardBack: backImgRes?.data?.url })

                    //upload national id
                    try {
                        const idFrontImgRes: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${driverId}cardF&storage=driverCard`, idFrontFile)
                        console.log({ nationalIdFront: idFrontImgRes?.data?.url })

                        try {
                            const idBackImgRes: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${driverId}cardB&storage=driverCard`, idBackFile)
                            console.log({ nationalIdBack: idBackImgRes?.data?.url })

                            //uplod all details
                            const mainBody = JSON.stringify({
                                "license": values?.license,
                                "licenseIssuedDate": new Date(values?.licenseIssuedDate).toISOString(),
                                "licenseExpireDate": new Date(values?.licenseExpiryDate).toISOString(),
                                "userid": values?.userid?.value,
                                "licenseImage": frontImgRes?.data?.url,
                                "licenseImageBack": backImgRes?.data?.url,
                                "nationalID": values?.nationalID,
                                "nationalIDImage": idFrontImgRes?.data?.url,
                                "nationalIDBackImage": idBackImgRes?.data?.url,
                                "residentialAddress": {
                                    "place": pickPlaceName,
                                    "latitude": pickLat,
                                    "longitude": pickLong
                                }
                            })

                            const dd = JSON.parse(mainBody)
                            console.log("-----data to post", dd)

                            try {
                                const mainRes = await BaseService.post_api(global_variables.create_new_driver, mainBody)
                                console.log(mainRes?.data)
                                notification.success({ message: mainRes?.data?.message })
                                setIsBusy(false)
                                handleClose()
                                setReFetch(!reFetch)
                            } catch (error: any) {
                                console.log("detailsError:", error)
                                notification.error({ message: error?.response?.data?.error, placement: "topLeft" })
                                setIsBusy(false)
                            }
                        } catch (error: any) {
                            console.log("idBackError:", error)
                            notification.error({ message: error?.response?.data?.error, placement: "topLeft" })
                            setIsBusy(false)
                        }
                    } catch (error: any) {
                        console.log("idFrontError:", error)
                        notification.error({ message: error?.response?.data?.error, placement: "topLeft" })
                        setIsBusy(false)
                    }
                } catch (error: any) {
                    console.log("backError:", error)
                    notification.error({ message: error?.response?.data?.error, placement: "topLeft" })
                    setIsBusy(false)
                }
            } catch (error: any) {
                console.log("frontError:", error)
                notification.error({ message: error?.response?.data?.error, placement: "topLeft" })
                setIsBusy(false)
            }
        }

    }


    return (
        <>
            <Modal width={600} title={<p>Add new driver</p>} open={isOpened} onCancel={handleClose} keyboard={false} footer={null} maskClosable={false} centered>
                <hr />
                <div className="h-[450px] overflow-y-auto px-3">
                    <Spin spinning={isBusy}>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            layout="vertical"
                            className='mt-3'
                        >
                            <div className="lg:grid lg:grid-cols-2 lg:gap-x-10">
                                {/* user */}
                                <Form.Item name="userid" label="Select User" rules={[{ required: true, message: 'Please search and choose a user' }]}>
                                    <UserSelector onChange={(selected: any) => setDriverId(selected?.value)} />
                                </Form.Item>

                                {/* address */}
                                <Form.Item name="residentialAddress" label="Residential Address">
                                    <GooglePlacesAutocomplete
                                        apiKey={MAP_KEY}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ["mw"],
                                            },
                                        }}
                                        selectProps={{
                                            placeholder: "Search...",
                                            isClearable: true,
                                            isSearchable: true,
                                            onChange: (selected) => handlePickUpSlected(selected),
                                            isDisabled: isBusy
                                        }}
                                    />
                                </Form.Item>
                            </div>

                            <hr className='my-4' />

                            {/* license */}
                            <div className="">
                                <Form.Item name="license" label="Driver's License Number" rules={[{ required: true, message: 'Enter license number' }]}>
                                    <Input className='rounded-[5px] border-gray-300' placeholder='Ex: MW2764673894' />
                                </Form.Item>

                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-10">
                                    <Form.Item name="licenseIssuedDate" label="Date Issued" rules={[{ required: true, message: 'Select date issued' }]}>
                                        <Input className='rounded-[5px] border-gray-300' type="date" />
                                    </Form.Item>

                                    <Form.Item name="licenseExpiryDate" label="Expiry Date" rules={[{ required: true, message: 'Select expiry date' }]}>
                                        <Input className='rounded-[5px] border-gray-300' type="date" />
                                    </Form.Item>
                                </div>

                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-10 mt-3">
                                    <div>
                                        <p className={`${isError ? 'text-red-500' : ''}`}>License Front Image</p>
                                        <ImageUploader id="frontLicense" selectedImgUrl={licenseFrontUrl} onChange={onFrontSelect} />
                                    </div>
                                    <div>
                                        <p className={`${isError ? 'text-red-500' : ''}`}>License Back Image</p>
                                        <ImageUploader id="backLicense" selectedImgUrl={licenseBackUrl} onChange={onBackSelect} />
                                    </div>
                                </div>
                            </div>

                            <hr className='mt-4' />

                            {/* national id */}
                            <div className='mt-4'>
                                <Form.Item name="nationalID" label="National ID Number" rules={[{ required: true, message: 'Enter national id number' }]}>
                                    <Input className='rounded-[5px] border-gray-300' placeholder='Ex: MW3497599200' />
                                </Form.Item>

                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-10">
                                    <div>
                                        <p className={`${isError ? 'text-red-500' : ''}`}>National ID Front</p>
                                        <ImageUploader id="idImgFront" selectedImgUrl={idFrontUrl} onChange={onIDFrontSelect} />
                                    </div>
                                    <div>
                                        <p className={`${isError ? 'text-red-500' : ''}`}>National ID Back</p>
                                        <ImageUploader id="idImgBack" selectedImgUrl={idBackUrl} onChange={onIDBackSelect} />
                                    </div>
                                </div>
                            </div>


                            <Form.Item className='mt-5'>
                                <button className='w-full h-[50px] bg-gradient-to-r from-default_blue to-default_orange text-white rounded-[40px]'>
                                    <p>Create New Driver</p>
                                </button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </>
    )
}

export default AddDriverModal
