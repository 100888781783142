import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShowToast from "../Alerts/all_toasts";
import ShowAlert from "../Alerts/all_alerts";
import { CreateNewDescription } from "../../functions/PaymentsFuntions";
import PayDescriptionTable from "../Tables/PayDescriptionTable";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const isoCountryCurrency = require("iso-country-currency");

const PaymentDescTab = () => {
  const [countryList, setCountryList] = useState([]);

  const [d_country, setDCountry] = useState("Select...");
  const [d_network, setDNetwork] = useState("");
  const [d_code, setDCode] = useState("");
  const [d_desc, setDescription] = useState("");
  const [reFetch, setReFetch] = useState(false);

  //options for text editor
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ align: [] }],
  ];

  // get countries on load
  useEffect(() => {
    var data = isoCountryCurrency.getAllISOCodes();
    //console.log(data)
    setCountryList(data);
  }, []);

  const handleDescOnChange = (value, delta, source, editor) => {
    setDescription(editor.getHTML());
  };

  //Post description
  const doPostDescription = () => {
    if (
      d_country === "Select..." ||
      d_desc === "" ||
      d_network === "" ||
      d_code === ""
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = {
        network: d_network,
        country: d_country,
        code: d_code,
        description: `<html>${d_desc}</html>`,
      };

      // console.log(data)
      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to post this data?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          ShowAlert.loading_alert("Posting Payment Description...");
          //Create description function
          CreateNewDescription(data);
        }
      });
    }
  };

  return (
    <>
      <Container>
        <div className="container mt-4">
          <div className="flex justify-between">
            <div>
              {" "}
              <i
                className="fa fa-sync btn"
                onClick={() => setReFetch(!reFetch)}
              ></i>{" "}
            </div>

            <div>
              <Link
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#descriptionModal"
              >
                + Add New
              </Link>
            </div>
          </div>
        </div>

        <div>
          <PayDescriptionTable />
        </div>
      </Container>

      {/* Payment Description modal */}
      <div
        className="modal fade modal-md"
        id="descriptionModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Post Payment Description
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                <div className="form-group mt-2">
                  <select
                    className="form-select form-control"
                    value={d_country}
                    onChange={(e) => setDCountry(e.target.value)}
                    aria-label="Default select example"
                    id="dropdown"
                  >
                    <option defaultValue="Select...">Select Country</option>
                    {countryList.map((the_d, index) => {
                      return (
                        <option key={index} value={the_d.countryName}>
                          {the_d.countryName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    type="text"
                    value={d_network}
                    onChange={(e) => setDNetwork(e.target.value)}
                    placeholder="Enter Network"
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    className="form-control shadow-none rounded-md"
                    type="text"
                    value={d_code}
                    onChange={(e) => setDCode(e.target.value)}
                    placeholder="Enter Short Code"
                  />
                </div>
                <div className="form-group mt-3 h-56">
                  <ReactQuill
                    className="h-40"
                    value={d_desc}
                    onChange={handleDescOnChange}
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="Enter some text..."
                  />
                </div>
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-dismiss="modal"
                data-bs-target="#descriptionModal"
              >
                Cancel
              </button>

              <button
                type="button"
                className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
                onClick={() => doPostDescription()}
              >
                Post Description
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDescTab;
