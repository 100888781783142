import CustomNavbar from "./navbar";
import CustomSideBar from "./sidebar";


interface layoutProps {
  children: any
  adminActive?: any
  payActive?: any
  commActive?: any
  carsActive?: any
  transActive?: any
  notifyActive?: any
  chatActive?: any
  dashActive?: any
  usersActive?: any
  driverActive?: any
  vechActive?: any
  trackActive?: any
  tripActive?: any
  promoActive?: any
  settingsActive?: any

}

const PageLayout = ({ children, adminActive, payActive, commActive, carsActive, transActive, notifyActive, chatActive, dashActive, usersActive, driverActive, vechActive, trackActive, tripActive, promoActive, settingsActive }: layoutProps) => {
  return (
    <div className="h-screen overflow-hidden">
      <div className="flex h-full">
        {/* sidebar */}
        <CustomSideBar adminActive={adminActive} payActive={payActive} commActive={commActive} carsActive={carsActive} transActive={transActive} notifyActive={notifyActive} chatActive={chatActive} usersActive={usersActive} driverActive={driverActive} vechActive={vechActive} trackActive={trackActive} tripActive={tripActive} promoActive={promoActive} settingsActive={settingsActive} dashActive={dashActive} />
        {/* navbar */}
        <div className="w-full">
          <CustomNavbar />
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
