import { PencilSquareIcon } from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import ShowAlert from "../Alerts/all_alerts";
import ModalXBtn from "../Buttons/ModalXBtn";
import { UpdateCode } from "../../functions/PromoFunctions";
import ShowToast from "../Alerts/all_toasts";
import { Empty, Spin } from "antd";
const isoCountryCurrency = require("iso-country-currency");

const PromoTable = () => {
  var currentDate = new Date();
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [limit, setLimit] = useState(4);

  const [loaded2, setLoaded2] = useState(false);
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");
  const [percent, setPercent] = useState("");
  const [d_limit, setDLimit] = useState("");
  const [tripCount, setTripCount] = useState("");
  const [type, setType] = useState("user");
  const [typeValue, setTypeValue] = useState("unrestricted");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [reFetch, setReFetch] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [d_country, setDCountry] = useState("Select...");

  //onload
  useEffect(() => {
    // get countries on load
    var data = isoCountryCurrency.getAllISOCodes();
    //console.log(data)
    setCountryList(data);

    fetchData(limit);
  }, [reFetch]);

  //fetch data
  const fetchData = (lim) => {
    setLoaded(false);
    const the_route =
      global_variables().get_all_promo + `?limit=${lim}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setData(response.data.payload);
        setLoaded(true);
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  //Pick Code for edit
  const PickCode = (i) => {
    var go = data[i]["_id"];

    var uniqueProf = data.filter(function (theData) {
      return theData._id === go;
    });
    // console.log(uniqueProf);
    setCode(uniqueProf[0]["promoCode"]);
    setAmount(uniqueProf[0]["amount"]);
    setPercent(uniqueProf[0]["percent"]);
    setDLimit(uniqueProf[0]["limit"]);
    setTripCount(uniqueProf[0]["tripNumber"]);
    setType(uniqueProf[0]["type"]);
    setDCountry(uniqueProf[0]["country"]);
    setTypeValue(uniqueProf[0]["typeValue"]);
    setStart(uniqueProf[0]["createdAt"].slice(0, 10));
    setEnd(uniqueProf[0]["expiryDate"].slice(0, 10));
    setLoaded2(true);
  };

  //Update function
  const doUpdate = (e) => {
    e.preventDefault();

    if (type === "city" && typeValue === "unrestricted") {
      ShowToast.warning_toast("Please enter a vaid city name!");
    } else if (type === "regional" && typeValue === "unrestricted") {
      ShowToast.warning_toast("Please enter a valid region name!");
    } else if (d_country === "Select..." || d_limit === "") {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      //Checkpoint
      ShowAlert.question_alert(
        "Confirm!",
        "Do yo wish to update this promo code?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          var data = {
            country: d_country,
            promoCode: code,
            limit: parseInt(d_limit),
            amount: parseInt(amount),
            percent: parseInt(percent),
            tripNumber: parseInt(tripCount),
            type: type.toLowerCase(),
            typeValue: typeValue,
            activationDate: start,
            expiryDate: end,
          };
          // console.log(data)

          ShowAlert.loading_alert("Saving Changes...");

          UpdateCode(data);
        }
      });
    }
  };

  //Load more code
  const doLoadMore = () => {
    let newLimit;
    if (limit <= data.length) {
      newLimit = limit + 4;
      // console.log(newLimit);
      setLimit(newLimit);
      fetchData(newLimit);
    } else if (limit === data.length) {
      ShowToast.info_toast("All data has been retrieved");
    } else {
      ShowToast.info_toast("All data has been retrieved");
    }
  };

  //Search Table
  const [filParam, setFilParam] = useState("country");
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    setLoaded(false);
    e.preventDefault();

    const the_route =
      global_variables().get_all_promo +
      `?filter={${JSON.stringify(filParam)}:{"$regex":${JSON.stringify(
        filQry
      )},"$options":"i"}}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setData(response.data.payload);
        setLoaded(true);
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  return (
    <>
      <Container>
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-6">
              {" "}
              <i
                className="fa fa-sync btn"
                onClick={() => setReFetch(!reFetch)}
              ></i>{" "}
            </div>
            <form className="col-md-6" onSubmit={handleSearch}>
              <div className="input-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={filParam}
                  onChange={(e) => setFilParam(e.target.value)}
                  style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
                >
                  <option defaultValue="0" disabled>
                    Filter by ...{" "}
                  </option>
                  <option value="country">Country</option>
                  <option value="typeValue">Range</option>
                </select>

                <input
                  className="form-control shadow-none"
                  placeholder="Search..."
                  id="truckNum"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  autoComplete="off"
                  type="text"
                  style={{ border: "1px solid #d9e2ef" }}
                  required
                  value={filQry}
                  onChange={(e) => setFilQry(e.target.value)}
                  autoFocus
                />
                <button
                  type="submit"
                  className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>

        <Row className="mt-4">
          {!loaded ? (
            <div className="text-center mt-5">
              <Spin size="large" />
            </div>
          ) : data?.length === 0 ? (
            <div className="text-center mt-5">
              <Empty description="No promo codes to display..." />
            </div>
          ) : (
            data.map((the_d, index) => {
              return (
                <Col className="mt-2" md={3} key={index}>
                  <Card className="shadow-sm rounded-md border-t-2 border-default_orange border-b-0 border-x-0">
                    <Card.Body>
                      <div className="flex justify-between">
                        <p className="text-xl font-bold text-default_orange">
                          {the_d.promoCode}
                        </p>
                        <Link
                          to="#"
                          onClick={PickCode.bind(this, index)}
                          data-bs-toggle="modal"
                          data-bs-target="#editCodeModal"
                        >
                          <PencilSquareIcon className="w-5 h-5" />
                        </Link>
                      </div>

                      <div className="mt-2">
                        <p className="font-semibold text-xl text-default_orange">
                          {the_d.percent}% Off
                        </p>
                      </div>

                      <div className="mt-2">
                        <p className="font-semibold text-xl text-default_orange">
                          {the_d.country}
                        </p>
                      </div>

                      <div className="mt-2 flex gap-2">
                        <p className="font-semibold">Usage:</p>
                        <p>
                          {the_d.usage} of {the_d.tripNumber}
                        </p>
                      </div>

                      <div className="mt-2 flex gap-2">
                        <p className="font-semibold">Limit:</p>
                        <p>{the_d.limit} users</p>
                      </div>

                      <div className="mt-2 flex gap-2">
                        <p className="font-semibold">Use Type:</p>
                        <p>{the_d.type}</p>
                      </div>

                      <div className="mt-2 flex gap-2">
                        <p className="font-semibold">Range:</p>
                        <p>{the_d.typeValue}</p>
                      </div>

                      <div className="mt-2">
                        <div className="flex gap-2">
                          <p className="font-semibold">Created On:</p>
                          <p>{moment(the_d.createdAt).format("Do MMM YYYY")}</p>
                        </div>
                        {currentDate < new Date(the_d.expiryDate) ? (
                          <div className="flex gap-2">
                            <p className="font-semibold">Expires On:</p>
                            <p>
                              {moment(the_d.expiryDate).format("Do MMM YYYY")}
                            </p>
                          </div>
                        ) : (
                          <div className="flex gap-2">
                            <p className="font-semibold text-default_red">
                              Expired
                            </p>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          )}

          {data?.length >= 4 ? (
            <div className="text-center mt-4 mb-4">
              <Link
                className="text-default_orange"
                onClick={() => doLoadMore()}
              >
                Load more ...
              </Link>
            </div>
          ) : (
            <></>
          )}
        </Row>
      </Container>

      {/* Edit code modal */}
      <div
        className="modal fade modal-md"
        id="editCodeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Edit Promo Code
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                {!loaded2 ? (
                  <div className="text-center mt-5">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <p>Please wait...</p>
                  </div>
                ) : (
                  <form onSubmit={doUpdate}>
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        value={d_country}
                        onChange={(e) => setDCountry(e.target.value)}
                        aria-label="Default select example"
                        id="dropdown"
                      >
                        <option defaultValue="Select...">Select Country</option>
                        {countryList.map((the_d, index) => {
                          return (
                            <option key={index} value={the_d.countryName}>
                              {the_d.countryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <input
                        className="form-control rounded-md"
                        type="text"
                        id="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Promo Code"
                        required
                        disabled
                      />
                    </div>
                    <div className="form-group mt-3">
                      <input
                        className="form-control rounded-md"
                        type="text"
                        id="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Amount"
                        required
                      />
                    </div>

                    <div className="form-group mt-3">
                      <input
                        className="form-control rounded-md"
                        type="text"
                        id="limit"
                        value={d_limit}
                        onChange={(e) => setDLimit(e.target.value)}
                        placeholder="No. of users"
                        required
                      />
                    </div>

                    <Row>
                      <Col className="mt-3" md={6}>
                        <div className="form-group">
                          <input
                            className="form-control rounded-md"
                            type="text"
                            id="percentage"
                            value={percent}
                            onChange={(e) => setPercent(e.target.value)}
                            placeholder="Percentage Off"
                            required
                          />
                        </div>
                      </Col>
                      <Col className="mt-3" md={6}>
                        <div className="form-group">
                          <input
                            className="form-control rounded-md"
                            type="text"
                            id="times"
                            value={tripCount}
                            onChange={(e) => setTripCount(e.target.value)}
                            placeholder="Use limit"
                            required
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mt-3" md={6}>
                        <div className="form-group">
                          <label htmlFor="start">Start Date</label>
                          <input
                            className="form-control rounded-md"
                            type="date"
                            id="start"
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                            required
                          />
                        </div>
                      </Col>
                      <Col className="mt-3" md={6}>
                        <div className="form-group">
                          <label htmlFor="end">Expiry Date</label>
                          <input
                            className="form-control rounded-md"
                            type="date"
                            id="end"
                            value={end}
                            onChange={(e) => setEnd(e.target.value)}
                            required
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mt-3" md={6}>
                        <div className="form-group">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            id="chooseType"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option defaultValue="user">User</option>
                            <option value="city">City</option>
                            <option value="regional">Region</option>
                          </select>
                        </div>
                      </Col>
                      <Col className="mt-3" md={6}>
                        {type === "user" ? (
                          <></>
                        ) : (
                          <div className="form-group">
                            <input
                              className="form-control rounded-md"
                              type="text"
                              id="end"
                              value={typeValue}
                              onChange={(e) => setTypeValue(e.target.value)}
                              placeholder="Value"
                            />
                          </div>
                        )}
                      </Col>
                    </Row>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="mt-4 bg-default_orange text-default_white w-fit px-3 py-1 text-lg rounded-full hover:text-default_white hover:bg-default_orange"
                      >
                        Update Code
                      </button>
                    </div>
                  </form>
                )}
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoTable;
