import ShowAlert from "../components/Alerts/all_alerts";
import BaseService from "../mixins/baseServices";
import global_variables from "../mixins/globarVars";

//Post payment description
export const CreateNewDescription = (data) => {
  const the_route = global_variables().payment_description_url;

  BaseService.post_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert(
        "Success",
        "Payment Description Created!",
        "Okay"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not create payment description!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Delete payment description
export const DeletePaymentDescription = (data) => {
  const the_route = global_variables().payment_description_url;

  BaseService.delete_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert(
        "Success",
        "Payment Description Deleted!",
        "Okay"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not delete payment description!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Delete payment description
export const ApproveTransaction = (data) => {
  const the_route = global_variables().approve_transaction;

  BaseService.put_api(the_route, data)
    .then((response) => {
      // console.log(response);
      ShowAlert.success_alert("Success", "Transaction Approved!", "Okay").then(
        (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        }
      );
    })
    .catch((error) => {
      // console.log(error);
      ShowAlert.error_alert(
        "Error",
        "Could not approve this transaction!",
        "Retry"
      ).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
};

//Calculate Commisions
export const SumCommissions = (array) => {
  if (array?.length !== 0) {
    return array.reduce((sum, obj) => sum + obj.credit, 0);
  }

  return 0;
};
