import axios from "axios";
import { BASE_URL } from "../helpers/contants";
import { StorageBox } from "../helpers/stroage";

const config: any = {
  headers: {
    "Content-Type": "application/json",
  },
};

const token = StorageBox.getAccessToken();

//Axios Post
const post_api = (route: any, data: any) => {
  config.headers["x-access-token"] = token;
  return axios.post(BASE_URL + route, data, config);
};

//Axios Get
const get_api = (route: any) => {
  config.headers["x-access-token"] = token;
  return axios.get(BASE_URL + route, config);
};

//Axios Put
const put_api = (route: any, data: any) => {
  config.headers["x-access-token"] = token;
  return axios.put(BASE_URL + route, data, config);
};

//Axios Delete
const delete_api = (route: any, data: any) => {
  var config = {
    method: "delete",
    url: BASE_URL + route,
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  config.headers["x-access-token"] = token;
  return axios(config);
};

const BaseService = {
  post_api,
  get_api,
  put_api,
  delete_api,
};

export default BaseService;
