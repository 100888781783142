import React, { useState, useEffect } from "react";
import { SumCommissions } from "../../../functions/PaymentsFuntions";
import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";

const TotalCommissions = () => {
  const [loading9, setLoading9] = useState(true);
  const [totalComm, setTotalComm] = useState(0);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    BaseService.get_api(
      `${global_variables.get_all_payments}?filter={"status":"Successful","credit":{"$gt":0}}`
    )
      .then(function (response) {
        // console.log(response.data.payload);
        setTotalComm(response?.data?.payload);
        setCurrency(response?.data?.payload?.[0]?.["currency"]);
        setLoading9(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  return (
    <>
      {loading9 ? (
        " "
      ) : (
        <span className="text-default_orange">
          {" " + currency || "GHS"} {SumCommissions(totalComm)}
        </span>
      )}
    </>
  );
};

export default TotalCommissions;
