import ShowAlert from "../components/Alerts/all_alerts";
import BaseService from "../mixins/baseServices";
import global_variables from "../mixins/globarVars";

export const CreatePrice = (data) => {
    const the_route = global_variables().create_price;

    BaseService.post_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'New price added!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'New price was not added!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}


//Regional Pricing
export const CreateRegionalPrice = (data) => {
    const the_route = global_variables().create_regional_price;

    BaseService.post_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'New regional price added!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Regional price was not added!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}


//Post Region
export const PostRegion = (data) => {
    const the_route = global_variables().post_regions;

    BaseService.post_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'New region added!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Region was not added!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}



//Post wallet limit
export const PostWalletLimit = (data) => {
    const the_route = global_variables().post_wallet_limit;

    BaseService.post_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'New wallet limit added!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Wallet limit was not added!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}