import React, { useState, useEffect } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Card, Container, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import ShowAlert from "../Alerts/all_alerts";
import { CreateNewClass, DeleteClass } from "../../functions/CarsAndClasses";
import ShowToast from "../Alerts/all_toasts";
import { Empty, Spin } from "antd";
import { useGeneralContext } from "../../contexts/generalContext";

const ClassesTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const { reFetch } = useGeneralContext();

  const [d_class, setDClass] = useState("");
  const [d_promo, setDPromo] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //trigger edit modal
  const [show, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().rydsom_class_url +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //Table columns
  const columns = [
    {
      name: "Class",
      selector: (row) => row.class,
    },
    {
      name: "Promo Status",
      selector: (row) => (row.promo ? "Allowable" : "Not Allowed"),
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-3">
            <Link to="#" title="Edit Class" onClick={doEdit.bind(this, row)}>
              <PencilIcon className="w-5 h-5" />
            </Link>
            <Link
              className="text-default_red"
              to="#"
              title="Delete Class"
              onClick={doDelete.bind(this, index)}
            >
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const doDelete = (i) => {
    var go = theData[i]["_id"];

    var uniqueData = theData.filter(function (theData) {
      return theData._id === go;
    });
    // console.log(uniqueData);

    ShowAlert.question_alert(
      "Confirm",
      "Do you wish to delete this info?",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: uniqueData[0]["_id"],
        };
        ShowAlert.loading_alert("Deleting class...");
        //Delete feature function
        DeleteClass(data);
      }
    });
  };

  const doEdit = (i) => {
    // console.log(i);
    setDClass(i.class);
    setDPromo(i.promo);
    handleEditShow();
  };

  //Edit Brand
  const doEditClass = () => {
    if (d_class === "" || d_promo === "") {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = {
        class: d_class,
        promo: JSON.parse(d_promo),
      };

      handleEditClose();

      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to edit this class?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          ShowAlert.loading_alert("Editing Class...");
          //Create class function
          CreateNewClass(data);
        }
      });
    }
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Update Class modal */}
      <Modal show={show} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="font-semibold">Edit Rydsom Class</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="form-group mt-3">
              <input
                className="form-control shadow-none rounded-md"
                disabled
                type="text"
                value={d_class}
                onChange={(e) => setDClass(e.target.value)}
                placeholder="Enter car feature"
              />
            </div>
            <div className="form-group mt-2">
              <select
                className="form-select form-control"
                value={d_promo}
                onChange={(e) => setDPromo(e.target.value)}
                aria-label="Default select example"
                id="dropdown"
              >
                <option defaultValue="Select...">Promo Status</option>
                <option value={true}>Allowable</option>
                <option value={false}>Not Allowed</option>
              </select>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="text-default_red mr-4"
            onClick={() => handleEditClose()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
            onClick={() => doEditClass()}
          >
            Edit Class
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClassesTable;
