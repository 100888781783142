import { ListBulletIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import { Empty, Spin } from "antd";

const UpdatesTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const [specificData, setSpecificData] = useState([]);
  const [mLoaded, setMLoaded] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //Onload
  useEffect(() => {
    fetchData(limit, skip);
  }, []);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);

    const the_route =
      global_variables().get_all_updates +
      `?limit=${lim}&skip=${sk}&sort={"created_at":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "Platform",
      selector: (row) => row.platform,
    },
    {
      name: "Package",
      selector: (row) => row.package,
    },
    {
      name: "Last Force",
      selector: (row) => row.lastforce,
    },
    {
      name: "Build",
      selector: (row) => row.build,
    },
    {
      name: "Link",
      selector: (row) => row.link,
    },
    {
      name: "Version",
      selector: (row) => row.version,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.created_at).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-3">
            <Link
              to="#"
              title="View Details"
              onClick={showDetails.bind(this, index)}
              data-bs-toggle="modal"
              data-bs-target="#updateDetModal"
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
            <Link
              to="#"
              title="Delete Customer"
              onClick={showDetails.bind(this, index)}
            >
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const showDetails = (i) => {
    var go = theData[i]["_id"];

    var uniqueData = theData.filter(function (theData) {
      return theData._id === go;
    });
    //console.log(uniqueData[0]);
    setSpecificData(uniqueData[0]);
    setMLoaded(true);
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Details modal */}
      <div
        className="modal fade modal-lg"
        id="updateDetModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Update Details
              </h1>
            </div>

            <div className="modal-body">
              <Container>
                {mLoaded ? (
                  <>
                    <Row>
                      <Col md={6}>
                        <div className="flex gap-3">
                          <p className="font-bold">Platform:</p>
                          <p>{specificData.platform}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-bold">Build:</p>
                          <p>{specificData.build}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-bold">Package:</p>
                          <p>{specificData.package}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-bold">Date Created:</p>
                          <p>
                            {moment(specificData.created_at).format(
                              "Do MMM YYYY"
                            )}
                          </p>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="flex gap-3">
                          <p className="font-bold">Last Force:</p>
                          <p>{specificData.lastforce}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-bold">Link:</p>
                          <Link to={specificData.link} target="_blank">
                            Click to open
                          </Link>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-bold">Message:</p>
                          <p>{specificData.message}</p>
                        </div>
                      </Col>
                    </Row>

                    <hr className="mt-4" />
                    <p className="font-bold mt-3">Major Fixes:</p>

                    {specificData.update ? (
                      <>
                        {specificData["update"].map((dd, index) => {
                          return (
                            <div className="flex gap-3 mt-3" key={index}>
                              <p>{index + 1}.</p>
                              <p>{dd}</p>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div className="text-center">
                          <p>No major fixes available</p>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="text-center mt-4 mb-4">
                      <p>No data to show</p>
                    </div>
                  </>
                )}
              </Container>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="text-default_red mr-4"
                data-bs-toggle="modal"
                data-bs-target="#updateDetModal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatesTable;
