import { ListBulletIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import moment from "moment-timezone";
import CloseModal from "../Buttons/CloseModal";
import ModalXBtn from "../Buttons/ModalXBtn";
import { Empty, Spin } from "antd";

const PricingTable = () => {
  const [loading, setLoading] = useState(true);
  const [theData, setTheData] = useState([]);
  const [specificData, setSpecificData] = useState([]);
  const [dataIn, setDataIn] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //Onload
  useEffect(() => {
    fetchData(limit, skip);
  }, []);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);
    const the_route =
      global_variables().get_all_prices +
      `?limit=${lim}&skip=${sk}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        //console.log(response.data.payload);
        setTheData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Base Price",
      selector: (row) => row.basePrice,
    },
    {
      name: "Operation Fee",
      selector: (row) => row.operationFee,
    },
    {
      name: "Commission",
      selector: (row) => row.commission,
    },
    {
      name: "Vehicle Type",
      selector: (row) => row.type,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("Do MMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#priceDetailsModal"
              onClick={showDetails.bind(this, index)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const showDetails = (i) => {
    var go = theData[i]["country"];

    var uniqueData = theData.filter(function (theData) {
      return theData.country === go;
    });
    setSpecificData(uniqueData);
    setDataIn(true);
    // console.log(uniqueData);
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  return (
    <>
      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={theData}
            pagination
            progressPending={loading}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
          />
        </Card.Body>
      </Card>

      {/* Details modal */}
      <div
        className="modal fade modal-xl"
        id="priceDetailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold"
                id="staticBackdropLabel"
              >
                Pricing Details
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                {dataIn ? (
                  <>
                    <Row>
                      <Col md={6}>
                        <div className="flex gap-3">
                          <p className="font-semibold">Currency:</p>
                          <p>{specificData[0]["currency"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Country:</p>
                          <p>{specificData[0]["country"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Base Price:</p>
                          <p>{specificData[0]["basePrice"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Vehicle Type:</p>
                          <p>{specificData[0]["type"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Date Created:</p>
                          <p>
                            {moment(specificData[0]["createdAt"]).format(
                              "Do MMM YYYY"
                            )}
                          </p>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="flex gap-3">
                          <p className="font-semibold">Cost Per Distance:</p>
                          <p>{specificData[0]["costPerDistance"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Cost Per Minute:</p>
                          <p>{specificData[0]["costPerMinute"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Operation Fee:</p>
                          <p>{specificData[0]["operationFee"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Operation Fee Type:</p>
                          <p>{specificData[0]["operationFeeType"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Commission:</p>
                          <p>{specificData[0]["commission"]}</p>
                        </div>
                        <div className="flex gap-3 mt-3">
                          <p className="font-semibold">Commission Type:</p>
                          <p>{specificData[0]["commissionFeeType"]}</p>
                        </div>
                      </Col>
                    </Row>

                    <hr className="mt-3" />
                    <p className="font-semibold text-lg mt-3">Rydsom Classes</p>

                    {specificData[0]["class"] ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Type</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Promo Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {specificData[0]["class"].map((the_d, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{the_d.type}</td>
                                  <td>{the_d.amount}</td>
                                  <td>
                                    {the_d.promo ? "Allowable" : "Forbidden"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <p>No classes to display</p>
                      </>
                    )}

                    <p className="font-semibold text-lg mt-5">
                      Rydsom Surge Prices
                    </p>

                    {specificData[0]["surge"] ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">No. of requests</th>
                              <th scope="col">Percentage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {specificData[0]["surge"].map((the_d, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{the_d.value}</td>
                                  <td>{the_d.percent + "%"}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <p>No classes to display</p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <p className="mt-3">No data to display</p>
                    </div>
                  </>
                )}
              </Container>
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingTable;
