import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import verify_img from "../../images/code_verify.png";
import { LoadingOutlined } from '@ant-design/icons';
import {
  sendCodeFunction,
} from "../../functions/Authentication";
import ShowToast from "../../components/Alerts/all_toasts";
import { message, Modal, Spin } from "antd";
import { BASE_URL } from "../../helpers/contants";
import { StorageBox } from "../../helpers/stroage";
import axios from "axios";
import { global_variables } from "../../helpers/globalVariables";

const AuthVerify2 = () => {

  const navigate = useNavigate()
  const [openForgetModal, setOpenForgetModal] = useState(false)
  const [email, setEmail] = useState("");
  const [pinValue, setPinValue] = useState("");
  const [isBusy, setIsBusy] = useState(false)

  //Check if code is correct
  const runVerification = (the_code: any) => {

    let data = {
      "token": the_code
    };

    setIsBusy(true)

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${BASE_URL}${global_variables.verify_code}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(response?.data)
        message.success(response?.data?.message);
        StorageBox.saveUserData(response?.data?.payload);
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      })
      .catch((error: any) => {
        console.log(error);
        setIsBusy(false)
        message.error(error?.response?.data?.error || "Unknown error occured. Please retry")
        navigate("/")
      });
  };

  //resend code
  const doResend = () => {
    if (email === "") {
      ShowToast.warning_toast("Please enter your email!");
    } else {
      sendCodeFunction({ email: email });
    }
  };


  return (
    <>
      <div className="h-screen w-full overflow-hidden bg-white">
        <div className="flex justify-center items-center h-full">
          <div className="row">
            <div className="col-md-6 mt-3">
              <img className="img-fluid" src={verify_img} alt="..." />
            </div>

            <div className="col-md-6 mt-3 flex flex-col justify-center">
              <div className="header">
                <h3 id="header-text">Check your mailbox</h3>
              </div>
              <p style={{ color: "#656565" }}>
                We've sent a 5-digit verification code to your email, check your
                inbox <br />
                and enter the code below to verify your account.
              </p>

              <div style={{ marginTop: "0.5rem" }}>
                <PinInput
                  length={5}
                  onChange={(value) => {
                    setPinValue(value);
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{ borderColor: "#8877cc" }}
                  inputFocusStyle={{ borderColor: "#523dc4" }}
                  onComplete={(value) => {
                    runVerification(value);
                  }}
                  autoSelect={true}
                  disabled={isBusy}
                />
              </div>

              <div className="mt-[30px]">
                {
                  isBusy ? <Spin indicator={<LoadingOutlined style={{ fontSize: 38, color: "#000" }} spin />} />
                    :
                    <div>
                      <Link
                        className="text-default_orange"
                        to="#"
                        onClick={() => setOpenForgetModal(true)}
                      >
                        Didn't get the email?
                      </Link>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Resend Modal */}
      {
        openForgetModal && <Modal open={openForgetModal} onCancel={() => setOpenForgetModal(false)} centered footer={null}>
          <div className="w-full">
            <h5 className="text-[18px] font-[500]">Resend Verification</h5>

            <div className="w-full mt-[20px]">
              If you don't see an email from us within 5 minutes, one of these
              things could have happened: <br /> <br />
              1. The email might be in your spam folder. (If you use Gmail,
              please check your Promotions folder as well.) <br /> <br />
              2. The email address you entered had a typo. <br /> <br />
              3. You accidentally entered another email address. (Usually
              happens with auto-complete.) <br /> <br />
              4. We can't deliver the email to this address. (Usually because of
              corporate firewalls or filtering.) <br /> <br />
              <p>Enter email again and click the resend button.</p>
              <div className="form-group mt-3">
                <input
                  className="form-control shadow-none border-outline_light rounded-md"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="admin_email"
                  placeholder="Enter email"
                  required
                  autoFocus
                />
              </div>
              <div className="from-group text-center pt-3">
                <button
                  type="submit"
                  className="bg-default_orange text-default_white px-[20px] py-[8px] rounded-md"
                  onClick={() => doResend()}
                >
                  Resend Code To My Email
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default AuthVerify2;
