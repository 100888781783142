import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ShowAlert from "../../components/Alerts/all_alerts";
import ShowToast from "../../components/Alerts/all_toasts";
import { SendEmail, SendSms } from "../../functions/Communication";
import PageLayout from "../../components/navLayouts/pageLayout";

const Notifications = () => {
  const [smsNum, setSmsNum] = useState("");
  const [smsMsg, setSmMsg] = useState("");

  const [theMail, setTheMail] = useState("");
  const [subject, setSubject] = useState("");
  const [mailMsg, setMailMsg] = useState("");

  const [recList, setRecList] = useState([]);
  const [recMailList, setRecMailList] = useState([]);
  const [added, setAdded] = useState(false);
  const [added2, setAdded2] = useState(false);

  //Add to sms list
  const doAdd = () => {
    if (smsNum === "") {
      ShowToast.warning_toast("Enter a phone number");
    } else {
      var tempArr = recList;
      tempArr.push(smsNum);
      setRecList(tempArr);
      setAdded(true);
      setSmsNum("");
      //console.log(recList);
    }
  };

  //Send sms function
  const sms_send = (e) => {
    e.preventDefault();

    if (recList.length === 0) {
      ShowToast.warning_toast("Please add at least one phone number!");
    } else {
      ShowAlert.loading_alert("Sending Message...");

      const data = {
        contacts: recList,
        message: smsMsg,
        senderId: "Rydsom",
      };

      // console.log(data);
      SendSms(data);
    }
  };

  //Add to mail list
  const doMailAdd = () => {
    if (theMail === "") {
      ShowToast.warning_toast("Enter a valid email address!");
    } else {
      var tempArr = recMailList;
      tempArr.push(theMail);
      setRecMailList(tempArr);
      setAdded2(true);
      setTheMail("");
      //console.log(recList);
    }
  };

  //Send Mail Message
  const send_email = (e) => {
    e.preventDefault();

    if (recMailList.length === 0) {
      ShowToast.warning_toast("Please add at least one email address");
    } else {
      ShowAlert.loading_alert("Sending Email...");

      const data = {
        emails: recMailList,
        message: mailMsg,
        subject: subject,
      };

      // console.log(data);
      SendEmail(data);
    }
  };

  const [tabActive, setTabActive] = useState(0);

  //Tab names
  const tabNames = [
    {
      name: "Send SMS",
    },
    {
      name: "Send Emails",
    },
  ];

  const showActive = (i) => {
    if (i === tabActive) {
      return "rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl bg-default_orange text-default_white";
    } else {
      return "bg-text_white rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl hover:bg-default_orange hover:text-default_white";
    }
  };

  return (
    <>
      <PageLayout notifyActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Notifications</p>

          <hr className="my-3" />

          <Tabs className="mt-3">
            <TabList className="bottom-0 mb-3 flex gap-2 text-xs md:text-md lg:text-lg xl:text-lg">
              {tabNames.map((item, index) => (
                <Tab key={index} className="border-0 bg-transparent text-sm">
                  <button
                    key={index}
                    onClick={setTabActive.bind(this, index)}
                    className={showActive(index)}
                  >
                    {item.name}
                  </button>
                </Tab>
              ))}
            </TabList>

            {/* Send SMS */}
            <TabPanel>
              <Container>
                <Row>
                  <Col md={6}>
                    <Card className="rounded-lg shadow-sm border-none">
                      <Card.Body>
                        <div>
                          <p className="text-xl font-bold text-center">
                            Fill out the form to send an SMS
                          </p>

                          <form onSubmit={sms_send}>
                            <div className="form-group mt-3">
                              <small className="text-default_red">
                                * Click on "+ Add" to add phone number to the
                                list of recipients{" "}
                              </small>
                              <input
                                className="form-control rounded-md mb-2"
                                type="text"
                                value={smsNum}
                                onChange={(e) => setSmsNum(e.target.value)}
                                placeholder="+233000000000"
                              />
                              <Link
                                className="bg-default_orange text-default_white px-2 rounded-md fa-pull-right"
                                to="#"
                                onClick={doAdd}
                              >
                                {" "}
                                + Add
                              </Link>
                            </div>{" "}
                            <br />
                            <div className="form-group mt-4">
                              <textarea
                                className="form-control rounded-md h-64"
                                value={smsMsg}
                                onChange={(e) => setSmMsg(e.target.value)}
                                type="text"
                                placeholder="Message"
                                required
                              />
                            </div>
                            <div className="form-group mt-4">
                              <button
                                className="form-control rounded-md bg-default_orange text-default_white hover:bg-default_orange hover:text-default_white"
                                type="submit"
                              >
                                Send SMS
                              </button>
                            </div>
                          </form>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={6}>
                    <p className="text-center">List of Recipients </p>
                    <hr />

                    {!added ? (
                      <p className="text-center mt-4 text-lg text-default_grey">
                        List is empty...
                      </p>
                    ) : (
                      recList.map((the_d, index) => (
                        <>
                          <div className="flex gap-4 mt-2" key={index}>
                            <p key={index}>{index + 1}.</p>
                            <p key={the_d}>{the_d}</p>
                          </div>
                        </>
                      ))
                    )}
                  </Col>
                </Row>
              </Container>
            </TabPanel>

            {/* Send Email */}
            <TabPanel>
              <Container>
                <Row>
                  <Col md={6}>
                    <Card className="rounded-lg shadow-sm border-none">
                      <Card.Body>
                        <div>
                          <p className="text-xl font-bold text-center">
                            Fill out the form to send an Email
                          </p>

                          <form onSubmit={send_email}>
                            <div className="form-group mt-3">
                              <small className="text-default_red">
                                * Click on "+ Add" to add more emails to the
                                list of recipients
                              </small>
                              <input
                                className="form-control rounded-md"
                                type="email"
                                value={theMail}
                                onChange={(e) => setTheMail(e.target.value)}
                                placeholder="Enter Email(s)"
                              />
                              <Link
                                className="bg-default_orange text-default_white px-2 rounded-md fa-pull-right mt-2"
                                to="#"
                                onClick={doMailAdd}
                              >
                                {" "}
                                + Add
                              </Link>
                            </div>

                            <div className="form-group mt-5">
                              <input
                                className="form-control rounded-md"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                type="text"
                                placeholder="Subject"
                                required
                              />
                            </div>

                            <div className="form-group mt-4">
                              <textarea
                                className="form-control rounded-md h-64"
                                value={mailMsg}
                                onChange={(e) => setMailMsg(e.target.value)}
                                type="text"
                                placeholder="Message"
                                required
                              />
                            </div>

                            <div className="form-group mt-4">
                              <button
                                className="form-control rounded-md bg-default_orange text-default_white hover:bg-default_orange hover:text-default_white"
                                type="submit"
                              >
                                Send Email
                              </button>
                            </div>
                          </form>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={6}>
                    <p className="text-center">List of Recipients </p>
                    <hr />

                    {!added2 ? (
                      <p className="text-center mt-4 text-lg text-default_grey">
                        List is empty...
                      </p>
                    ) : (
                      recMailList.map((the_d, index) => (
                        <>
                          <div className="flex gap-4 mt-2" key={index}>
                            <p key={index}>{index + 1}.</p>
                            <p key={the_d}>{the_d}</p>
                          </div>
                        </>
                      ))
                    )}
                  </Col>
                </Row>
              </Container>
            </TabPanel>
          </Tabs>
        </div>
      </PageLayout>
    </>
  );
};

export default Notifications;
