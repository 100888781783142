import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShowToast from '../Alerts/all_toasts';
import ShowAlert from '../Alerts/all_alerts';
import WalletTable from '../Tables/WalletTable';
import CustomerTable from '../Tables/CustomerTable';
import { CreateCustomerService, CreateUpdate } from '../../functions/Admin';
import UpdatesTable from '../Tables/UpdatesTable';
const isoCountryCurrency = require('iso-country-currency');



const UpdatesTab = () => {

    const [dUpdate, setDUpdate] = useState([]);
    const [d_version, setDVersion] = useState("");
    const [d_build, setDBuild] = useState("");
    const [d_platform, setDPlatform] = useState("");
    const [d_package, setDPackage] = useState("");
    const [d_msg, setDMsg] = useState("");
    const [d_force, setDForce] = useState("");
    const [d_link, setDLink] = useState("");
    const [d_list, setDList] = useState("");
    const [dAdded, setDAdded] = useState(false);



    //Add class funtion
    const addToList = () => {
        if (d_list === "") {
            ShowToast.warning_toast("Fill out all fields!");
        }
        else {
            var tempArr = dUpdate;
            tempArr.push(d_list);
            setDUpdate(tempArr);
            setDAdded(true);
            setDList("");
        }
    }



    //Post customer service
    const doPostCustomer = () => {
        if (d_version === "" || d_build === "" || d_platform === "" || d_package === "" || d_msg === "" || dUpdate === "" || d_link === "") {
            ShowToast.warning_toast('Please fill out all fields!');
        }
        else if (dUpdate.length === 0) {
            ShowToast.warning_toast('Please add at least one update to the list!');
        }
        else {
            ShowAlert.loading_alert('Creating customer service...');

            var data = {
                "version": d_version,
                "build": parseInt(d_build),
                "platform": d_platform,
                "package": d_package,
                "message": d_msg,
                "lastforce": parseInt(d_force),
                "update": dUpdate,
                "link": d_link
            };

            CreateUpdate(data);
            // console.log(data);

        }
    }







    return (
        <>
            <Container>

                <div className="container mt-4">
                    <div className='flex justify-between'>
                        <div> <i className='fa fa-sync btn' onClick={() => { window.location.reload() }}></i> </div>

                        <div>
                            <Link className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" to="#" data-bs-toggle="modal" data-bs-target="#customerModal">+ Add New</Link>
                        </div>
                    </div>
                </div>


                <div>

                    <UpdatesTable />

                </div>


            </Container>







            {/* Post updates */}
            <div className="modal fade modal-xl" id="customerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 font-semibold" id="staticBackdropLabel">Post Updates</h1>
                        </div>

                        <div className="modal-body">
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <div className='form-group mt-3'>
                                            <input className='form-control shadow-none rounded-md' value={d_version} onChange={(e) => setDVersion(e.target.value)} type='text' placeholder='Version' />
                                        </div>
                                        <div className='form-group mt-3'>
                                            <input className='form-control shadow-none rounded-md' value={d_build} onChange={(e) => setDBuild(e.target.value)} type='text' placeholder='Build' />
                                        </div>
                                        <div className='form-group mt-2'>
                                            <select className="form-select form-control" value={d_package} onChange={(e) => setDPackage(e.target.value)} aria-label="Default select example" id="dropdown2">
                                                <option defaultValue='Select...'>Select App</option>
                                                <option value='com.rydsom.user'>Rydsom User App</option>
                                                <option value='com.rydsom.driver'>Rydsom Driver App</option>
                                            </select>
                                        </div>
                                        <div className='form-group mt-2'>
                                            <select className="form-select form-control" value={d_platform} onChange={(e) => setDPlatform(e.target.value)} aria-label="Default select example" id="dropdown3">
                                                <option defaultValue='Select...'>Select Platform</option>
                                                <option value='ios'>Apple iOS</option>
                                                <option value='android'>Android</option>
                                            </select>
                                        </div>
                                        <div className='form-group mt-3'>
                                            <input className='form-control shadow-none rounded-md' value={d_force} onChange={(e) => setDForce(e.target.value)} type='text' placeholder='Last Force' />
                                        </div>
                                        <div className='form-group mt-3'>
                                            <input className='form-control shadow-none rounded-md' value={d_msg} onChange={(e) => setDMsg(e.target.value)} type='text' placeholder='Message' />
                                        </div>
                                        <div className='form-group mt-3'>
                                            <input className='form-control shadow-none rounded-md' value={d_link} onChange={(e) => setDLink(e.target.value)} type='text' placeholder='Link' />
                                        </div>
                                        <div className='form-group mt-3'>
                                            <input className='form-control shadow-none rounded-md' value={d_list} onChange={(e) => setDList(e.target.value)} type='text' placeholder='Major Fixes' />
                                        </div>
                                        <div className='mt-3'>
                                            <button type="button" onClick={() => addToList()} className="text-default_white text-xs bg-default_orange px-3 py-2 rounded-md hover:text-default_white">Add</button>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <p className='mt-2'>Major Fixes</p>
                                        <hr className='mt-2' />
                                        {
                                            !dAdded ? <small>Nothing added...</small>
                                                :
                                                dUpdate.map((the_d, index) => {
                                                    return (
                                                        <Col key={index} md={6}>
                                                            <div className='flex gap-3 mt-2'>
                                                                <p>{index + 1}.</p>
                                                                <p>{the_d}</p>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="text-default_red mr-4" data-bs-toggle="modal" data-bs-target="#customerModal">Cancel</button>

                            <button type="button" className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white" onClick={() => doPostCustomer()}>Post Customer Service</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default UpdatesTab