import React from 'react'
import { AiOutlinePlusCircle } from "react-icons/ai";


interface btnProps {
    onClick?: () => void
    text?: string
}


const AddButton = ({ onClick, text }: btnProps) => {
    return (
        <>
            <button className="flex gap-1 items-center px-4 py-2 rounded-[30px] shadow-sm bg-default_orange text-white" onClick={onClick}>
                <AiOutlinePlusCircle className='h-5 w-5' />
                <p>{text || "Add New"}</p>
            </button>
        </>
    )
}

export default AddButton
