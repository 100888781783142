import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Card, Container, Modal, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ListBulletIcon, MapPinIcon } from "@heroicons/react/24/outline";
import BaseService from "../../mixins/baseServices";
import global_variables from "../../mixins/globarVars";
import TableShimmer from "../Shimmers/TableShimmer";
import MapsLayOut from "../Maps/MapsLayOut";
import CloseModal from "../Buttons/CloseModal";
import ModalXBtn from "../Buttons/ModalXBtn";
import ShowToast from "../Alerts/all_toasts";
import Select from "react-select";
import NoDataComponent from "../layouts/NoDataComponent";
import { Empty, Spin } from "antd";

const TrackingTable = () => {
  const [loading, setLoading] = useState(true);
  const [trips, setTrips] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [tripForModal, setTripForModal] = useState([]);
  const [reFetch, setReFetch] = useState(false);

  const [uniqueLoaded, setUniqueLoaded] = useState(false);
  const [specificData, setSpecificData] = useState([]);
  const [driverList, setDriverList] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  const handleCloseMap = () => {
    setShowMap(false);
    setTripForModal([]);
  };

  const handleShowMap = (i) => {
    // console.log(i)
    setTripForModal(i);
    setShowMap(true);
  };

  //Fetch drivers
  const fetchDrivers = () => {
    const the_route =
      global_variables().get_all_drivers + '?filter={"approved": true}';

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response.data.payload);
        setDriverList(response.data.payload);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //onload
  useEffect(() => {
    fetchDrivers();
    fetchTrips(limit, skip);
  }, [reFetch]);

  const fetchTrips = () => {
    setLoading(true);

    const the_route =
      global_variables().get_all_trips +
      `?filter={"completed": false, "driverId":{"$ne":null}}&sort={"createdAt":-1}`;

    BaseService.get_api(the_route)
      .then(function (response) {
        // console.log(response.data.payload);
        setTrips(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchTrips(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchTrips(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchTrips(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Table columns
  const columns = [
    {
      name: "Driver Name",
      selector: (row) => (row.driver_data ? row.driver_data["fullName"] : ""),
    },
    {
      name: "Phone",
      selector: (row) => (row.driver_data ? row.driver_data["phone"] : ""),
    },
    {
      name: "Pickup",
      selector: (row) => row.pickUpPlace,
    },
    {
      name: "Destination",
      selector: (row) => row.destinationPlace,
    },
    {
      name: "Car No.",
      selector: (row) => (row.car_data ? row.car_data["registeration"] : ""),
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <div className="flex gap-3">
            <Link
              to="#"
              title="Trip Details"
              onClick={showDetails.bind(this, row)}
              data-bs-toggle="modal"
              data-bs-target="#trackingDetailsModal"
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
            <Link
              to="#"
              title="Open Map"
              onClick={handleShowMap.bind(this, row)}
            >
              <MapPinIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  //View Details
  const showDetails = (i) => {
    setSpecificData(i);
    setUniqueLoaded(true);
    // console.log(i);
  };

  const [filQry, setFilQry] = useState("");

  //Search fiter
  const applySearch = () => {
    if (filQry === "") {
      ShowToast.warning_toast(`Please input a driver's name`);
    } else {
      setLoading(true);

      const the_route =
        global_variables().get_all_trips +
        `?filter={"completed": false, "driverId": ${JSON.stringify(filQry)}}`;

      BaseService.get_api(the_route)
        .then((response) => {
          // console.log(response.data.payload);
          setTrips(response.data.payload);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>
          <form className="col-md-6">
            <div className="input-group">
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
              >
                <option defaultValue="0" disabled>
                  Filter by ...{" "}
                </option>
                <option value="name">Driver Name</option>
              </select>

              <Select
                className="basic-single w-80"
                isClearable={true}
                isSearchable={true}
                options={driverList.map((dd) => {
                  return { value: dd.userid, label: dd.user_data["fullName"] };
                })}
                onChange={(e) => setFilQry(e.value)}
              />

              <button
                onClick={() => applySearch()}
                type="button"
                className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={trips}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            progressPending={loading}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
          />
        </Card.Body>
      </Card>

      {/* Map Modal */}
      <Modal show={showMap} onHide={handleCloseMap} size="fullscreen">
        <Modal.Header closeButton>
          <Modal.Title>Live Trip Tracking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showMap ? (
            <>
              <MapsLayOut
                mapOrigin={{
                  lat: tripForModal.destinationLatitude,
                  lng: tripForModal.destinationLongitude,
                }}
                mapDestination={{
                  lat: tripForModal.pickUpLatitude,
                  lng: tripForModal.pickUpLongitude,
                }}
                driverId={tripForModal.driverId}
                trackingId={tripForModal.tripId}
              />
            </>
          ) : (
            <Container className="text-center mt-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p>Please wait...</p>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="text-default_white bg-default_orange px-4 mr-4"
            data-bs-dismiss="modal"
            onClick={() => handleCloseMap()}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Tracking details modal */}
      <div
        className="modal fade modal-xl"
        id="trackingDetailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                View Trip Details
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                {!uniqueLoaded ? (
                  <TableShimmer />
                ) : (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>Trip Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="font-semibold">Pick Up City:</span>{" "}
                            {specificData.pickUpCity}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Pick Up Place:
                            </span>{" "}
                            {specificData.pickUpPlace}
                          </td>
                          <td>
                            <span className="font-semibold">Request Time:</span>{" "}
                            {moment(specificData.requestTime).format(
                              "Do MMM YYYY; hh:mm a"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">
                              Destination City:
                            </span>{" "}
                            {specificData.destinationCity}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Destination Place:
                            </span>{" "}
                            {specificData.destinationPlace}
                          </td>
                          <td>
                            <span className="font-semibold">End Time:</span>{" "}
                            {moment(
                              specificData.currentStatus["timestamp"]
                            ).format("Do MMM YYYY; hh:mm a")}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">
                              Trip Distance:
                            </span>{" "}
                            {specificData.distance +
                              " " +
                              specificData.distanceUnit}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <br />

                      <thead>
                        <tr>
                          <th>Driver Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              className="img-fluid rounded-full"
                              src={specificData.driver_data["image"]}
                              alt="..."
                              width="100px"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">First Name:</span>{" "}
                            {specificData.driver_data
                              ? specificData.driver_data["firstName"]
                              : ""}
                          </td>
                          <td>
                            <span className="font-semibold">Last Name:</span>{" "}
                            {specificData.driver_data
                              ? specificData.driver_data["lastName"]
                              : ""}
                          </td>
                          <td>
                            <span className="font-semibold">Phone Number:</span>{" "}
                            {specificData.driver_data
                              ? specificData.driver_data["phone"]
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                      <br />

                      <thead>
                        <tr>
                          <th>Car Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Front View
                            <img
                              className="img-fluid"
                              src={specificData.car_data["carFront"]}
                              alt="..."
                              width="150px"
                            />
                          </td>
                          <td>
                            Side View
                            <img
                              className="img-fluid"
                              src={specificData.car_data["carSide"]}
                              alt="..."
                              width="150px"
                            />
                          </td>
                          <td>
                            Back View
                            <img
                              className="img-fluid"
                              src={specificData.car_data["carBack"]}
                              alt="..."
                              width="150px"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">Car Number:</span>{" "}
                            {specificData.car_data
                              ? specificData.car_data["registeration"]
                              : ""}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Roadworthy Name:
                            </span>{" "}
                            {specificData.car_data
                              ? specificData.car_data["roadWorthy"]
                              : ""}
                          </td>
                          <td>
                            <span className="font-semibold">
                              Insurance Number:
                            </span>{" "}
                            {specificData.car_data
                              ? specificData.car_data["insurance"]
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                      <br />

                      <thead>
                        <tr>
                          <th>Rider Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              className="img-fluid rounded-full"
                              src={specificData.user_data["image"]}
                              alt="..."
                              width="100px"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="font-semibold">First Name:</span>{" "}
                            {specificData.user_data
                              ? specificData.user_data["firstName"]
                              : ""}
                          </td>
                          <td>
                            <span className="font-semibold">Last Name:</span>{" "}
                            {specificData.user_data
                              ? specificData.user_data["lastName"]
                              : ""}
                          </td>
                          <td>
                            <span className="font-semibold">Phone Number:</span>{" "}
                            {specificData.user_data
                              ? specificData.user_data["phone"]
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                )}
              </Container>
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
          ... ... Administrator
        </div>
      </div>
    </>
  );
};

export default TrackingTable;
