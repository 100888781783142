import React from "react";
import TrackingTable from "../../components/Tables/TrackingTable";
import PageLayout from "../../components/navLayouts/pageLayout";

const Tracking = () => {
  return (
    <>
      <PageLayout trackActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Tracking</p>

          <hr className="my-3" />

          {/* Table */}

          <TrackingTable />
        </div>
      </PageLayout>
    </>
  );
};

export default Tracking;
