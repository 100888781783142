import ShowAlert from "../components/Alerts/all_alerts";
import BaseService from "../mixins/baseServices";
import global_variables from "../mixins/globarVars"

//suspend user
export const SuspendUser = (data) => {
    const the_route = global_variables().suspend_user;

    BaseService.put_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'User suspended!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Failed to suspend user!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
}


//unsuspend user
export const UnsuspendUser = (data) => {

    const the_route = global_variables().unsuspend_user;

    BaseService.put_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Suspsension revoked!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Failed to unsuspend user!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })

}


//approve driver
export const ApproveDriver = (data) => {

    const the_route = global_variables().approve_driver;

    BaseService.put_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Driver Approved!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Failed to approve driver!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })

}


//approve vehicle
export const ApproveVehicle = (data) => {

    const the_route = global_variables().approve_vehicle;

    BaseService.put_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Vehicle Approved!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Failed to approve vehicle!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })

}


//unapprove vehicle
export const UnapproveVehicle = (data) => {

    const the_route = global_variables().approve_vehicle;

    BaseService.put_api(the_route, data)
        .then((response) => {
            // console.log(response);
            ShowAlert.success_alert('Success!', 'Vehicle Unapproved!', 'Done')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })
        .catch((error) => {
            // console.log(error);
            ShowAlert.error_alert('Sorry!', 'Failed to unapprove vehicle!', 'Retry')
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
        })

}




