import { EyeIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShowAlert from '../../components/Alerts/all_alerts';
import ShowToast from '../../components/Alerts/all_toasts';
import { setNewPassFunction } from '../../functions/Authentication';
import frgt_pass_img from '../../images/frgt_pass_img.png';
import { message } from 'antd';



const NewPass = () => {

    const [passwordd, setPasswordd] = useState("");
    const [cPasswordd, setCPasswordd] = useState("");

    //Show Password
    const [values, setValues] = useState({ showPassword: false });

    const ViewPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    }



    //Update password
    const doPassCheck = () => {

        if (passwordd === "" || cPasswordd === "") {
            message.warning('Please fill out all fields!');
        }
        else if (passwordd !== cPasswordd) {
            message.warning('Passwords do not match!');
        }
        else {
            const new_password = {
                "password": passwordd
            }
            ShowAlert.loading_alert('Updating Password...');
            setNewPassFunction(new_password);
        }

    }





    return (
        <>
            <div className='h-screen w-full overflow-hidden'>
                <div className="flex justify-center items-center h-full">
                    <Card className='rounded-lg border-none shadow-sm px-10 py-5'>
                        <Card.Body>
                            <img className='img-fluid block mr-auto ml-auto' src={frgt_pass_img} width="300px" alt='...' />
                            <div className='form-group pt-3'>
                                <div className="input-group">
                                    <input className='form-control shadow-none border-outline_light rounded-md' type={values.showPassword ? "text" : "password"} id="admin_password" value={passwordd} onChange={(e) => setPasswordd(e.target.value)} placeholder='Password' aria-describedby="basic-addon1" required />
                                    <Link to="#" className="input-group-text" id="admin_password" onClick={ViewPassword}><EyeIcon className='w-4 h-4' /></Link>
                                </div>
                            </div>
                            <div className='form-group pt-3'>
                                <input className='form-control shadow-none border-outline_light rounded-md' value={cPasswordd} onChange={e => setCPasswordd(e.target.value)} type='password' id="boss_password2" placeholder='Confirm Password' required />
                            </div>

                            <div className='form-group mt-4 text-center'>
                                <button className='bg-default_orange text-default_white shadow-md rounded-full form-control hover:text-default_white active:bg-default_orange' onClick={doPassCheck} type="submit" id="signin-btn">
                                    Set Password
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default NewPass