import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import global_variables from "../../mixins/globarVars";
import BaseService from "../../mixins/baseServices";
import moment from "moment-timezone";
import ShowAlert from "../Alerts/all_alerts";
import { ApproveVehicle } from "../../functions/Actions";
import ModalXBtn from "../Buttons/ModalXBtn";
import CloseModal from "../Buttons/CloseModal";
import { Empty, Spin } from "antd";

const UnapprovedVehicles = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reFetch, setReFetch] = useState(false);

  const [selectedData, setSelectedData] = useState([]);
  const [dataIn, setDataIn] = useState(false);

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  //onload
  useEffect(() => {
    fetchData(limit, skip);
  }, [reFetch]);

  //fetch data
  const fetchData = (lim, sk) => {
    setLoading(true);
    const the_route =
      global_variables().get_all_vehicles +
      '?filter={"approved": false}' +
      `&limit=${lim}&skip=${sk}&sort={"updatedAt":-1}`;

    BaseService.get_api(the_route)
      .then((response) => {
        // console.log(response);
        setTotalRows(response.data.total);
        setData(response.data.payload);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //Table columns
  const columns = [
    {
      name: "",
      selector: (row) => (
        <img className="w-10 h-10 rounded-full" src={row.carFront} alt="..." />
      ),
    },
    {
      name: "Driver Name",
      selector: (row) => row.user_data["fullName"],
    },
    {
      name: "Phone",
      selector: (row) => row.user_data["phone"],
    },
    {
      name: "Car Number",
      selector: (row) => row.registeration,
    },
    {
      name: "Date Created",
      selector: (row) =>
        moment(row.user_data["createdAt"]).format("Do MMM YYYY"),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.approved ? (
            <p className="text-default_white bg-default_green px-3 py-1 rounded-xl">
              Approved
            </p>
          ) : (
            <p className="text-default_white bg-default_red px-3 py-1 rounded-xl">
              Unapproved
            </p>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      selector: (row, index) => (
        <>
          <div className="flex gap-2">
            <Link
              to="#"
              title="Details"
              data-bs-toggle="modal"
              data-bs-target="#vehicleDetailsModal"
              onClick={ShowDetails.bind(this, row)}
            >
              <ListBulletIcon className="w-5 h-5" />
            </Link>
            {row.approved ? (
              <Link to="#" title="Unapprove">
                <ExclamationTriangleIcon className="w-5 h-5" />
              </Link>
            ) : (
              <Link
                to="#"
                title="Approve"
                onClick={CheckPoint.bind(this, index)}
              >
                <CheckBadgeIcon className="w-5 h-5" />
              </Link>
            )}
          </div>
        </>
      ),
    },
  ];

  //Checkpoint for approval
  const CheckPoint = (i) => {
    var go = data[i]["userid"];

    var uniqueData = data.filter(function (theData) {
      return theData.userid === go;
    });

    // console.log(uniqueData[0]['userid']);

    ShowAlert.question_alert(
      "Confirm Action",
      "Would you like to approve this vehicle?",
      "Proceed"
    ).then((result) => {
      if (result.isConfirmed) {
        //do suspension
        //console.log(start, end, reason);
        ShowAlert.loading_alert("Please wait...");
        const data = {
          approved: true,
          userid: uniqueData[0]["userid"],
        };

        ApproveVehicle(data);
      }
    });
  };

  //show details
  const ShowDetails = (i) => {
    // var go = data[i]['_id'];

    // var uniqueData = data.filter(function (theData) {
    //     return theData._id === go;
    // });

    setSelectedData(i);
    setDataIn(true);
    // console.log(i);
  };

  /*=============Pagination Start============== */
  const rowChange = (a) => {
    fetchData(a, skip);
    setLimit(a);
    // console.log(a);
  };

  const changePage = (a) => {
    if (a === 1) {
      fetchData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      fetchData(limit, newSkip);
      setSkip(newSkip);
      // console.log(newSkip);
    }
  };
  /*=============Pagination End============== */

  //Search Table
  const [filQry, setFilQry] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);

    const the_route =
      global_variables().get_all_vehicles +
      `?filter={"approved": false,"registeration":${JSON.stringify(filQry)}}`;

    BaseService.get_api(the_route)
      .then((response) => {
        //console.log(response.data.payload);
        setData(response.data.payload);
        setTotalRows(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-6">
            {" "}
            <i
              className="fa fa-sync btn"
              onClick={() => setReFetch(!reFetch)}
            ></i>{" "}
          </div>
          <form className="col-md-6" onSubmit={handleSearch}>
            <div className="input-group">
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ maxWidth: "150px", border: "1px solid #d9e2ef" }}
              >
                <option defaultValue="0" disabled>
                  Filter by ...{" "}
                </option>
                <option value="registeration">Car Number</option>
              </select>

              <input
                className="form-control shadow-none"
                placeholder="Search..."
                id="truckNum"
                aria-label="Search"
                aria-describedby="search-addon"
                autoComplete="off"
                type="text"
                style={{ border: "1px solid #d9e2ef" }}
                required
                value={filQry}
                onChange={(e) => setFilQry(e.target.value)}
                autoFocus
              />
              <button
                type="submit"
                className="btn outline outline-1 outline-default_orange bg-default_orange text-default_white text-md hover:text-default_white hover:bg-default_orange"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Table */}

      <Card className="mt-3 rounded-xl shadow-md border-y-0 border-default_orange border-b-0 border-x-2">
        <Card.Body>
          <DataTable
            columns={columns}
            data={data}
            pagination
            paginationTotalRows={totalRows}
            progressPending={loading}
            onChangeRowsPerPage={rowChange}
            onChangePage={changePage}
            noDataComponent={<Empty description="No data to display" />}
            progressComponent={
              <div className="flex justify-center items-center h-[150px]">
                <Spin size="large" />
              </div>
            }
          />
        </Card.Body>
      </Card>

      {/* View Details Modal */}
      <div
        className="modal fade modal-xl"
        id="vehicleDetailsModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 font-semibold text-2xl"
                id="staticBackdropLabel"
              >
                Vehicle Details
              </h1>
              <ModalXBtn />
            </div>
            <div className="modal-body">
              <Container>
                {dataIn ? (
                  <>
                    <p className="font-semibold text-xl">Driver Information</p>
                    <hr />
                    {selectedData["user_data"] ? (
                      <>
                        <Row>
                          <Col className="mt-3" md={6}>
                            <div className="flex gap-4 mt-3">
                              <p className="font-semibold">First Name:</p>
                              <p>{selectedData["user_data"]["firstName"]}</p>
                            </div>
                            <div className="flex gap-4 mt-3">
                              <p className="font-semibold">Middle Name:</p>
                              <p>
                                {selectedData["user_data"]["middleName"]
                                  ? selectedData["middleName"]
                                  : "..."}
                              </p>
                            </div>
                            <div className="flex gap-4 mt-3">
                              <p className="font-semibold">Last Name:</p>
                              <p>{selectedData["user_data"]["lastName"]}</p>
                            </div>
                            <div className="flex gap-4 mt-3">
                              <p className="font-semibold">Signup Date:</p>
                              <p>
                                {moment(
                                  selectedData["user_data"]["createdAt"]
                                ).format("Do MMM YYYY")}
                              </p>
                            </div>
                            <div className="flex gap-4 mt-3">
                              <p className="font-semibold">User Iamge:</p>
                              <img
                                className="img-fluid w-36"
                                src={selectedData["user_data"]["image"]}
                                alt="..."
                              />
                            </div>
                          </Col>
                          <Col className="mt-3" md={6}>
                            <div className="flex gap-4 mt-1">
                              <p className="font-semibold">Phone Number:</p>
                              <p>{selectedData["user_data"]["phone"]}</p>
                            </div>
                            <div className="mt-3">
                              <p className="font-semibold">Roles:</p>
                              {selectedData["user_data"]["userRoles"].map(
                                (the_d, index) => {
                                  return (
                                    <p key={index}>
                                      {index + 1}. {the_d}
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <p>No driver data available...</p>
                    )}

                    <p className="font-semibold text-xl mt-5">
                      Vehicle Information
                    </p>
                    <hr />

                    <Row>
                      <Col md={6}>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Registration Number:</p>
                          <p>{selectedData["registeration"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Insurance Number:</p>
                          <p>{selectedData["insurance"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Roadworthy Number:</p>
                          <p>{selectedData["roadWorthy"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Color:</p>
                          <p>{selectedData["carColor"]}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Number of Seats:</p>
                          <p>{selectedData?.vehicleModelData?.[0]?.seats}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Brand:</p>
                          <p>{selectedData?.brandData?.[0]?.brand}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Model:</p>
                          <p>{selectedData?.vehicleModelData?.[0]?.model}</p>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Class:</p>
                          {selectedData?.classData.map((_d, i) => (
                            <p key={i}>{_d.class},</p>
                          ))}
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Features:</p>
                          {selectedData?.featuresData.map((_d, i) => (
                            <p key={i}>{_d.feature},</p>
                          ))}
                        </div>
                      </Col>
                      <Col className="mt-3" md={6}>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Car Front:</p>
                          <Image
                            className="w-60 h-60 object-cover"
                            src={selectedData["carFront"]}
                            alt="..."
                            fluid
                          />
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Car Side:</p>
                          <Image
                            className="w-60 h-60 object-cover"
                            src={selectedData["carSide"]}
                            alt="..."
                            fluid
                          />
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Car Back:</p>
                          <Image
                            className="w-60 h-60 object-cover"
                            src={selectedData["carBack"]}
                            alt="..."
                            fluid
                          />
                        </div>
                      </Col>

                      <Col className="mt-3" md={6}>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Registration Image:</p>
                          <Image
                            className="h-60 w-60 object-cover"
                            src={selectedData["registerationImage"]}
                            alt="..."
                            fluid
                          />
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Insurance Image:</p>
                          <Image
                            className="h-60 w-60 object-cover"
                            src={selectedData["insuranceImage"]}
                            alt="..."
                            fluid
                          />
                        </div>
                        <div className="flex gap-4 mt-3">
                          <p className="font-semibold">Roadworthy Image:</p>
                          <Image
                            className="h-60 w-60 object-cover"
                            src={selectedData["roadWorthyImage"]}
                            alt="..."
                            fluid
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <p className="text-center">No data to display ...</p>
                )}
              </Container>
            </div>
            <div className="modal-footer">
              <CloseModal btn_name="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnapprovedVehicles;
