import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShowToast from "../Alerts/all_toasts";
import ShowAlert from "../Alerts/all_alerts";
import CarModelTable from "../Tables/CarModelTable";
import Select from "react-select";
import { CreateNewModel } from "../../functions/CarsAndClasses";
import { useGeneralContext } from "../../contexts/generalContext";
import { message, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";

const CarModelsTab = () => {
  const [allClasses, setAllClasses] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const { reFetch, setReFetch } = useGeneralContext();
  const [isBusy, setIsBusy] = useState(false);

  const [d_model, setDModel] = useState("");
  const [d_brand, setDBrand] = useState("Select...");
  const [d_class, setDClass] = useState("Select...");
  const [d_seats, setDSeats] = useState("");
  const [d_car, setDCar] = useState("Select...");
  const [excepFeatures, setExecpFeatures] = useState([]);

  //trigger post modal
  const [show, setPstShow] = useState(false);
  const handlePostClose = () => setPstShow(false);
  const handlePostShow = () => setPstShow(true);

  //onload
  useEffect(() => {
    GetAllClasses();
    GetAllBrands();
    GetAllFeatures();
  }, []);

  //get classes
  const GetAllClasses = () => {
    BaseService.get_api(global_variables.rydsom_class_url)
      .then((response) => {
        // console.log(response.data.payload);
        setAllClasses(response.data.payload);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //get brands
  const GetAllBrands = () => {
    BaseService.get_api(global_variables.car_brand_url)
      .then((response) => {
        // console.log(response.data.payload);
        setAllBrands(response.data.payload);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //get features
  const GetAllFeatures = () => {
    BaseService.get_api(global_variables.car_feature_url)
      .then((response) => {
        var theData = response.data.payload;
        // console.log(theData);
        const options = theData.map((dd) => {
          return { value: dd._id, label: dd.feature };
        });
        setFeatureOptions(options);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //handle exceptions change
  /* To be continued */
  const handleChange = (selectedOptions) => {
    //selectedOptions is an array of the selected options
    // console.log(selectedOptions);
    var theData = selectedOptions.map((dd) => {
      return dd.value;
    });
    var tempArr = excepFeatures;
    tempArr.push(theData);
    tempArr.map((dd) => {
      return setExecpFeatures(dd);
    });
  };

  //Post Brand
  const doPostModel = () => {
    if (
      d_class === "Select..." ||
      d_brand === "Select..." ||
      d_model === "" ||
      d_seats === "" ||
      d_car === "Select..."
    ) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      var data = {
        model: d_model,
        brand: d_brand,
        class: d_class,
        seats: parseInt(d_seats),
        type: d_car,
        classExceptionFeatures: excepFeatures,
      };

      if (data?.classExceptionFeatures.length === 0) {
        delete data?.classExceptionFeatures;
      }

      console.log(data);

      ShowAlert.question_alert(
        "Confirm",
        "Do you wish to post this model?",
        "Proceed"
      ).then((result) => {
        if (result.isConfirmed) {
          setIsBusy(true);

          BaseService.post_api(global_variables.car_model_url, data)
            .then((response) => {
              // console.log(response);
              message.success("New Model Created!");
              setIsBusy(false);
              handlePostClose();
              setReFetch(!reFetch);
            })
            .catch((error) => {
              console.log(error);
              message.error(
                error?.response?.data?.error ||
                  "Could not create new car model!"
              );
              setIsBusy(false);
            });
        }
      });
    }
  };

  return (
    <>
      <Container>
        <div className="container mt-4">
          <div>
            <Link
              className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
              to="#"
              onClick={() => handlePostShow()}
            >
              + Add New
            </Link>
          </div>
        </div>

        <div>
          <CarModelTable />
        </div>
      </Container>

      {/* Post Models modal */}
      <Modal show={show} onHide={handlePostClose}>
        <Spin spinning={isBusy}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 className="font-semibold">Post Car Models</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="form-group">
                <select
                  className="form-select form-control"
                  value={d_brand}
                  onChange={(e) => setDBrand(e.target.value)}
                  aria-label="Default select example"
                  id="dropdown"
                >
                  <option defaultValue="Select...">Choose Brand</option>
                  {allBrands.map((dd, index) => {
                    return (
                      <option key={index} value={dd._id}>
                        {dd.brand}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group mt-3">
                <input
                  className="form-control shadow-none rounded-md"
                  type="text"
                  value={d_model}
                  onChange={(e) => setDModel(e.target.value)}
                  placeholder="Enter model"
                />
              </div>
              <div className="form-group mt-3">
                <select
                  className="form-select form-control"
                  value={d_class}
                  onChange={(e) => setDClass(e.target.value)}
                  aria-label="Default select example"
                  id="dropdown"
                >
                  <option defaultValue="Select...">Choose Class</option>
                  {allClasses.map((dd, index) => {
                    return (
                      <option key={index} value={dd._id}>
                        {dd.class}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group mt-3">
                <input
                  className="form-control shadow-none rounded-md"
                  type="text"
                  value={d_seats}
                  onChange={(e) => setDSeats(e.target.value)}
                  placeholder="Enter no. of seats"
                />
              </div>
              <div className="form-group mt-3">
                <select
                  className="form-select form-control"
                  value={d_car}
                  onChange={(e) => setDCar(e.target.value)}
                  aria-label="Default select example"
                  id="dropdown"
                >
                  <option defaultValue="Select...">Choose Vehicle</option>
                  <option value="car">Car</option>
                  <option value="motorcycle">Motorcycle</option>
                </select>
              </div>
              <div className="form-group mt-3">
                <small>
                  Choose car features{" "}
                  <span className="text-default_red">(Not required)</span>
                </small>
                <Select
                  isMulti
                  options={featureOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="text-default_red mr-4"
              onClick={() => handlePostClose()}
            >
              Cancel
            </button>

            <button
              type="button"
              className="text-default_white bg-default_orange px-3 py-2 rounded-md hover:text-default_white"
              onClick={() => doPostModel()}
            >
              Post Model
            </button>
          </Modal.Footer>
        </Spin>
      </Modal>
    </>
  );
};

export default CarModelsTab;
