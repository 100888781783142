import axios from "axios";
import { StorageBox } from "./stroage";
import { BASE_URL } from "./contants";

const config: any = {
  headers: {
    "Content-Type": "application/json",
  },
};

//Axios Post
const post_api = (route: string, data: any) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.post(BASE_URL + route, data, config);
};

//Axios Get
const get_api = (route: string) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.get(BASE_URL + route, config);
};

//Axios Put
const put_api = (route: string, data: any) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.put(BASE_URL + route, data, config);
};

//Axios Delete
const delete_api = (route: string, data: any) => {
  var config = {
    method: "delete",
    url: BASE_URL + route,
    headers: {
      "x-access-token": StorageBox.getAccessToken(),
      "Content-Type": "application/json",
    },
    data: data,
  };

  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios(config);
};

// file upload
const file_upload = (route: string, file: any) => {
  const data = new FormData();
  data.append("file", file, "[PROXY]");

  let fileConfig = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASE_URL + route,
    headers: {
      "x-access-token": StorageBox.getAccessToken(),
    },
    data: data,
  };
  try {
    const response = axios.request(fileConfig);
    return response;
  } catch (error) {
    return error;
  }
};

const BaseService = {
  post_api,
  get_api,
  put_api,
  delete_api,
  file_upload,
};

export default BaseService;
