import React, { useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ApprovedVehiclesTable from "../../components/Tables/ApprovedVehiclesTable";
import UnapprovedVehicles from "../../components/Tables/UnapprovedVehicles";
import PageLayout from "../../components/navLayouts/pageLayout";

const Vehicles = () => {
  const [tabActive, setTabActive] = useState(0);

  //Tab names
  const tabNames = [
    {
      name: "Approved Vehicles",
    },
    {
      name: "Unapproved Vehicles",
    },
  ];

  const showActive = (i) => {
    if (i === tabActive) {
      return "rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl bg-default_orange text-default_white";
    } else {
      return "bg-text_white rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl hover:bg-default_orange hover:text-default_white";
    }
  };

  return (
    <>
      <PageLayout vechActive="active">
        <div className="h-full overflow-y-auto w-full px-10 pt-10 pb-20">
          <p className="text-2xl font-semibold">Vehicles</p>

          <hr className="my-3" />

          <Tabs className="mt-2">
            <TabList className="bottom-0 mb-3 flex gap-2 text-xs md:text-md lg:text-lg xl:text-lg">
              {tabNames.map((item, index) => (
                <Tab key={index} className="border-0 bg-transparent text-sm">
                  <button
                    key={index}
                    onClick={setTabActive.bind(this, index)}
                    className={showActive(index)}
                  >
                    {item.name}
                  </button>
                </Tab>
              ))}
            </TabList>

            {/* All Vehicles */}
            <TabPanel>
              <ApprovedVehiclesTable />
            </TabPanel>

            {/* Unapproved vehicles */}
            <TabPanel>
              <UnapprovedVehicles />
            </TabPanel>
          </Tabs>
        </div>
      </PageLayout>
    </>
  );
};

export default Vehicles;
